import { memo, useEffect, useState } from "react";
import CostimizeSidebar from "../layout/CostimizeSidebar";
import MainHead from "../layout/MainHead";
import {
  Tabs,
  Row,
  Col,
  Button,
  Input,
  AutoComplete,
  message,
  Badge,
  Drawer,
} from "antd";
import { Icon } from "@iconify/react";
import AssetSpendExport from "./AssetSpendExport";
// import AssetSpendList_new from "./AssetSpendList_new";
import AssetSpendList_new from "./Assets_new_table";
import AssetSpendTopListChart from "./AssetSpendTopListChart";
import AssetSpendListDB from "./AssetSpendListDB";
import AssetSpendListOthers from "./AssetSpendListOthers";
import AssetSpendother from "./Assets_Other";
// import AssetSpendListOthers from "../assetandspend/Assets_Others";
import OldRecords from "./OldRecords";
import Kubernetes_CostDemaze from "./kubernetesCostDemaze/Kubernetes_CostDemaze";
import AssetKubernetes from "./AssetKubernetes";
import { useDispatch, useSelector } from "react-redux";
import CostdemazeFilter from "../layout/CostdemazeFilter";
import moment from "moment";
import {
  downloads3file,
  searchresource,
  resourceassetspend,
} from "../../action/costimizeV2Active";
// import AssetSpendDBMain from "../assetandspend/AssetSpendDB/AssetSpendDBMain";
import AssetSpendDBMain from "../assetandspend/Asset_DB_table";
import ResourceList from "./ResourceList";
import CostimazeDatePicker from "../layout/CostimazeDatePicker";
import NetworkingCost from "./network-tab/NetworkingCost";

const AssetSpendLayout = () => {
  const {
    selectedCloud,
    selectedEnvironment,
    selectedAccount,
    selectedApplication,
    selectedservices,
    selectedos,
    selectedinfra,
    selecteddb,
  } = useSelector((state) => state.constimize);
  const [topchartstatus, setTopChartStatus] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [records, setRecords] = useState([]);
  const [applicationlist, setApplicationList] = useState([]);
  const [resourceName, setResourceName] = useState([]);
  const [viewStatus, setViewStatus] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const [resourceNameselected, setSelectResource] = useState("");
  const [selectedvalueData, setSelectedValueData] = useState();
  const [select, setonSelect] = useState(false);
  const dispatch = useDispatch();
  const [infrochart, setInfraChartDetails] = useState({
    categories: [],
    vmlist: [],
    dblist: [],
    others: [],
  });
  const [spendchart, setSpendChartDetails] = useState({
    categories: [],
    value: [],
  });

  const [chartspend, setChartAllSpend] = useState([]);
  const [chartcount, setChartAllCount] = useState([]);
  const selectedValues = [
    selectedCloud,
    selectedEnvironment,
    selectedAccount,
    selectedApplication,
    selectedservices,
    selectedos,
    selectedinfra,
    selecteddb,
  ];
  useEffect(() => {
    var records_list_count = [];
    var reacords_list_spend = [];
    applicationlist.map((application) => {
      const list_records = records?.filter((datas) => {
        return datas.application === application.toLowerCase();
      });
console.log(records)
      console.log(applicationlist)
      const spendtotal = list_records.reduce((a, v) => (a = +a + +v.spend), 0);
      records_list_count.push({
        label: application,
        value: list_records.length,
      });
      reacords_list_spend.push({
        label: application,
        value: Math.round(spendtotal),
      });
    });
    const records_list = records_list_count.sort((a, b) =>
      a.value > b.value ? -1 : 1
    );
    const records_list_spend = reacords_list_spend.sort((a, b) =>
      a.value > b.value ? -1 : 1
    );
    var cate_list = [];
    var vmcount = [];
    records_list.map((data, index) => {
      if (index < 5) {
        cate_list.push(data.label);
        vmcount.push(data.value);
      }
    });
    setInfraChartDetails({
      categories: cate_list,
      vmlist: vmcount,
      dblist: [0, 0, 0, 0, 0],
      others: [0, 0, 0, 0, 0],
    });

    var spend_cate_list = [];
    var spend_list = [];
    records_list_spend.map((data, index) => {
      if (index < 5) {
        spend_cate_list.push(data.label);
        spend_list.push(data.value);
      }
    });
    setSpendChartDetails({ categories: spend_cate_list, value: spend_list });
  }, [records]);
  const items = [
    {
      key: "1",
      label: `VM`,
      children: (
        <div className="assetspendslm">
          {select ? (
            <ResourceList
              resourceNames={resourceNameselected}
              searchValue={searchValue}
            />
          ) : (
            <AssetSpendList_new
              type="vm"
              className="slm"
              setRecords={setRecords}
              setApplicationList={setApplicationList}
              setViewStatus={setViewStatus}
              viewStatus={viewStatus}
            />
          )}
        </div>
      ),
    },
    {
      key: "2",
      label: `DBaas`,
      children: (
        <div className="assetspendslm">
          {" "}
          <AssetSpendDBMain
            setChartAllSpend={setChartAllSpend}
            setChartAllCount={setChartAllCount}
            type="db"
          />
        </div>
      ),
    },
    {
      key: "3",
      label: `Kubernetes`,
      children: (
        <div className="assetspendslm">
          <AssetKubernetes />
        </div>
      ),
    },
    // {
    //   key: "5",
    //   label: `Networking`,
    //   children: (
    //     <div className="assetspendslm">
    //       <NetworkingCost />
    //     </div>
    //   ),
    // },
    {
      key: "4",
      label: `Others`,
      children: (
        <div className="assetspendslm">
          <AssetSpendother
            setChartAllSpend={setChartAllSpend}
            setChartAllCount={setChartAllCount}
            type="others"
          />
        </div>
      ),
    },
  ];
  const { selectedTopdate } = useSelector((state) => state.constimize);
  // const companyName = localStorage.getItem(
  //   "CognitoIdentityServiceProvider.company"
  // );

  var companyName = "all_cloud";
  
  // const downloadExcel = () => {
  //   let now = new Date();
  //   let start = moment(
  //     new Date(now.getFullYear(), now.getMonth(), now.getDate())
  //   );
  //   const getPrevMonth = moment(selectedTopdate)
  //     .subtract(1, "months")
  //     .startOf("month")
  //     .format("MMMM");
  //   let prevMonth = getPrevMonth.toLowerCase();
  //   dispatch(downloads3file({ prevMonth, companyName }))
  //     .unwrap()
  //     .then(({ data }) => {
  //       window.location.href = data.application_grouping_report;
  //     })
  //     .catch((err) => {
  //       console.log(err.message);
  //     });
  // };
  const downloadExcel = () => {
    let now = new Date();
    const prevMonth = new Date(selectedTopdate).toLocaleDateString("en-US", {
      month: "long",
    });
    const yearvalue = new Date(selectedTopdate).toLocaleDateString("en-US", {
      year: "numeric",
    });
    dispatch(downloads3file({ prevMonth, yearvalue, companyName }))
      .unwrap()
      .then(({ data }) => {
        if (data.application_grouping_report === "Report Not Yet Generated") {
          message.info("Report not yet generated");
        } else if (data && data.application_grouping_report) {
          const apidata = data.application_grouping_report.replace(
            /^"|"$/g,
            ""
          );

          const link = document.createElement("a");
          link.href = apidata;
          link.download = "grouping.pdf";
          link.click();
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  const handleSearch = (value) => {
    dispatch(searchresource({ companyName, selectedTopdate }))
      .unwrap()
      .then(({ data }) => {
        const resourcelist = data.assetspend_resourcelist.map((datas) => {
          return {
            resourcename: datas.resourcename,
          };
        });

        setResourceName(resourcelist);
      })
      .catch((err) => {
        console.log(err);
      });

    setSearchValue(value);
    if (value === "") {
      setonSelect(false);
    }
  };
  const handleSelect = (value) => {
    setSelectedValueData(value);
    if (!value) {
      setonSelect(false);
      setSelectResource([]);
    } else {
      dispatch(
        resourceassetspend({ companyName, selectedTopdate, searchValue: value })
      )
        .unwrap()
        .then(({ data }) => {
          const resourceselect = data.assetspend_resource_search.map(
            (datas) => {
              return {
                account_name: datas.account_name,
                environment: datas.environment,
                application: datas.application,
                cloud: datas.cloud,
                resourceid: datas.resourceid,
                ram: datas.ram,
                core: datas.core,
                instancetype: datas.instancetype,
                storage: datas.storage,
                billingperiodstartdate: datas.billingperiodstartdate,
                spend: datas.spend,
                recommendation: datas.recommendation,
                savings_opportunity: datas.savings_opportunity,
                second_month_spend: datas.second_month_spend,
                third_month_spend: datas.third_month_spend,
                first_month_spend: datas.first_month_spend,
              };
            }
          );
          setSelectResource(resourceselect);
          setonSelect(true);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  let dataSource = [];
  if (resourceName && !Array.isArray(resourceName)) {
    dataSource = Object.values(resourceName).map((item) => item.resourcename);
  } else if (Array.isArray(resourceName)) {
    dataSource = resourceName.map((item) => item.resourcename);
  } else {
    console.error("resourceName is not an array or an object");
  }
  if (!Array.isArray(dataSource)) {
    dataSource = [];
  }
  const filteredSuggestions = dataSource.filter((item) =>
    item.toLowerCase().includes(searchValue.toLowerCase())
  );
  const showSuggestions =
    searchValue.length > 0 && filteredSuggestions.length > 0;
  const autoCompleteDataSource = showSuggestions ? filteredSuggestions : [];
  let allCount = 0;
  const [openfilter, setOpenfilter] = useState(false);
  let notAllCount = 0;
  const showDrawer = () => {
    setOpenfilter(true);
  };
  const onClose = () => {
    setOpenfilter(false);
  };
  selectedValues.forEach((value) => {
    if (Array.isArray(value) && value.includes("all")) {
      allCount++;
    } else {
      notAllCount++;
    }
  });
  return (
    <>
      {topchartstatus && (
        <AssetSpendTopListChart
          topchartstatus={topchartstatus}
          setTopChartStatus={setTopChartStatus}
          infrochart={infrochart}
          spendchart={spendchart}
          chartspend={chartspend}
          chartcount={chartcount}
        />
      )}

      <div style={{ display: "flex", height: "100%" }} className="costimize_bg">
        <OldRecords
          setChartAllSpend={setChartAllSpend}
          setChartAllCount={setChartAllCount}
        />
        <CostimizeSidebar />
        <MainHead title="Asset/Spend" />

        <div className="assets_spend_screen">
          <Row>
            <Col span={24}>
              <div className="year-picker-qg-top-right">
                <CostimazeDatePicker />
              </div>
            </Col>
          </Row>
          <Row style={{ display: "block", marginTop: "3rem" }}>
            <Col span={24} className="spend-asset-body">
              <div className="spend-search">
                <div className="d-flex">
                  {activeTab === "1" && (
                    <div className="searchOption">
                      <AutoComplete
                        style={{ width: 200, height: 30 }}
                        value={searchValue}
                        onChange={(value) => handleSearch(value)}
                        onSelect={handleSelect}
                        dataSource={autoCompleteDataSource}
                        placeholder="Enter Resource Name"
                        allowClear
                      />
                    </div>
                  )}

                  {/* <div
                    className="plat-icon-bg cursor-pointer"
                    onClick={() => setTopChartStatus(true)}
                  >
                    <Icon
                      icon="mdi:chart-bar-stacked"
                      style={{ fontSize: "20px" }}
                      className="factory-icon"
                    />
                  </div> */}
                  {activeTab === "1" && companyName !== "fivestar" && (
                    <div
                      className="plat-icon-bg cursor-pointer"
                      onClick={downloadExcel}
                    >
                      <Icon
                        icon="file-icons:microsoft-excel"
                        style={{ fontSize: "20px", color: "#ff6a2f" }}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="spend-tab">
                <Tabs
                  type="line"
                  defaultActiveKey="1"
                  items={items}
                  onChange={(key) => setActiveTab(key)}
                  className="assets-tabs"
                />
              </div>
            </Col>
          </Row>
        </div>
        <div className="filter-button ">
          {notAllCount > 0 && (
            <Badge
              count={notAllCount}
              offset={[-35, -6]}
              className="filter-badge"
            />
          )}
          <Button
            className={`vertical-button ${
              notAllCount ? "vertical-button-zero" : ""
            } rotated`}
            onClick={showDrawer}
          >
            <span>Filter</span>
            <span>
              <Icon
                icon="tdesign:filter"
                className={`filter-icon ${
                  notAllCount ? "filter-icon-zero" : ""
                } rotated `}
              />
            </span>
          </Button>
        </div>
      </div>
      <div className="costdemaze-drawer-filter">
        <Drawer
          title=""
          onClose={onClose}
          open={openfilter}
          size="300"
          className="costdemaze-filter-drawer"
        >
          <CostdemazeFilter />
        </Drawer>
      </div>
    </>
  );
};
export default memo(AssetSpendLayout);
