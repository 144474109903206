import { Icon } from "@iconify/react";
import { Col, Row, Tooltip } from "antd";
import moment from "moment";
import React from "react";
import ReactApexChart from "react-apexcharts";
import { PiFilePdfFill } from "react-icons/pi";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function Top5IncreaseSpend({ applicationlevelspentmore }) {
  const activeTab = useSelector(
    (state) => state.costimizespend.QuickGlancePageTop5SpendTabActiveTab
  );
  const { selectedTopdate } = useSelector((state) => state.constimize);
  // const endDateNsub3 = () => {
  //   return `  ${
  //     moment(selectedTopdate).isSame(new Date(), "month")
  //       ? moment().subtract(3, "days").format("YYYY-MM-DD")
  //       : moment(selectedTopdate).endOf("month").format("YYYY-MM-DD")
  //   } `;
  // };
  const endDateNsub3 = () => {
    const currentDate = moment();
    const threeDaysBefore = currentDate
      .subtract(3, "days")
      .format("YYYY-MMM-DD");
    return `  ${
      moment(selectedTopdate).isSame(currentDate, "month")
        ? threeDaysBefore
        : moment(selectedTopdate).endOf("month").format("YYYY-MMM-DD")
    } `;
  };
  function calculateWeekRanges() {
    const currentDate = moment();
    const startDate = moment(endDateNsub3()).startOf("week");
    let currentWeekEnd = startDate.clone().endOf("week");

    // Adjust current week end if it exceeds the current date
    if (currentWeekEnd.isAfter(currentDate)) {
      currentWeekEnd = currentDate;
    }

    const currentWeekStart = startDate.format("Do MMM");
    const currentWeekEndFormatted = currentWeekEnd.format("Do MMM");

    const previousWeekStart = startDate
      .clone()
      .subtract(1, "week")
      .format("Do MMM");
    const previousWeekEnd = startDate
      .clone()
      .subtract(1, "week")
      .endOf("week")
      .format("Do MMM");

    const prePreviousWeekStart = startDate
      .clone()
      .subtract(2, "weeks")
      .format("Do MMM");
    const prePreviousWeekEnd = startDate
      .clone()
      .subtract(2, "weeks")
      .endOf("week")
      .format("Do MMM");

    const fourthWeekStart = startDate
      .clone()
      .subtract(3, "weeks")
      .format("Do MMM");
    const fourthWeekEnd = startDate
      .clone()
      .subtract(3, "weeks")
      .endOf("week")
      .format("Do MMM");

    let generatedWeeks = {
      current_week: `${currentWeekStart} - ${moment(endDateNsub3()).format(
        "Do MMM"
      )}`,
      previous_week: `${previousWeekStart} - ${previousWeekEnd}`,
      pre_previous_week: `${prePreviousWeekStart} - ${prePreviousWeekEnd}`,
      fourth_week: `${fourthWeekStart} - ${fourthWeekEnd}`,
    };
    return [
      generatedWeeks.fourth_week,
      generatedWeeks.pre_previous_week,
      generatedWeeks.previous_week,
      generatedWeeks.current_week,
    ];
  }
  const difference = (diff) => {
    // || diff < 10
    if (diff == null) {
      return "-";
    } else {
      const formattedNumber = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0, // Ensure no rounding
        maximumFractionDigits: 2, // Specify the maximum number of fraction digits
      }).format(diff);
      return formattedNumber;
    }
  };

  let tableData;
  //   Prod/non-prod untagged logic--

  if (activeTab?.production) {
    tableData = applicationlevelspentmore.filter(
      (item) => item.environment === "Production"
    );
  }
  if (activeTab?.nonProduction) {
    tableData = applicationlevelspentmore.filter(
      (item) => item.environment === "Non-Production"
    );
  }
  if (activeTab?.unTagged) {
    tableData = applicationlevelspentmore.filter(
      (item) => item.environment === "Untagged"
    );
  }
  let highestValue = -Infinity;

  // Iterate over each object in the data array
  for (let i = 0; i < tableData.length; i++) {
    // Iterate over each week property in the current object
    for (let week in tableData[i]) {
      // Check if the property is a week property (current_week, previous_week, pre_previous_week, or fourth_week)
      if (week.includes("week")) {
        // Convert the value of the week property to number
        let weekValue = parseInt(tableData[i][week], 10);
        // Update highestValue if weekValue is greater
        if (weekValue > highestValue) {
          highestValue = weekValue;
        }
      }
    }
  }
  const navigate = useNavigate();
  const redirectToPdfPage = () => {
    navigate("/costdemaze/report/pdf#all");
  };

  return (
    <>
      <Row>
        <Col span={24}>
          {/* <JsonViewer data={columns} /> */}
          {/* {JSON.stringify(tableData, null, 4)} */}
          <table className={`tablGraph`}>
            <tr className="border-btm">
              <th
                onClick={() => navigate("/costdemaze/report/#all")}
                type="button"
              >
                <Tooltip
                  overlayClassName="custom-tooltip"
                  placement="right"
                  title="Generate PDF"
                >
                  Application{" "}
                  <PiFilePdfFill
                    size={14}
                    onClick={() => navigate("/costdemaze/report/#all")}
                  />
                </Tooltip>
              </th>
              <th className=" text-end " colSpan={4}></th>
              <th className="text-end">Difference</th>
            </tr>
            {tableData.map((item, index) => (
              <tr key={item.difference + index}>
                <td>{item.application}</td>
                <td colSpan={4}>
                  {/* <span className="flex justify-content-center ">
                    <StackedBarChartDemo />
                  </span> */}

                  <span className="flex justify-content-center ">
                    <ReactApexChart
                      options={{
                        chart: {
                          height: "100%",
                          type: "line",
                          sparkline: {
                            enabled: true,
                            height: "100%",
                          },
                          toolbar: {
                            show: false,
                          },
                        },
                        stroke: {
                          curve: "smooth",
                          width: 0.6, // Set the width of the line
                          opacity: 0.7,
                          colors: ["white"],
                        },
                        colors: ["white"],
                        xaxis: {
                          categories: calculateWeekRanges(),
                          labels: {
                            show: false, // Hide x-axis labels
                          },
                          axisBorder: {
                            show: false, // Hide x-axis line
                          },
                          axisTicks: {
                            show: false, // Hide x-axis ticks
                          },
                        },
                        yaxis: {
                          show: false, // Hide y-axis
                          max: highestValue + 50,
                        },
                        grid: {
                          show: false, // Hide grid lines
                        },
                        fill: {
                          opacity: 1, // Make the line area visible
                        },

                        tooltip: {
                          y: {
                            formatter: function (val) {
                              return "$" + val.toLocaleString("en-US"); 
                            },
                          },
                          enabled: true,
                          shared: true,
                          style: {
                            fontSize: "11px", 
                          },
                        },
                        markers: {
                          size: 2,
                          strokeWidth: 1,
                          fillOpacity: 1,
                          colors: ["white", "white", "white", "white"], // Specify colors for each point
                          hover: {
                            sizeOffset: 4,
                          },
                        },
                      }}
                      series={[
                        {
                          name: "Spend",
                          data: [
                            item.fourth_week || 0,
                            item.pre_previous_week || 0,
                            item.previous_week || 0,
                            item.current_week || 0,
                          ],
                        },
                      ]}
                      type="line"
                      height={40}
                      width={"90%"}
                    />
                  </span>
                </td>

                <td
                  className={
                    difference(item.difference) == "-"
                      ? "text-end "
                      : "text-red font-500 text-end "
                  }
                >
                  {difference(item.difference) == "-" ? (
                    "-"
                  ) : (
                    <>
                      {item.difference > 0 ? (
                        <>
                          {" "}
                          {difference(item.difference)}
                          <Icon icon="mdi:arrow-drop-up" color="red" />
                        </>
                      ) : (
                        <>
                          {" "}
                          <span style={{ color: "green" }}>
                            {difference(Math.abs(item.difference))}
                          </span>
                          <Icon
                            icon="mdi:arrow-drop-up"
                            style={{ color: "green" }}
                          />
                        </>
                      )}
                    </>
                  )}
                </td>
              </tr>
            ))}
          </table>
          {/* <pre>{JSON.stringify(tableData, null, 4)}</pre> */}
        </Col>
      </Row>
    </>
  );
}

export default Top5IncreaseSpend;
