import React, { useState, useEffect } from "react";
import { Table, Tooltip, Spin } from "antd";
import { filterAssetandSpendList } from "../../action/costimizedashboardAction";
import { Icon } from "@iconify/react";
import { useDispatch, useSelector } from "react-redux";
const AssetSpendother = (props) => {
  const {
    selectedCloud,
    selectedTopdate,
    selectedEnvironment,
    selectedAccount,
    selectedApplication,
    selectedservices,
    selectedos,
    selectedinfra,
    selecteddb,
  } = useSelector((state) => state.constimize);
  const dispatch = useDispatch();
  // const companyName = localStorage.getItem(
  //   "CognitoIdentityServiceProvider.company"
  // );

  var companyName = "all_cloud";
  const [tablerecord, setTablerecord] = useState([]);
  const [loadingstatus, setLoadingstatus] = useState(true);
  const columns = [
    {
      title: "Environment",
      dataIndex: "environment",
      key: "environment",
    },
    {
      title: "Spend",
      dataIndex: "spend",
      key: "spend",
      align: "center",
    },
  ];

  const groupedRecords = tablerecord.reduce((result, record) => {
    const environment = record.environments;
    if (!result[environment]) {
      result[environment] = {
        key_name: environment,
        records: [],
        totalCount: 0,
        totalSpend: 0,
        second_month_spend: 0,
      };
    }
    result[environment].records.push(record);
    result[environment].totalCount += parseInt(record.count, 10) || 0;
    result[environment].totalSpend += parseFloat(record.spend,15) || 0;
    result[environment].second_month_spend +=
      parseFloat(record.second_month_spend) || 0;
    return result;
  }, {});
  const aggregatedData = Object.values(groupedRecords);
  const mergedChildrenMap = new Map();
  aggregatedData.forEach((item, index) => {
    const environmentKey = item.key_name;
    if (!mergedChildrenMap.has(environmentKey)) {
      mergedChildrenMap.set(environmentKey, { ...item });
    } else {
      const mergedItem = mergedChildrenMap.get(environmentKey);
      mergedItem.count += item.totalCount;
      mergedItem.spend += parseFloat(item.totalSpend || 0);
      mergedItem.second_month_spend += parseFloat(item.second_month_spend || 0);
    }
  });
  const mergedChildren = Array.from(mergedChildrenMap.values());
  const spendValue = aggregatedData
    .reduce((total, item) =>{  return Number(total) + parseFloat(item.totalSpend || 0)}, 0)
    .toFixed(15)
    // .replace(/\.00$/, "")
    // .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  const t1 = aggregatedData
    .reduce((total, item) => total + parseFloat(item.totalSpend || 0), 0)
    .toFixed(0);
  const secondaryspendValue = aggregatedData
    .reduce(
      (total, item) => total + parseFloat(item.second_month_spend || 0),
      0
    )
    .toFixed(0)
    .replace(/\.00$/, "")
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  const generateAdditionalChildren = (data, parentKey) => {
    return groupedAndSummarizedDatasub
      .filter(
        (item) =>
          item.key_name === data.key_name && item.all_cloud === data.all_cloud
      )
      .map((item, index) => {
        let currentParentKey = `${parentKey}${index + 1}`;
        return {
          key: currentParentKey,
          environment: `${item.applications}(${item.totalCount})`,
          spend: (
            <>
              {`$${parseFloat(Math.abs(item.totalSpend || 0))
                .toFixed(0)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
              {
                <Icon
                  className={
                    "me-1 " +
                    (item.totalSpend > item.second_month_spend
                      ? "text-danger-icon-custom"
                      : "text-success-icon-custom")
                  }
                  icon={
                    item.totalSpend > item.second_month_spend
                      ? "material-symbols:arrow-drop-up"
                      : "material-symbols:arrow-drop-down"
                  }
                  style={{ fontSize: "20px" }}
                />
              }
            </>
          ),
        };
      });
  };
  const generateNestedChildren = (data, parentKey) => {
    return groupedAndSummarizedData
      .filter((item) => item.key_name === data.key_name)
      .map((item, index) => {
        let currentParentKey = `${parentKey}${index + 1}`;
        const additionalChildren = generateAdditionalChildren(
          item,
          currentParentKey
        );
        return {
          key: currentParentKey,
          environment: (
            <>
              {item.all_cloud === "AWS" && (
                <Icon icon="skill-icons:aws-light" className="asset-icon" />
              )}
              {item.all_cloud === "Azure" && (
                <Icon icon="skill-icons:azure-light" className="asset-icon" />
              )}
              {item.all_cloud === "GCP" && (
                <Icon icon="skill-icons:gcp-light" className="asset-icon" />
              )}
              {item.all_cloud !== "AWS" &&
                item.all_cloud !== "Azure" &&
                item.all_cloud !== "GCP" && (
                  <Icon icon="material-symbols:cloud" className="asset-icon" />
                )}
              {`${item.all_cloud}(${item.totalCount})`}
            </>
          ),
          spend: (
            <>
              {`$${parseFloat(Math.abs(item.totalSpend || 0))
                .toFixed(0)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}

              {
                <Icon
                  className={
                    "me-1 " +
                    (item.totalSpend > item.second_month_spend
                      ? "text-danger-icon-custom"
                      : "text-success-icon-custom")
                  }
                  icon={
                    item.totalSpend > item.second_month_spend
                      ? "material-symbols:arrow-drop-up"
                      : "material-symbols:arrow-drop-down"
                  }
                  style={{ fontSize: "20px" }}
                />
              }
            </>
          ),
          children: additionalChildren,
        };
      });
  };
  const groupAndSummarizeData = (data) => {
    const groupedData = {};
    data.forEach((group) => {
      const { key_name, records } = group;
      records.forEach((record) => {
        const { all_cloud, count, spend, environments } = record;
        const combinedKey = `${key_name}_${environments}_${all_cloud}`;
        if (!groupedData[combinedKey]) {
          groupedData[combinedKey] = {
            key_name: key_name,
            environments: environments,
            all_cloud: all_cloud,
            records: [],
            totalCount: 0,
            totalSpend: 0,
          };
        }
        groupedData[combinedKey].records.push(record);
        groupedData[combinedKey].totalCount += parseInt(count, 10) || 0;
        groupedData[combinedKey].totalSpend += parseFloat(spend) || 0;
      });
    });
    const result = Object.values(groupedData);
    return result;
  };
  const groupAndSummarizeDatasub = (data) => {
    const groupedData = {};
    data.forEach((group) => {
      const { key_name, records } = group;
      records.forEach((record) => {
        const { all_cloud, count, spend, environments, applications } = record;
        const combinedKey = `${key_name}_${environments}_${all_cloud}_${applications}`;
        if (!groupedData[combinedKey]) {
          groupedData[combinedKey] = {
            key_name: key_name,
            environments: environments,
            all_cloud: all_cloud,
            applications: applications,
            records: [],
            totalCount: 0,
            totalSpend: 0,
          };
        }
        groupedData[combinedKey].records.push(record);
        groupedData[combinedKey].totalCount += parseInt(count, 10) || 0;
        groupedData[combinedKey].totalSpend += parseFloat(spend) || 0;
      });
    });
    const result = Object.values(groupedData);
    return result;
  };
  const groupedAndSummarizedData = groupAndSummarizeData(mergedChildren);
  const groupedAndSummarizedDatasub = groupAndSummarizeDatasub(
    groupedAndSummarizedData
  );
  const parsedSpendValue = parseFloat(spendValue);
  const isPositive = parsedSpendValue > 0;
  const numericValue = parseFloat(spendValue.replace(/,/g, "")); // Convert string to number, removing commas
  const positiveValue = Math.abs(numericValue);

  const updatedData = [
    {
      key: 1,
      environment: `All Environment(${aggregatedData.reduce(
        (total, item) => total + item.totalCount,
        0
      )})`,
      spend: (
        <>
          {" "}
          {`$${parseFloat(
            Math.abs(parseFloat(spendValue.replace(/,/g, "")) || 0)
          )
            .toFixed(0)
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
          {/* <Icon
            className={`me-1 ${
              isPositive
                ? "text-danger-icon-custom"
                : "text-success-icon-custom"
            }`}
            icon={
              isPositive
                ? "material-symbols:arrow-drop-up"
                : "material-symbols:arrow-drop-down"
            }
            style={{ fontSize: "20px" }}
          /> */}
          <Icon
            className={`me-1 ${
              Number(t1) > Number(secondaryspendValue)
                ? "text-danger-icon-custom"
                : "text-success-icon-custom"
            }`}
            icon={
              Number(t1) > Number(secondaryspendValue)
                ? "material-symbols:arrow-drop-up"
                : "material-symbols:arrow-drop-down"
            }
            style={{ fontSize: "20px" }}
          />
          {/* <Icon
            className={`me-1 ${parseFloat(spendValue.replace(/,/g, "")) <
            secspend
                ? "text-success-icon-custom"
                : "text-danger-icon-custom"
              }`}
            icon={
              parseFloat(spendValue.replace(/,/g, "")) <
              secspend
                ? "material-symbols:arrow-drop-down"
                : "material-symbols:arrow-drop-up"
            }
            style={{ fontSize: "20px" }}
          /> */}
        </>
      ),
      children: mergedChildren.map((item, index) => {
        const childKey = 11 + index;
        return {
          key: 11 + index,
          environment: `${item.key_name}(${item.totalCount})`,
          spend: (
            <>
              {`$${parseFloat(Math.abs(item.totalSpend || 0))
                .toFixed(0)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
              <Icon
                className={`me-1 ${
                  item.totalSpend > item.second_month_spend
                    ? "text-danger-icon-custom"
                    : "text-success-icon-custom"
                }`}
                icon={
                  item.totalSpend > item.second_month_spend
                    ? "material-symbols:arrow-drop-up"
                    : "material-symbols:arrow-drop-down"
                }
                style={{ fontSize: "20px" }}
              />
            </>
          ),
          children: [...generateNestedChildren(item, `${childKey}`)],
        };
      }),
    },
  ];
  useEffect(() => {
    const apivalue = {
      environment: selectedEnvironment.toString(),
      account: selectedAccount.toString(),
      cloud: selectedCloud.toString(),
      application: selectedApplication.toString(),
      services: selectedservices.toString(),
      os: selectedos.toString(),
      infra: "Others",
      db: selecteddb.toString(),
      company_name: companyName,
    };
    dispatch(
      filterAssetandSpendList({ companyName, selectedTopdate, apivalue })
    )
      .unwrap()
      .then(({ data }) => {
        if (data.newfunctionquery.length > 0) {
          setTablerecord(data.newfunctionquery);
        } else {
          setTablerecord([]);
        }
        setLoadingstatus(false);
      })
      .catch((err) => {
        console.log(err.message);
        setLoadingstatus(false);
      });
  }, [
    selectedTopdate,
    selectedEnvironment,
    selectedAccount,
    selectedCloud,
    selectedApplication,
    selectedservices,
    selectedos,
    selecteddb,
    companyName,
  ]);
  return (
    <>
      {" "}
      <div>
        <Spin spinning={loadingstatus} wrapperClassName="costimize-loader">
          <Table
            columns={columns}
            dataSource={updatedData}
            className="assets-table"
          />
        </Spin>
      </div>
    </>
  );
};
export default AssetSpendother;
