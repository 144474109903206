import { React, useState } from "react";
import { Form, Input, Button, Row, Col,Tabs } from "antd";
import { useNavigate } from "react-router-dom";

function LandingZoneForm() {
  const [form] = Form.useForm();
  const { TabPane } = Tabs;
  const [state, setState] = useState({
    loggingaccountemail: "",
    loggingaccountname: "",
    securityaccountemail: "",
    securityaccountname: "",
  });

      const handleSubmit = (event) => {
        event.preventDefault();
      };

  // const handleInputChange = (event) => {
  //   const { name, value } = event.target;
  //   setState((prevProps) => ({
  //     ...prevProps,
  //     [name]: value,
  //   }));
  // };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    console.log(`Input changed: ${name} - ${value}`);
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // const handleSubmit = (event) => {
  //   event.preventDefault();
  //   console.log(state);
  // };

  const onFinish = (values) => {
    console.log("Form values:", values);
    console.log("State values:", state);
  };

  const navigate = useNavigate();

  function HandleOpen() {
    navigate("/landingzoneadd");
  }

  // const HandleSave = () => {
  //     form.validateFields().then(() => {
  //       navigate("/landingzoneformpage");
  //     }).catch((errorInfo) => {
  //       console.log("Form validation failed:", errorInfo);
  //     });
  //   };

  const HandleSave = async () => {
    try {
      await form.validateFields();

      console.log("Form validation succeeded");
      console.log("State values:", state);
      const jsonFormat = JSON.stringify(state);
      console.log("JSON format:", jsonFormat);
      navigate("/landingzoneformpage");
    } catch (errorInfo) {
      console.log("Form validation failed:", errorInfo);
    }
  };

  // function HandleSave() {
  //     navigate("/landingzoneformpage");}

  return (
    <>
       

      {/* <Form onSubmit={handleSubmit} form={form}> */}
      <Form onFinish={onFinish} form={form}>
        <div className="landingzoneadd">
          <Row gutter={[16, 16]} className="d-flex justify-content-center">
            <Col xxl={18} xl={18} lg={18} md={18} sm={24}>
              <Row gutter={[16, 16]}>
                <Col xxl={5} xl={5} lg={5} md={5} sm={24}>
                  <span className="devops-form-names1">
                    Logging Account Email
                  </span>
                </Col>
                <Col xxl={1} xl={1} lg={1} md={1} sm={1}></Col>
                <Col xxl={18} xl={18} lg={18} md={18} sm={24}>
                  <Form.Item
                    name="cloud_platform"
                    rules={[{ required: true, message: "Required" }]}
                  >
                    <Input
                      type="text"
                      className="devops-antd-select"
                      placeholder="Enter Security AccountEmail"
                      name="loggingaccountemail"
                      value={state.loggingaccountemail}
                      onChange={handleInputChange}
                    />

                    {/* <Input type="text" className="devops-antd-select" placeholder="Enter logging accountemail" value={state.loggingaccountemail} onChange={handleInputChange}/> */}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col xxl={5} xl={5} lg={5} md={5} sm={24}>
                  <span className="devops-form-names1">
                    Logging Account Name
                  </span>
                </Col>
                <Col xxl={1} xl={1} lg={1} md={1} sm={1}></Col>
                <Col xxl={18} xl={18} lg={18} md={18} sm={24}>
                  <Form.Item
                    name="account_name"
                    rules={[{ required: true, message: "Required" }]}
                  >
                    <Input
                      type="text"
                      className="devops-antd-select"
                      placeholder="Enter Logging accountname"
                      name="loggingaccountname"
                      value={state.loggingaccountemail}
                      onChange={handleInputChange}
                    />
                    {/* <Input type="text" className="devops-antd-select" placeholder="Enter Logging accountname" value={state.securityaccountemail} onChange={handleInputChange}/> */}
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[16, 16]}>
                <Col xxl={6} xl={6} lg={6} md={6} sm={24}>
                  <span className="devops-form-names1">
                    Security Account Email
                  </span>
                </Col>
                <Col xxl={18} xl={18} lg={18} md={18} sm={24}>
                  <Form.Item
                    name="service_name"
                    rules={[{ required: true, message: "Required" }]}
                  >
                    <Input
                      type="text"
                      className="devops-antd-select"
                      placeholder="Enter Security AccountEmail"
                      name="securityaccountemail"
                      value={state.securityaccountname}
                      onChange={handleInputChange}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[16, 16]}>
                <Col xxl={5} xl={5} lg={5} md={5} sm={24}>
                  <span className="devops-form-names1">
                    Security Account Name
                  </span>
                </Col>
                <Col xxl={1} xl={1} lg={1} md={1} sm={1}></Col>
                <Col xxl={18} xl={18} lg={18} md={18} sm={24}>
                  <Form.Item
                    name="securityaccountname"
                    rules={[{ required: true, message: "Required" }]}
                  >
                    <Input
                      type="text"
                      className="devops-antd-select"
                      placeholder="Enter Security AccountName"
                      name="securityaccountname"
                      value={state.securityaccountname}
                      onChange={handleInputChange}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
         
        </div>
        <br />
        {/* <div className="SaveBack"> */}
        <Row gutter={[16, 16]} className="d-flex justify-content-between landing-zone-footer ">
          <Col span={12}>
            {/* <Form.Item style={{ textAlign: "right" }}> */}
              <Button
                className="form-back-button"
                htmlType="button"
                onClick={HandleOpen}
              >
                Back
              </Button>
            {/* </Form.Item> */}
          </Col>
          {/* <Button
          type="primary"
          htmlType="submit"
          className="form-button save-form-button"
          onClick={HandleSave}
        > */}
          <Col span={12}>
            {/* <Form.Item style={{ textAlign: "right" }}> */}
              <Button
                type="primary"
                onClick={HandleSave}
                className="form-button save-form-button"
              >
                {" "}
                Save
              </Button>
            {/* </Form.Item> */}
          </Col>
        </Row>
        {/* </div> */}
      </Form>
     
    </>
  );
}

export default LandingZoneForm;
