import { JoinFull } from "@mui/icons-material";
import { Table } from "antd";
import { useEffect } from "react";
import { useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { CgDetailsMore } from "react-icons/cg";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import { Icon } from "@iconify/react";

const PlannedVsActualTable = (props) => {
  const [plannedrecords, setPlannedrecords] = useState({
    jan: "0",
    feb: "0",
    mar: "0",
    apr: "0",
    may: "0",
    jun: "0",
    jul: "0",
    aug: "0",
    sep: "0",
    oct: "0",
    nov: "0",
    dec: "0",
    annual_total: "0",
  });
  const [tablerecords, setTableRecords] = useState([]);
  const currencyFormatter = (val) => {
    if (val === null || val === 0) {
      return "-";
    } else if (isNaN(Number(val))) {
      return 0;
    } else {
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD", // Change to your desired currency code
        minimumFractionDigits: 0, // Minimum number of fraction digits
        maximumFractionDigits: 2, // Maximum number of fraction digits
      }).format(Number(val));
    }
  };
  
  const columns = [
    {
      title: " ",
      dataIndex: "title",
      key: "title",
      width: "1rem",
    },
    {
      title: "Year",
      dataIndex: "year",
      key: "year",
      align: "left",
      width: "1rem",
    },
    {
      title: "Jan",
      dataIndex: "jan",
      key: "jan",
      align: "right",
      render: (record, index) => {
        const number = Number(record);
        if (index.type === "planned" || number === 0) {
          // return `$${number.toLocaleString(undefined, {
          //   maximumFractionDigits: 2,
          // })}`;
          return currencyFormatter(number)
        } else {
          if (Number(plannedrecords.jan) > number) {
            return (
              <>
                <span style={{ color: "rgb(10 137 0)" }}>
                  {" "}
                  {currencyFormatter(number)}{" "}
                </span>
              </>
            );
          } else {
            return (
              <>
                <span style={{ color: "#be1e2d" }}>
                  {" "} {currencyFormatter(number)}
                  {" "}
                </span>
              </>
            );
          }
        }
      },
    },
    {
      title: "Feb",
      dataIndex: "feb",
      key: "feb",
      align: "right",
      render: (record, index) => {
        const number = Number(record);
        if (index.type === "planned" || number === 0) {
          return currencyFormatter(number)
        } else {
          if (Number(plannedrecords.feb) > number) {
            return (
              <>
                <span style={{ color: "rgb(10 137 0)" }}>
                  {" "}
                  {currencyFormatter(number)}{" "}
                </span>
              </>
            );
          } else {
            return (
              <>
                <span style={{ color: "#be1e2d" }}>
                  {" "}
                  $
                  {currencyFormatter(number)}{" "}
                </span>
              </>
            );
          }
        }
      },
    },
    {
      title: "Mar",
      dataIndex: "mar",
      key: "mar",
      align: "right",
      render: (record, index) => {
        const number = Number(record);
        if (index.type === "planned" || number === 0) {
          return currencyFormatter(number)
        } else {
          if (Number(plannedrecords.mar) > number) {
            return (
              <>
                <span style={{ color: "rgb(10 137 0)" }}>
                  {" "}
                  {currencyFormatter(number)}{" "}
                </span>
              </>
            );
          } else {
            return (
              <>
                <span style={{ color: "#be1e2d" }}>
                  {" "}
                  {currencyFormatter(number)}{" "}
                </span>
              </>
            );
          }
        }
      },
    },
    {
      title: "Apr",
      dataIndex: "apr",
      key: "apr",
      align: "right",
      render: (record, index) => {
        const number = Number(record);
        if (index.type === "planned" || number === 0) {
          return currencyFormatter(number)
        } else {
          if (Number(plannedrecords.apr) > number) {
            return (
              <>
                <span style={{ color: "rgb(10 137 0)" }}>
                  {" "}
                  $
                  {currencyFormatter(number)}{" "}
                </span>
              </>
            );
          } else {
            return (
              <>
                <span style={{ color: "#be1e2d" }}>
                  {" "}
                  {currencyFormatter(number)}{" "}
                </span>
              </>
            );
          }
        }
      },
    },
    {
      title: "May",
      dataIndex: "may",
      key: "may",
      align: "right",
      render: (record, index) => {
        const number = Number(record);
        if (index.type === "planned" || number === 0) {
          return currencyFormatter(number)
        } else {
          if (Number(plannedrecords.may) > number) {
            return (
              <>
                <span style={{ color: "rgb(10 137 0)" }}>
                  {" "}
                  {currencyFormatter(number)}{" "}
                </span>
              </>
            );
          } else {
            return (
              <>
                <span style={{ color: "#be1e2d" }}>
                  {" "}
                  {currencyFormatter(number)}{" "}
                </span>
              </>
            );
          }
        }
      },
    },
    {
      title: "Jun",
      dataIndex: "jun",
      key: "jun",
      align: "right",
      render: (record, index) => {
        const number = Number(record);
        if (index.type === "planned" || number === 0) {
          return currencyFormatter(number)
        } else {
          if (Number(plannedrecords.jun) > number) {
            return (
              <>
                <span style={{ color: "rgb(10 137 0)" }}>
                  {" "}
                  {currencyFormatter(number)}{" "}
                </span>
              </>
            );
          } else {
            return (
              <>
                <span style={{ color: "#be1e2d" }}>
                  {" "}
                  {currencyFormatter(number)}{" "}
                </span>
              </>
            );
          }
        }
      },
    },
    {
      title: "Jul",
      dataIndex: "jul",
      key: "jul",
      align: "right",
      render: (record, index) => {
        const number = Number(record);
        if (index.type === "planned" || number === 0) {
          return currencyFormatter(number)
        } else {
          if (Number(plannedrecords.jul) > number) {
            return (
              <>
                <span style={{ color: "rgb(10 137 0)" }}>
                  {" "}
                  {currencyFormatter(number)}{" "}
                </span>
              </>
            );
          } else {
            return (
              <>
                <span style={{ color: "#be1e2d" }}>
                  {" "}
                  {currencyFormatter(number)}{" "}
                </span>
              </>
            );
          }
        }
      },
    },
    {
      title: "Aug",
      dataIndex: "aug",
      key: "aug",
      align: "right",
      render: (record, index) => {
        const number = Number(record);
        if (index.type === "planned" || number === 0) {
          return currencyFormatter(number)
        } else {
          if (Number(plannedrecords.aug) > number) {
            return (
              <>
                <span style={{ color: "rgb(10 137 0)" }}>
                  {" "}
                  {currencyFormatter(number)}{" "}
                </span>
              </>
            );
          } else {
            return (
              <>
                <span style={{ color: "#be1e2d" }}>
                  {" "}
                  {currencyFormatter(number)}{" "}
                </span>
              </>
            );
          }
        }
      },
    },
    {
      title: "Sep",
      dataIndex: "sep",
      key: "sep",
      align: "right",
      render: (record, index) => {
        const number = Number(record);
        if (index.type === "planned" || number === 0) {
          return currencyFormatter(number)
        } else {
          if (Number(plannedrecords.sep) > number) {
            return (
              <>
                <span style={{ color: "rgb(10 137 0)" }}>
                  {" "}
                  {currencyFormatter(number)}{" "}
                </span>
              </>
            );
          } else {
            return (
              <>
                <span style={{ color: "#be1e2d" }}>
                  {" "}
                  {currencyFormatter(number)}{" "}
                </span>
              </>
            );
          }
        }
      },
    },
    {
      title: "Oct",
      dataIndex: "oct",
      key: "oct",
      align: "right",
      render: (record, index) => {
        const number = Number(record);
        if (index.type === "planned" || number === 0) {
          return currencyFormatter(number)
        } else {
          if (Number(plannedrecords.oct) > number) {
            return (
              <>
                <span style={{ color: "rgb(10 137 0)" }}>
                  {" "}
                  {currencyFormatter(number)}{" "}
                </span>
              </>
            );
          } else {
            return (
              <>
                <span style={{ color: "#be1e2d" }}>
                  {" "}
                  {currencyFormatter(number)}{" "}
                </span>
              </>
            );
          }
        }
      },
    },
    {
      title: "Nov",
      dataIndex: "nov",
      key: "nov",
      align: "right",
      render: (record, index) => {
        const number = Number(record);
        if (index.type === "planned" || number === 0) {
          return currencyFormatter(number)
        } else {
          if (Number(plannedrecords.nov) > number) {
            return (
              <>
                <span style={{ color: "rgb(10 137 0)" }}>
                  {" "}
                  {currencyFormatter(number)}{" "}
                </span>
              </>
            );
          } else {
            return (
              <>
                <span style={{ color: "#be1e2d" }}>
                  {" "}
                  {currencyFormatter(number)}{" "}
                </span>
              </>
            );
          }
        }
      },
    },
    {
      title: "Dec",
      dataIndex: "dec",
      key: "dec",
      align: "right",
      render: (record, index) => {
        const number = Number(record);
        if (index.type === "planned" || number === 0) {
          return currencyFormatter(number)
        } else {
          if (Number(plannedrecords.dec) > number) {
            return (
              <>
                <span style={{ color: "rgb(10 137 0)" }}>
                  {" "}
                  {currencyFormatter(number)}{" "}
                </span>
              </>
            );
          } else {
            return (
              <>
                <span style={{ color: "#be1e2d" }}>
                  {" "}
                  {currencyFormatter(number)}{" "}
                </span>
              </>
            );
          }
        }
      },
    },
    {
      title: "Annual Total",
      dataIndex: "annual_total",
      key: "annual_total",
      align: "right",
      render: (record, index) => {
        const number = Number(record);
        if (index.type === "planned" || number === 0) {
          return currencyFormatter(number)
        } else {
          if (Number(plannedrecords.annual_total) > number) {
            return (
              <>
                <span style={{ color: "rgb(10 137 0)" }}>
                  {" "}
                  {currencyFormatter(number)}{" "}
                </span>
              </>
            );
          } else {
            return (
              <>
                <span style={{ color: "#be1e2d" }}>
                  {" "}
                  {currencyFormatter(number)}{" "}
                </span>
              </>
            );
          }
        }
      },
    },
  ];

  const tableProps = {};

  useEffect(() => {
    if (props.actualtablerecords) {
      const actual = props.actualtablerecords;
  
      // Initialize an array for monthly spend, defaulting to 0 for each month
      const actual_list = Array(12).fill(0);
  
      // Sum up spend values for each month
      actual.forEach((record) => {
        if (record.spend && record.reportmonth) {
          const monthIndex = new Date(record.reportmonth).getMonth(); // 0 for Jan, 11 for Dec
          actual_list[monthIndex] += Number(record.spend);
        }
      });
  
      // Prepare actual record
      const current_year = new Date().getFullYear();
      const actual_record = {
        key: "2",
        year: current_year,
        jan: Math.round(actual_list[0]),
        feb: Math.round(actual_list[1]),
        mar: Math.round(actual_list[2]),
        apr: Math.round(actual_list[3]),
        may: Math.round(actual_list[4]),
        jun: Math.round(actual_list[5]),
        jul: Math.round(actual_list[6]),
        aug: Math.round(actual_list[7]),
        sep: Math.round(actual_list[8]),
        oct: Math.round(actual_list[9]),
        nov: Math.round(actual_list[10]),
        dec: Math.round(actual_list[11]),
        annual_total: actual_list.reduce((sum, val) => sum + val, 0), // Total annual spend
        action: "",
        title: "Actual",
        type: "actual",
      };
  
      // Prepare planned record
      let planned_records = {
        key: "1",
        year: "2023",
        jan: 0,
        feb: 0,
        mar: 0,
        apr: 0,
        may: 0,
        jun: 0,
        jul: 0,
        aug: 0,
        sep: 0,
        oct: 0,
        nov: 0,
        dec: 0,
        annual_total: 0,
        action: "",
        title: "Planned",
        type: "planned",
      };
  
      // Update planned records if matching year found
      props.yearlistrecords.forEach((data) => {
        if (Number(data.budget_year) === current_year) {
          planned_records = {
            key: "1",
            year: Number(data.budget_year),
            jan: data.budget_jan,
            feb: data.budget_feb,
            mar: data.budget_mar,
            apr: data.budget_apr,
            may: data.budget_may,
            jun: data.budget_jun,
            jul: data.budget_jul,
            aug: data.budget_aug,
            sep: data.budget_sept,
            oct: data.budget_oct,
            nov: data.budget_nov,
            dec: data.budget_dec,
            annual_total: data.annual_total,
            action: "",
            title: "Planned",
            type: "planned",
          };
        }
      });
  
      // Update state with new records
      setPlannedrecords(planned_records);
      setTableRecords([planned_records, actual_record]);
    }
  }, [props]);
  
  const applicationExport = () => {
    const tablelist = tablerecords.map((data) => {
      return {
        title: data.title,
        Jan: data.jan,
        Feb: data.feb,
        Mar: data.mar,
        Apr: data.apr,
        May: data.may,
        Jun: data.jun,
        Jul: data.jul,
        Aug: data.aug,
        Sep: data.sep,
        Oct: data.oct,
        Nov: data.nov,
        Dec: data.dec,
        "Annual Total": data.annual_total,
      };
    });
    const fileName = "Planned vs. Actual";
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheettml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(tablelist);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <>
      <div className="budget-search">
        <div className="budget-float-right" style={{ display: "flex" }}>
          <div className="budget-table-top-icon" onClick={applicationExport} >
        
            <Icon 
              icon="file-icons:microsoft-excel"
              style={{ fontSize: "16px", color: "#ff6a2f",cursor:"pointer" }}
            />
          </div>
        </div>
      </div>
      <div className="costimize-table-width">
        <div className="plannedVsActualTable">
          <Table
            {...tableProps}
            // className="budget-table"
            className="slm"
            columns={columns}
            dataSource={tablerecords}
            pagination={false}
            // rowClassName={(record, index) => {
            //     console.log(record);
            //     console.log(index);
            // }}
          />
        </div>
      </div>
    </>
  );
};

export default PlannedVsActualTable;
