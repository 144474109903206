import { Card, Col, Row, Spin } from "antd";
import React, { useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import {
  getOperateDBSpread,
  getOperateDRSpread,
  getOperateMonitoring,
  getOperateOSCount,
  getOperateOUCount,
  getOperateRAMUtil,
  getOperateServiceCount,
  getOperateStorageUtil,
  getOperateVMDetails,
  kuberneteslandscapespread,
  operateecs,
} from "../action/graphql/a2crover/A2CReducer";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { numberFormat } from "../custom_hook/CustomHook";

function OperateAssetsPerspective() {
  const dispatch = useDispatch();
  const [isShowSpinner, setIsShowSpinner] = useState(true);

  const company = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );

  const {
    selectedEnvironment,
    selectedAccount,
    selectedApplication,
    selectedou,
  } = useSelector((store) => store.a2cRover);
  // Input for ECS WHICH IS DIFFERENT FROM OTHERS
  const input_ecs = {
    account_name: selectedAccount.toString(),
    company: company,
    application: selectedApplication.toString(),
    environment: selectedEnvironment.toString(),
    organization_unit: selectedou.toString(),
  };
  const input_k8 = {
    application: selectedApplication.toString(),
    company: company,
    environment: selectedEnvironment.toString(),
    organization_unit: selectedou.toString(),
    account_name: selectedAccount.toString(),
  };
  // EXCEPT ECS OTHER CHARTS INPUT
  const input = {
    account_name: selectedAccount.toString(),
    company: company,
    application: selectedApplication.toString(),
    environment: selectedEnvironment.toString(),
    organiation_unit: selectedou.toString(),
  };
  const [vmDetails, setVMDetails] = useState({
    vm_count: 0,
    paas: 0,
    disk_count: 0,
    total_cpu: 0,
    total_memory: 0,
    total_storage: 0,
  });
  const [kubernetesValues, setKubernetesValues] = useState({
    containers: 0,
    deployments: 0,
    forgate: 0,
    namespaces: 0,
    nodes: 0,
    pods: 0,
    resource_count: 0,
    services: 0,
  });
  const [ecs, setEcs] = useState({
    container_count: 0,
    resource_count: 0,
  });
  const [ouCount, setOUCount] = useState([]);
  const ou_labels = ouCount.map((item) => item.ou);
  const ou_series = ouCount.map((item) => item.resource_count);
  const numeric_ou_series = ou_series.map((value) => parseInt(value, 10));
  const [drSpread, setDRSpread] = useState([]);
  const dr_labels = drSpread.map((item) => item.dr);
  const dr_series = drSpread.map((item) => item.app_count);
  const numeric_dr_series = dr_series.map((value) => parseInt(value, 10));
  const [dbSpread, setDBSpread] = useState([]);
  const db_labels = dbSpread.map((item) => item.database);
  const db_series = dbSpread.map((item) => item.db_count);
  const numeric_db_series = db_series.map((value) => parseInt(value, 10));
  const [monitoring, setMonitoring] = useState([]);
  const monitoring_labels = monitoring.map((item) => item.monitoring_status);
  const monitoring_series = monitoring.map((item) => item.application_count);
  const numeric_monitoring_series = monitoring_series.map((value) =>
    parseInt(value, 10)
  );

  const [serviceCount, setServiceCount] = useState([]);
  const service_labels = serviceCount.map((item) => item.service);
  const service_series = serviceCount.map((item) => item.resource_count);
  const numeric_service_series = service_series.map((value) =>
    parseInt(value, 10)
  );
  const [osCount, setOSCount] = useState([]);
  const os_labels = osCount.map((item) => item.os);
  const os_series = osCount.map((item) => item.os_count);
  const numeric_os_series = os_series.map((value) => parseInt(value, 10));
  const [storageUtil, setStorageUtil] = useState([]);
  const [ramUtil, setRAMUtil] = useState([]);
  useEffect(() => {
    dispatch(getOperateVMDetails(input))
      .unwrap()
      .then(({ data }) => {
        setIsShowSpinner(false);
        // console.log(data);
        if (data.operate_vm_details.length === 0) {
          setVMDetails({
            vm_count: 0,
            paas: 0,
            disk_count: 0,
            total_cpu: 0,
            total_memory: 0,
            total_storage: 0,
          });
        } else {
          let obj = {};
          // for (const key in data.operate_vm_details[0]) {
          //   if (data.operate_vm_details[0][key] === null) {
          //     obj[key] = 0;
          //   } else {
          //     obj[key] = data.operate_vm_details[0][key];
          //   }
          // }
          setVMDetails(data.operate_vm_details[0]);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    // second api
    dispatch(getOperateOUCount(input))
      .unwrap()
      .then(({ data }) => {
        setIsShowSpinner(false);
        const record = data.operate_ou_spread.map((datas) => {
          return {
            ou: datas.ou,
            resource_count: datas.resource_count,
          };
        });
        setOUCount(record);
      })
      .catch((err) => {
        console.log(err);
      });
    // third api
    dispatch(getOperateDRSpread(input))
      .unwrap()
      .then(({ data }) => {
        setIsShowSpinner(false);
        const record = data.operate_dr_spread.map((datas) => {
          return {
            dr: datas.dr,
            app_count: datas.app_count,
          };
        });
        setDRSpread(record);
      })
      .catch((err) => {
        console.log(err);
      });
    //kubernetes api
    dispatch(kuberneteslandscapespread(input_k8))
      .unwrap()
      .then(({ data }) => {
        // console.log(data);
        if (data.operate_k8s_spread.length === 0) {
          setKubernetesValues({
            containers: 0,
            deployments: 0,
            forgate: 0,
            namespaces: 0,
            nodes: 0,
            pods: 0,
            resource_count: 0,
            services: 0,
          });
        } else {
          setKubernetesValues(data.operate_k8s_spread[0]);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    //ecs api
    dispatch(operateecs(input_ecs))
      .unwrap()
      .then(({ data }) => {
        setIsShowSpinner(false);
        // console.log(data);
        if (data.operate_ecs_spread_count.length === 0) {
          setEcs({
            container_count: 0,
            resource_count: 0,
          });
        } else {
          let obj = {};
          // for (const key in data.operate_vm_details[0]) {
          //   if (data.operate_vm_details[0][key] === null) {
          //     obj[key] = 0;
          //   } else {
          //     obj[key] = data.operate_vm_details[0][key];
          //   }
          // }
          setEcs(data.operate_ecs_spread_count[0]);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    // fourth api
    dispatch(getOperateDBSpread(input))
      .unwrap()
      .then(({ data }) => {
        setIsShowSpinner(false);
        const record = data.operate_db_spread.map((datas) => {
          return {
            database: datas.database,
            db_count: datas.db_count,
          };
        });
        setDBSpread(record);
      })
      .catch((err) => {
        console.log(err);
      });
    // fifthapi
    dispatch(getOperateMonitoring(input))
      .unwrap()
      .then(({ data }) => {
        setIsShowSpinner(false);
        const record = data.operate_monitoring_implementation.map((datas) => {
          return {
            monitoring_status: datas.monitoring_status,
            application_count: datas.application_count,
          };
        });
        setMonitoring(record);
      })
      .catch((err) => {
        console.log(err);
      });
    // sixth api
    dispatch(getOperateServiceCount(input))
      .unwrap()
      .then(({ data }) => {
        setIsShowSpinner(false);
        const record = data.operate_service_spread.map((datas) => {
          return {
            service: datas.service,
            resource_count: datas.resource_count,
          };
        });
        setServiceCount(record);
      })
      .catch((err) => {
        console.log(err);
      });
    // seventh api
    dispatch(getOperateOSCount(input))
      .unwrap()
      .then(({ data }) => {
        setIsShowSpinner(false);
        const record = data.operate_os_spread.map((datas) => {
          return {
            os: datas.os,
            os_count: datas.os_count,
          };
        });
        setOSCount(record);
      })
      .catch((err) => {
        console.log(err);
      });
    // 8th api
    // dispatch(getOperateStorageUtil(input))
    //   .unwrap()
    //   .then(({ data }) => {
    //     setIsShowSpinner(false);
    //     const record = data.operate_storage_util.map((datas) => {
    //       return {
    //         x: datas.ou,
    //         y: parseInt(datas.storage, 10),
    //       };
    //     });
    //     setStorageUtil([{ data: record }]);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
    dispatch(getOperateStorageUtil(input))
  .unwrap()
  .then(({ data }) => {
    setIsShowSpinner(false);
    const record = data.operate_storage_util.map((datas) => {
      return {
        x: datas.ou,
        y: new Intl.NumberFormat('en-US').format(parseInt(datas.storage, 10)),
      };
    });
    setStorageUtil([{ data: record }]);
  })
  .catch((err) => {
    console.log(err);
  });

    // 9th api
    dispatch(getOperateRAMUtil(input))
      .unwrap()
      .then(({ data }) => {
        setIsShowSpinner(false);
        const record = data.operate_ram_util.map((datas) => {
          return {
            x: datas.application,
            y: parseInt(datas.mem_util, 10),
          };
        });
        setRAMUtil([{ data: record }]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [selectedEnvironment, selectedAccount, selectedApplication, selectedou]);

  const ou_options = {
    chart: {
      type: "donut",
    },
    dataLabels: {
      formatter: function (val) {
        // Remove the percentage symbol from the labels
        return val.toFixed(0);
      },
    },
    noData: {
      text: "No Data",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: "#fff",
        fontSize: "20px",
        fontFamily: "Poppins",
      },
    },
    tooltip: {
      enabled: true,
      theme: "light",
      y: {
        formatter: function (value) {
          return value + " Nos";
        },
      },
      fillSeriesColor: false, // You can use 'light' or 'dark' theme
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            value: {
              show: true,
              fontSize: "23px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 600,
              color: "#ff6a2f",
              offsetY: -5,
              formatter: function (val) {
                return val;
              },
            },
            total: {
              show: true,
              showAlways: true,
              label: "",
              color: "#ff6a2f",
              formatter: function (w) {
                return w.globals.seriesTotals.reduce((a, b) => {
                  return a + b;
                }, 0);
              },
            },
          },
        },
      },
    },

    //////////trial ends
    colors: [
      "#9FDEF1",
      "#2A5D78",
      "#F6AA54",
      "#A7EE7C",
      "#F65454",
      "#057EC1",
      "#230B34",
      "#FE632B",
    ],
    labels: ou_labels,
    legend: {
      position: "bottom",
      labels: {
        colors: "#ffffff",
      },
      horizontalAlign: "center",
    },
    stroke: {
      show: false,
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          xaxis: {
            labels: {
              style: {
                colors: "#FFFFFF",
              },
            },
          },
          yaxis: {
            labels: {
              style: {
                colors: "#FFFFFF",
              },
            },
          },
          dataLabels: {
            formatter: function (val) {
              // Remove the percentage symbol from the labels
              return val.toFixed(0);
            },
          },
          tooltip: {
            enabled: true,
            theme: "light",
            y: {
              formatter: function (value) {
                return value + " Nos";
              },
            },
            fillSeriesColor: false, // You can use 'light' or 'dark' theme
          },
          legend: {
            position: "bottom",
            labels: {
              colors: "#ffffff",
            },
          },
        },
      },
    ],
  };

  const service_options = {
    chart: {
      type: "donut",
    },
    dataLabels: {
      formatter: function (val) {
        // Remove the percentage symbol from the labels
        return val.toFixed(0);
      },
    },
    noData: {
      text: "No Data",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: "#fff",
        fontSize: "20px",
        fontFamily: "Poppins",
      },
    },
    tooltip: {
      enabled: true,
      theme: "light",
      y: {
        formatter: function (value) {
          return value + " Nos";
        },
      },
      fillSeriesColor: false, // You can use 'light' or 'dark' theme
    },
    colors: [
      "#9FDEF1",
      "#2A5D78",
      "#F6AA54",
      "#A7EE7C",
      "#F65454",
      "#057EC1",
      "#230B34",
      "#FE632B",
    ],
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            value: {
              show: true,
              fontSize: "23px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 600,
              color: "#ff6a2f",
              offsetY: -5,
              formatter: function (val) {
                return val;
              },
            },
            total: {
              show: true,
              showAlways: true,
              label: "",
              color: "#ff6a2f",
              formatter: function (w) {
                return w.globals.seriesTotals.reduce((a, b) => {
                  return a + b;
                }, 0);
              },
            },
          },
        },
      },
    },
    labels: service_labels,
    legend: {
      position: "bottom",
      labels: {
        colors: "#ffffff",
      },
      horizontalAlign: "center",
    },
    stroke: {
      show: false,
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          xaxis: {
            labels: {
              style: {
                colors: "#FFFFFF",
              },
            },
          },
          yaxis: {
            labels: {
              style: {
                colors: "#FFFFFF",
              },
            },
          },
          dataLabels: {
            formatter: function (val) {
              // Remove the percentage symbol from the labels
              return val.toFixed(0);
            },
          },
          tooltip: {
            enabled: true,
            theme: "light",
            y: {
              formatter: function (value) {
                return value + " Nos";
              },
            },
            fillSeriesColor: false, // You can use 'light' or 'dark' theme
          },
          legend: {
            position: "bottom",
            labels: {
              colors: "#ffffff",
            },
          },
        },
      },
    ],
  };

  const os_options = {
    chart: {
      type: "donut",
    },
    dataLabels: {
      formatter: function (val) {
        // Remove the percentage symbol from the labels
        return val.toFixed(0);
      },
    },
    noData: {
      text: "No Data",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: "#fff",
        fontSize: "20px",
        fontFamily: "Poppins",
      },
    },
    tooltip: {
      enabled: true,
      theme: "light",
      y: {
        formatter: function (value) {
          return value + " Nos";
        },
      },
      fillSeriesColor: false, // You can use 'light' or 'dark' theme
    },
    colors: [
      "#9FDEF1",
      "#2A5D78",
      "#F6AA54",
      "#A7EE7C",
      "#F65454",
      "#057EC1",
      "#230B34",
      "#FE632B",
    ],
    labels: os_labels,
    legend: {
      position: "bottom",
      labels: {
        colors: "#ffffff",
      },
      horizontalAlign: "center",
    },
    stroke: {
      show: false,
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          xaxis: {
            labels: {
              style: {
                colors: "#FFFFFF",
              },
            },
          },
          yaxis: {
            labels: {
              style: {
                colors: "#FFFFFF",
              },
            },
          },
          dataLabels: {
            formatter: function (val) {
              // Remove the percentage symbol from the labels
              return val.toFixed(0);
            },
          },
          tooltip: {
            enabled: true,
            theme: "light",
            y: {
              formatter: function (value) {
                return value + " Nos";
              },
            },
            fillSeriesColor: false, // You can use 'light' or 'dark' theme
          },
          legend: {
            position: "bottom",
            labels: {
              colors: "#ffffff",
            },
          },
        },
      },
    ],
  };

  const monitoring_options = {
    grid: {
      show: false,
    },
    chart: {
      type: "bar",
    },
    noData: {
      text: "No Data",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: "#fff",
        fontSize: "20px",
        fontFamily: "Poppins",
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: "#FFFFFF", // Change the Y-axis text color to '#FFFFFF'
        },
      },
    },
    colors: ["#40A2B8"],
    plotOptions: {
      strokeWidth: 0,
      bar: {
        borderRadius: 4,
        horizontal: true,
        columnWidth: "50px",
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: monitoring_labels,
      labels: {
        style: {
          colors: "#FFFFFF", // Change the X-axis text color to '#FFFFFF'
        },
      },
    },
  };

  const drSpread_options = {
    grid: {
      show: false,
    },
    chart: {
      type: "bar",
    },
    noData: {
      text: "No Data",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: "#fff",
        fontSize: "20px",
        fontFamily: "Poppins",
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: "#FFFFFF", // Change the Y-axis text color to '#FFFFFF'
        },
      },
    },
    colors: ["#40A2B8"],
    plotOptions: {
      strokeWidth: 0,
      bar: {
        borderRadius: 4,
        horizontal: true,
        columnWidth: "50px",
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: dr_labels,
      labels: {
        style: {
          colors: "#FFFFFF", // Change the X-axis text color to '#FFFFFF'
        },
      },
    },
  };

  const dbSpread_options = {
    grid: {
      show: false,
    },
    chart: {
      type: "bar",
    },
    colors: ["#FF6A2F", "#A7EE7C"],
    noData: {
      text: "No Data",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: "#fff",
        fontSize: "20px",
        fontFamily: "Poppins",
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: "#FFFFFF", // Change the Y-axis text color to '#FFFFFF'
        },
      },
    },
    colors: ["#40A2B8"],
    plotOptions: {
      strokeWidth: 0,
      bar: {
        borderRadius: 4,
        horizontal: true,
        columnWidth: "50px",
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: db_labels,
      labels: {
        style: {
          colors: "#FFFFFF", // Change the X-axis text color to '#FFFFFF'
        },
      },
    },
  };

  const treeseries = [
    {
      data: [
        {
          x: "New Delhi",

          y: 218,
        },

        {
          x: "Kolkata",

          y: 149,
        },
      ],
    },
  ];

  const treeoptions = {
    legend: {
      show: false,
    },
    noData: {
      text: "No Data",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: "#fff",
        fontSize: "20px",
        fontFamily: "Poppins",
      },
    },
    chart: {
      height: 350,

      type: "treemap",
    },

    plotOptions: {
      treemap: {
        distributed: true,

        enableShades: true,
      },
    },

    colors: ["#FF6A2F"],
  };
  const config_options = {
    chart: {
      type: "bar",
      height: 350,
      stacked: true, // Enable stacking for the bars
    },
    plotOptions: {
      bar: {
        horizontal: true, // Make it horizontal
        // Adjust the bar height
      },
    },
    stroke: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: [
        "configuration",
        "uid_troops",
        "production",
        "test_local",
        "test_run",
      ],
      labels: {
        style: {
          colors: "#FFFFFF",
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: "#FFFFFF",
        },
      },
    },
    legend: {
      labels: {
        colors: ["#FFF"],
      },
    },
    noData: {
      text: "No Data",

      align: "center",

      verticalAlign: "middle",

      offsetX: 0,

      offsetY: 0,

      style: {
        color: "#fff",

        fontSize: "20px",

        fontFamily: "Poppins",
      },
    },
  };
  const config_series = [
    {
      name: "CPU",
      data: [44, 55, 23, 14, 19],
    },
    {
      name: "Memory ",
      data: [13, 22, 20, 15, 30],
    },
  ];
  return (
    <>
      <Row gutter={[16, 16]}>
        <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Card title="VM" className="operate-details-cards">
                {/* {vmDetails.vm_count} */}
                <div className="d-flex justify-content-center">
                  {isShowSpinner ? (
                    <Spin />
                  ) : (
                    <span className="count">
                      {vmDetails.vm_count ? <>{numberFormat(vmDetails.vm_count)}</> : 0}
                    </span>
                  )}
                </div>
              </Card>
            </Col>

            <Col span={12}>
              <Card title="Core" className="operate-details-cards">
                {/* {parseInt(vmDetails.total_cpu, 10)} */}
                <div className="d-flex justify-content-center">
                  {isShowSpinner ? (
                    <Spin />
                  ) : (
                    <span className="count">
                      {vmDetails.total_cpu ? (
                        <>{numberFormat(vmDetails.total_cpu)}</>
                      ) : (
                        0
                      )}
                    </span>
                  )}
                </div>
              </Card>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Card title="Persistent Disk" className="operate-details-cards">
                {/* {vmDetails.vm_count} */}
                <div className="d-flex justify-content-center">
                  {isShowSpinner ? (
                    <Spin />
                  ) : (
                    <span className="count">
                      {vmDetails.disk_count ? <>{numberFormat(vmDetails.disk_count)}</> : 0}
                    </span>
                  )}
                </div>
              </Card>
            </Col>

            <Col span={12}>
              <Card title="Storage (GB)" className="operate-details-cards">
                {/* {parseInt(vmDetails.total_cpu, 10)} */}
                <div className="d-flex justify-content-center">
                  {isShowSpinner ? (
                    <Spin />
                  ) : (
                    <span className="count">
                      {vmDetails.total_storage ? (
                        <>{numberFormat(vmDetails.total_storage)}</>
                      ) : (
                        0
                      )}
                    </span>
                  )}
                </div>
              </Card>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Card title="RAM (GB)" className="operate-details-cards">
                {/* {vmDetails.vm_count} */}
                <div className="d-flex justify-content-center">
                  {isShowSpinner ? (
                    <Spin />
                  ) : (
                    <span className="count">
                      {vmDetails.total_memory ? (
                        <>{numberFormat(vmDetails.total_memory)}</>
                      ) : (
                        0
                      )}
                    </span>
                  )}
                </div>
              </Card>
            </Col>

            <Col span={12}>
              <Card title="PaaS" className="operate-details-cards">
                {/* {parseInt(vmDetails.total_cpu, 10)} */}
                <div className="d-flex justify-content-center">
                  {isShowSpinner ? (
                    <Spin />
                  ) : (
                    <span className="count">
                      {vmDetails.paas ? <>{numberFormat(vmDetails.paas)}</> : 0}
                    </span>
                  )}
                </div>
              </Card>
            </Col>
          </Row>
        </Col>

        <Col xxl={9} xl={9} lg={9} md={9} sm={24} xs={24}>
          <Card title="Resource Count by OU %" className="operate-cards">
            {isShowSpinner ? (
              <div className=" d-flex justify-content-center align-items-center spinner-height">
                <Spin />
              </div>
            ) : (
              <ReactApexChart
                options={ou_options}
                series={numeric_ou_series}
                type="donut"
                height={288}
                className="donutchart"
              />
            )}
          </Card>
        </Col>
        <Col xxl={9} xl={9} lg={9} md={9} sm={24} xs={24}>
          <Card title="Service Spread %" className="operate-cards">
            {isShowSpinner ? (
              <div className=" d-flex justify-content-center align-items-center spinner-height">
                <Spin />
              </div>
            ) : (
              <ReactApexChart
                options={service_options}
                series={numeric_service_series}
                type="donut"
                height={288}
                className="donutchart"
              />
            )}
          </Card>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={16}>
          <Card className="operate-cards">
            <h5 className=" kubernetes-header p-0 pt-3">
              Kubernetes Landscape Spread
            </h5>
          </Card>
          <Row gutter={[16, 16]}>
            <Col span={6}>
              <Card title="Clusters" className="operate-details-cards">
                {/* {parseInt(vmDetails.total_cpu, 10)} */}
                <div className="d-flex justify-content-center">
                  {isShowSpinner ? (
                    <Spin />
                  ) : (
                    <span className="count">
                      {kubernetesValues.resource_count ? (
                        <>{numberFormat(kubernetesValues.resource_count)}</>
                      ) : (
                        0
                      )}
                    </span>
                  )}
                </div>
              </Card>
            </Col>
            <Col span={6}>
              <Card title="Node" className="operate-details-cards">
                {/* {parseInt(vmDetails.total_cpu, 10)} */}
                <div className="d-flex justify-content-center">
                  {isShowSpinner ? (
                    <Spin />
                  ) : (
                    <span className="count">
                      {kubernetesValues.nodes ? (
                        <>{numberFormat(kubernetesValues.nodes)}</>
                      ) : (
                        0
                      )}
                    </span>
                  )}
                </div>
              </Card>
            </Col>
            <Col span={6}>
              <Card title="Fargate" className="operate-details-cards">
                {/* {parseInt(vmDetails.total_cpu, 10)} */}
                <div className="d-flex justify-content-center">
                  {isShowSpinner ? (
                    <Spin />
                  ) : (
                    <span className="count">
                      {kubernetesValues.forgate ? (
                        <>{numberFormat(kubernetesValues.forgate)}</>
                      ) : (
                        0
                      )}
                    </span>
                  )}
                </div>
              </Card>
            </Col>
            <Col span={6}>
              <Card title="Namespaces" className="operate-details-cards">
                {/* {parseInt(vmDetails.total_cpu, 10)} */}
                <div className="d-flex justify-content-center">
                  {isShowSpinner ? (
                    <Spin />
                  ) : (
                    <span className="count">
                      {kubernetesValues.namespaces ? (
                        <>{numberFormat(kubernetesValues.namespaces)}</>
                      ) : (
                        0
                      )}
                    </span>
                  )}
                </div>
              </Card>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={6}>
              <Card title="Container" className="operate-details-cards ">
                {/* {parseInt(vmDetails.total_cpu, 10)} */}
                <div className="d-flex justify-content-center">
                  {isShowSpinner ? (
                    <Spin />
                  ) : (
                    <span className="count">
                      {kubernetesValues.containers ? (
                        <>{numberFormat(kubernetesValues.containers)}</>
                      ) : (
                        0
                      )}
                    </span>
                  )}
                </div>
              </Card>
            </Col>
            <Col span={6}>
              <Card title="Service" className="operate-details-cards">
                {/* {parseInt(vmDetails.total_cpu, 10)} */}
                <div className="d-flex justify-content-center">
                  {isShowSpinner ? (
                    <Spin />
                  ) : (
                    <span className="count">
                      {kubernetesValues.services ? (
                        <>{numberFormat(kubernetesValues.services)}</>
                      ) : (
                        0
                      )}
                    </span>
                  )}
                </div>
              </Card>
            </Col>
            <Col span={6}>
              <Card title="Deployments" className="operate-details-cards">
                {/* {parseInt(vmDetails.total_cpu, 10)} */}
                <div className="d-flex justify-content-center">
                  {isShowSpinner ? (
                    <Spin />
                  ) : (
                    <span className="count">
                      {kubernetesValues.deployments ? (
                        <>{numberFormat(kubernetesValues.deployments)}</>
                      ) : (
                        0
                      )}
                    </span>
                  )}
                </div>
              </Card>
            </Col>
            <Col span={6}>
              <Card title="Pod" className="operate-details-cards">
                {/* {parseInt(vmDetails.total_cpu, 10)} */}
                <div className="d-flex justify-content-center">
                  {isShowSpinner ? (
                    <Spin />
                  ) : (
                    <span className="count">
                      {kubernetesValues.pods ? <>{numberFormat(kubernetesValues.pods)}</> : 0}
                    </span>
                  )}
                </div>
              </Card>
            </Col>
          </Row>
        </Col>
        <Col span={8}>
          <Card className="operate-cards">
            <h5 className=" kubernetes-header p-0 pt-3">ECS</h5>
          </Card>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Card title="Clusters" className="operate-details-cards">
                {/* {parseInt(vmDetails.total_cpu, 10)} */}
                <div className="d-flex justify-content-center">
                  {isShowSpinner ? (
                    <Spin />
                  ) : (
                    <span className="count">
                      {ecs.resource_count ? <>{numberFormat(ecs.resource_count)}</> : 0}
                    </span>
                  )}
                </div>
              </Card>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Card title="Containers" className="operate-details-cards">
                {/* {parseInt(vmDetails.total_cpu, 10)} */}
                <div className="d-flex justify-content-center">
                  {isShowSpinner ? (
                    <Spin />
                  ) : (
                    <span className="count">
                      {ecs.container_count ? <>{numberFormat(ecs.container_count)}</> : 0}
                    </span>
                  )}
                </div>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
          <Card title="OS Spread %" className="operate-cards">
            {isShowSpinner ? (
              <div className=" d-flex justify-content-center align-items-center spinner-height">
                <Spin />
              </div>
            ) : (
              <ReactApexChart
                options={os_options}
                series={numeric_os_series}
                type="pie"
                height={347}
                className="donutchart"
              />
            )}
          </Card>
        </Col>

        <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
          <Card title="DB Spread" className="operate-cards">
            {isShowSpinner ? (
              <div className=" d-flex justify-content-center align-items-center spinner-height">
                <Spin />
              </div>
            ) : (
              <ReactApexChart
                options={dbSpread_options}
                series={[{ data: numeric_db_series }]}
                type="bar"
                height={300}
              />
            )}
          </Card>
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
          <Card title="Monitoring Implementation" className="operate-cards">
            {isShowSpinner ? (
              <div className=" d-flex justify-content-center align-items-center spinner-height">
                <Spin />
              </div>
            ) : (
              <ReactApexChart
                options={monitoring_options}
                series={[{ data: numeric_monitoring_series }]}
                type="bar"
                height={300}
              />
            )}
          </Card>
        </Col>

        <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
          <Card title="DR Implementation" className="operate-cards">
            {isShowSpinner ? (
              <div className=" d-flex justify-content-center align-items-center spinner-height">
                <Spin />
              </div>
            ) : (
              <ReactApexChart
                options={drSpread_options}
                series={[{ data: numeric_dr_series }]}
                type="bar"
                height={300}
              />
            )}
          </Card>
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
          <Card title="As-Is RAM Utilization" className="operate-cards">
            {isShowSpinner ? (
              <div className=" d-flex justify-content-center align-items-center spinner-height">
                <Spin />
              </div>
            ) : (
              <ReactApexChart
                options={treeoptions}
                series={ramUtil}
                type="treemap"
                height={400}
              />
            )}
          </Card>
        </Col>

        <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
          <Card title="As-Is Storage Utilization" className="operate-cards">
            {isShowSpinner ? (
              <div className=" d-flex justify-content-center align-items-center spinner-height">
                <Spin />
              </div>
            ) : (
              <ReactApexChart
                options={treeoptions}
                series={storageUtil}
                type="treemap"
                height={400}
              />
            )}
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default OperateAssetsPerspective;
