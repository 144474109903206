import {
  MDBInput,
  MDBCheckbox,
  MDBBtn,
  MDBIcon,
  MDBValidation,
  MDBValidationItem,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";
import { Button, message, Modal, Form, Input } from "antd";
import { useEffect, useState } from "react";
import client from "./apploClient";
import { LOGIN_USER } from "./Graphql";
import Alert from "@mui/material/Alert";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import LinearProgress from "@mui/material/LinearProgress";
import { useDispatch, useSelector } from "react-redux";
import {
  loginUser,
  getMemberRole,
  azureAdEmailCheck,
} from "./action/userAction";
import { capitalizeFirst } from "./custom_hook/CustomHook";
// import { getMemberRole } from "./action/costimizeV2Active";
import { Icon } from "@iconify/react";
import { Navigate } from "react-router-dom";
import {
  profileInfoMethod,
  setUserType,
  userInfoMethod,
  getCompanyName,
  getOfferingsList,
} from "./slice/userSlice";
import { FcGoogle } from "react-icons/fc";
import { ImLinkedin } from "react-icons/im";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useNavigate } from "react-router-dom";
//auth
import { signIn, getUserDetails } from "./amplify/auth-config";
import ForgotPassword from "./ForgotPassword";

function Login(props) {
  const { loading, error, userInfo, profileInfo } = useSelector(
    (state) => state.user
  );
  const dispatch = useDispatch();
  const navigateFn = useNavigate();
  const [formValue, setFormValue] = useState({
    username: "",
    password: "",
  });
  const [close, setCloseAsset] = useState(false);
  const [successInfo, setSuccessInfo] = useState(false);
  const [loader, setLoader] = useState(false);
  const [nav, setNav] = useState(false);
  const [errorcontent, setErrorContent] = useState("");
  const [forgotpasswordstatus, setForgotPasswordStatus] = useState(false);
  const [buttonloading, setButtonloading] = useState(false);
  const [azureerrorstatus, setAzureErrorStatus] = useState(false);

  const [open, setOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleCancel = () => {
    setOpen(false);
  };
  const onChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };
  const [isDevops, setIsDevops] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setConfirmPassword(!confirmPassword);
  };
  const [getPathName, setGetpathName] = useState("");

  useEffect(() => {
    const getUrl = localStorage.getItem("devops-review");
    if (getUrl) {
      setIsDevops(true);
      setGetpathName(getUrl);
      localStorage.removeItem("devops-review");
    }
  }, []);
  const onFinish = (email) => {
    setIsSubmitting(true);

    dispatch(azureAdEmailCheck(email))
      .unwrap()
      .then(({ data }) => {
        setIsSubmitting(false);
        const records = JSON.parse(data.azure_ad_validation);

        if (records === "company not exists") {
          setAzureErrorStatus(true);
        } else {
          const client_id = records.client_id;
          const tenantid = records.tenant_id;

          window.location.href =
            "https://launcher.myapps.microsoft.com/api/signin/" +
            client_id +
            "?tenantId=" +
            tenantid;
        }
      })

      .catch((err) => {
        console.log(err);
        setIsSubmitting(false);
        setAzureErrorStatus(true);
      });

    // window.location.href =
    //   "https://launcher.myapps.microsoft.com/api/signin/16c43b3a-75cc-43c4-bfbc-cb7f0b79e8ca?tenantId=1e0b7619-b37d-4b53-a46b-35a1180b4632";
  };

  // const submit = () => {
  //     if (username && password) {
  //         setLoader(true);
  //         console.log({ username, password })
  //         dispatch(loginUser({ username, password }))
  //         .unwrap()
  //             .then(({ data }) => {
  //                 // console.log(data);
  //             if (data.getUserConfig) {
  //                 console.log(data.getUserConfig);

  //                 if (data.getUserConfig.Role === 'User' || data.getUserConfig.Role === null || data.getUserConfig.Role === '') {
  //                     // console.log(data.getUserConfig);
  //                     if(data.getUserConfig.status === "active"){
  //                         dispatch(userInfoMethod(data.getUserConfig));
  //                         dispatch(getCompanyName(data.getUserConfig.company_name));
  //                         dispatch(getOfferingsList(data.getUserConfig.Offerings));
  //                         dispatch(setUserType({type: "User"}));
  //                         // setNav('/card');
  //                         navigateFn('/card');
  //                     }else{
  //                         setCloseAsset(true);
  //                         setLoader(false);
  //                         setSuccessInfo(false);
  //                         setErrorContent("Account inactive, contact admin.");
  //                     }

  //                 } else if (data.getUserConfig.Role === 'Admin') {
  //                     dispatch(userInfoMethod(data.getUserConfig));
  //                     dispatch(setUserType({type: "Admin"}));
  //                     // setNav('/signedup-customer');
  //                     navigateFn('/signedup-customer');
  //                 } else {
  //                     dispatch(userInfoMethod(data.getUserConfig));
  //                     dispatch(setUserType({type: "master"}));
  //                     // setNav('/admin-dashboard');
  //                     navigateFn('/admin-dashboard');
  //                 }
  //             } else {
  //                 setCloseAsset(true);
  //                 setLoader(false);
  //                 setSuccessInfo(false);
  //                 setErrorContent("Loggedin Failiure!");
  //             }
  //         })
  //         .catch(err => {
  //             console.log(err);
  //             setLoader(false);
  //             setSuccessInfo(false);
  //             setErrorContent("Loggedin Failiure!");
  //         });
  //     }
  // }

  const submit = () => {
    setButtonloading(true);
    setLoader(true);
    dispatch(signIn({ username, password }))
      .unwrap()
      .then((signindata) => {
        localStorage.setItem(
          "_token",
          signindata.signInUserSession.accessToken.jwtToken
        );
        localStorage.setItem(
          "CognitoIdentityServiceProvider.auth",
          signindata.attributes.sub
        );
        const records = {
          user_id: signindata.attributes.sub,
          _token: signindata.signInUserSession.accessToken.jwtToken,
        };
        dispatch(loginUser(records))
          .unwrap()
          .then(({ data }) => {
            localStorage.removeItem("_token");
            localStorage.removeItem("CognitoIdentityServiceProvider.auth");
            if (data.getUsers.status === "active") {
              dispatch(userInfoMethod(data.getUsers));
              localStorage.setItem(
                "_token",
                signindata.signInUserSession.accessToken.jwtToken
              );
              localStorage.setItem(
                "CognitoIdentityServiceProvider.auth",
                signindata.attributes.sub
              );
              if (isDevops) {
                navigateFn(getPathName);
              } else {
                navigateFn("/role");
              }
            } else {
              setCloseAsset(true);
              setButtonloading(false);
              setLoader(false);
              setSuccessInfo(false);
              setErrorContent("Account inactive, contact admin.");
              message.error("Account inactive, contact admin.");
            }
          })
          .catch((err) => {
            console.log(err);
            setButtonloading(false);
            setLoader(false);
            setSuccessInfo(false);
            setErrorContent("Loggedin Failiure!");
          });
      })
      .catch((err) => {
        // console.log(err);
        setButtonloading(false);
        setCloseAsset(true);
        setLoader(false);
        setSuccessInfo(false);
        setErrorContent(err);
      });
    // const jwt_token = 'test';
    // dispatch(getUserDetails({ jwt_token }))
    // .unwrap()
    // .then((data) => {
    //     console.log(data);
    // })
    // .catch(err => {
    //     console.log(err);

    // });
  };

  const { username, password } = formValue;

  return (
    <div className="plat-signgroup-right-body mt-1 p-3">
      <div className="d-flex justify-content-center">
        {/* <h4>Sign in to your account</h4> */}
        <h3>Sign in to your account</h3>
      </div>
      {forgotpasswordstatus ? (
        <ForgotPassword
          forgotpasswordstatus={forgotpasswordstatus}
          setForgotPasswordStatus={setForgotPasswordStatus}
        />
      ) : (
        <>
          <MDBValidation>
            {nav && <Navigate to={nav} replace={true} />}
            {/* <div className="text-center mb-3">
                        <MDBBtn className='btn btn-primary btn-floating mx-1' style={{ backgroundColor: '#1266f1' }} href='#'>
                            <FcGoogle size={32}  />
                        </MDBBtn>
                        <MDBBtn className='btn btn-primary btn-floating mx-1' style={{ backgroundColor: '#1266f1' }} href='#'>
                            <ImLinkedin size={33}  color="#1b5f9d" />
                        </MDBBtn>
                    </div>
                    <p className="text-center">or</p> */}
            <div className="loginForm p-4">
              {close ? (
                <Alert
                  onClose={() => setCloseAsset(false)}
                  severity={successInfo ? "success" : "error"}
                >
                  {successInfo
                    ? "You have loggedin successfully !"
                    : errorcontent}
                </Alert>
              ) : null}

              <div className="userName mb-3">
                <MDBValidationItem
                  className="mb-3 pb-1"
                  feedback="Please enter the email."
                  invalid
                >
                  <div className=" form-fields">
                    <label className="plat-form-label me-3 required">
                      {" "}
                      <b> Email ID </b>{" "}
                    </label>
                    <MDBInput
                      id="form1"
                      name="username"
                      type="text"
                      className="loginInputField mt-2"
                      onChange={onChange}
                      value={username}
                      required
                    />
                  </div>
                </MDBValidationItem>
              </div>
              <div className="password mb-2">
                <MDBValidationItem
                  className="mb-3 pb-1 position-relative"
                  feedback="Please enter the password."
                  invalid
                >
                  <div className="form-fields">
                    <label className="plat-form-label me-1 required">
                      {" "}
                      <b>Password</b>
                    </label>
                    <MDBInput
                      id="typePassword"
                      type={confirmPassword ? "text" : "password"}
                      name="password"
                      className="loginInputField mt-2"
                      onChange={onChange}
                      value={password}
                      required
                    />
                    {/* <span
                      className="eye-icon mt-2 position-absolute top-0 start-0"
                      onClick={togglePasswordVisibility}
                      style={{
                        cursor: "pointer",
                        zIndex: "1",
                        marginLeft: "321px",
                        marginTop: "12px",
                      }}
                    >
                      {confirmPassword ? (
                        <Icon
                          icon="fa:eye"
                          className="icon-eye-class"
                        
                        />
                      ) : (
                        <Icon
                          icon="fa:eye-slash"
                          className="icon-eye-class"
                        
                        />
                      )}
                    </span> */}
                  </div>
                </MDBValidationItem>
              </div>

              <div className="plat-loing-btn-body mt-3">
                <Button
                  type="primary"
                  htmlType="submit"
                  id="submit-signin"
                  className="me-1 plat-signin-button"
                  loading={buttonloading}
                  onClick={submit}
                >
                  Sign In
                </Button>
              </div>
              <div className="row mb-1 mt-3">
                {window.location.pathname !== "/admin" ? (
                  <div className="text-center loginText">
                    <p className="mb-3">
                      {" "}
                      Don't have an account?{" "}
                      <a
                        className="sign-page-link"
                        href="javascript:void(0)"
                        onClick={props.onSelectTab}
                      >
                        {" "}
                        Sign up{" "}
                      </a>{" "}
                    </p>
                    <p className="mb-3">
                      {/* <OverlayTrigger
                                    placement="bottom"
                                    overlay={<Tooltip id="button-tooltip-2">Forgot password not available now. Contact admin.</Tooltip>}
                                > */}

                      <a
                        className="sign-page-link plat_cursor_pointer"
                        href
                        onClick={() => setForgotPasswordStatus(true)}
                      >
                        {" "}
                        Forgot password?{" "}
                      </a>

                      {/* <a className='sign-page-link plat_cursor_pointer' href> Forgot password? </a> */}
                      {/* </OverlayTrigger> */}
                    </p>
                  </div>
                ) : null}
              </div>
              {
                <div className="d-flex justify-content-center p-3">
                  {/* <Button
              type="button"
              htmlType="submit"
              className="me-1 plat-login-button plat-login-azure-btn"
              onClick={() =>
                (window.location.href =
                  "https://launcher.myapps.microsoft.com/api/signin/16c43b3a-75cc-43c4-bfbc-cb7f0b79e8ca?tenantId=1e0b7619-b37d-4b53-a46b-35a1180b4632")
              }
            >
              Login with Azure AD
            </Button>  */}
                  <Button
                    type="button"
                    htmlType="submit"
                    className="me-1 plat-login-azure-btn"
                    onClick={() => setOpen(true)}
                  >
                    <Icon
                      icon="logos:microsoft-icon"
                      style={{ marginRight: "4px" }}
                    />{" "}
                    Microsoft
                  </Button>
                </div>
              }
            </div>
          </MDBValidation>
        </>
      )}
      {/* azuer ad  */}
      <Modal
        title="Login With Microsoft"
        open={open}
        // onOk={handleOk}
        // confirmLoading={confirmLoading}
        onCancel={handleCancel}
        // visible={open}
        footer={null}
        maskClosable={false}
        className="login-azure-ad-model"
      >
        {azureerrorstatus && (
          <Alert onClose={() => setAzureErrorStatus(false)} severity={"error"}>
            Sorry, your email address is not available for this service. Please
            make sure you're using a valid email address and try again.
          </Alert>
        )}

        <Form onFinish={onFinish} className="mt-5">
          <Form.Item
            name="email"
            label="Email"
            className="email-id-label"
            rules={[
              { required: true, message: "Please input your email!" },
              { type: "email", message: "Please enter a valid email address!" },
            ]}
          >
            <Input className="background-antd-color" />
          </Form.Item>
          <Form.Item className="d-flex justify-content-end">
            <Button
              type="primary"
              htmlType="submit"
              id="submit"
              className="yes-button "
              loading={isSubmitting}
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}

export default Login;
