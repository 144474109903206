import { memo, useState } from "react";
import {
  Col,
  Row,
  Card,
  Table,
  Badge,
  Typography,
  Breadcrumb,
  Space,
} from "antd";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  deferredsummary,
  getListSavingOppurtunitySummary,
  savingsummarytable,
} from "../../../action/costimizeV2Active";
import "./sav_op.css";
import PotentialTable from "./PotentialTable";
import TrackedTable from "./TrackedTable";
import RealisedTable from "./RealisedTable";
import { numberFormat } from "../../../custom_hook/CustomHook";
import { FaArrowRight } from "react-icons/fa6";
import { MdDoubleArrow } from "react-icons/md";
import SpotQuickGlance from "./SpotQuickGlance";

const SavingsOppurtunity = () => {
  const {
    selectedEnvironment,
    selectedAccount,
    selectedCloud,
    selectedApplication,
    selectedTopdate,
    selectedservices,
    selectedos,
    selectedinfra,
    selecteddb,
  } = useSelector((state) => state.constimize);
  const dispatch = useDispatch();
  // const companyName = localStorage.getItem(
  //   "CognitoIdentityServiceProvider.company"
  // );

  var companyName = "all_cloud";

  //state
  const [topPotential, setTopPotential] = useState({
    potential_savings: 0,
    potential_count: 0,
  });
  const [topRecords, setTopRecords] = useState({
    realised_savings: 0,
    tracked_savings: 0,
    deferred_savings: 0,
    realised_count: 0,
    tracked_count: 0,
    deferred_count: 0,
  });

  //status
  const [recordsupdate, setRecordsUpdate] = useState(false);
  const [expandedKey, setExpandedKey] = useState(null);

  const [potentialSummaryTable, setPotentialSummaryTable] = useState([]);
  const [deferredSummaryTable, setDeferredSummaryTable] = useState([]);
  const [realisedSummaryTable, setRealisedSummaryTable] = useState([]);
  const [trackedSummaryTable, setTrackedSummaryTable] = useState([]);
  const [rolestatus, setRoleStatus] = useState(true);
  const [showOuterTable, setShowOuterTable] = useState("New");
  const [showInnerTable, setShowInnerTable] = useState("");
  const [deferredSummary, setDeferredSummary] = useState([]);
  const [breadCrumb, setBreadCrumb] = useState([
    {
      title: "New",
    },
  ]);

  const { Text } = Typography;

  const columns = [
    {
      title: "View",
      key: "clouds0ww1",
      className: "text-start w-20px",
      width: "10%",
      render: (_, record) => (
        // {record.account_name}
        <Space size="middle">
          <MdDoubleArrow
            type="button"
            size={14}
            onClick={() => handleRowClick(record)}
          />
        </Space>
      ),
    },
    {
      title: "Recommendation Type",
      dataIndex: "recommendation_type",
      key: "recommendation_type",
      align: "center",
    },
    {
      title: "Resources",
      dataIndex: "resources",
      key: "resources",
      align: "center",
    },
    {
      title: `${
        showOuterTable == "New"
          ? "Potential"
          : showOuterTable == "Track"
          ? "Potential"
          : showOuterTable == "Defer"
          ? "Deferred"
          : showOuterTable == "Realise"
          ? "Realised"
          : ""
      } Savings`,
      dataIndex: "savings",
      key: "savings",
      align: "right",
      render: (record) => {
        return (
          <>
            <div>${numberFormat(record)}</div>
          </>
        );
      },
    },
  ];

  const onExpand = (_, { key }) =>
    expandedKey === key ? setExpandedKey(null) : setExpandedKey(key);

  useEffect(() => {
    //role
    const role = JSON.parse(
      localStorage.getItem("CognitoIdentityServiceProvider.role")
    );
    if (role) {
      if (role[0]["savings_model"] === "Read") {
        setRoleStatus(false);
      }
    }

    var apivalue = {
      environment: selectedEnvironment.toString(),
      account: selectedAccount.toString(),
      cloud: selectedCloud.toString(),
      application: selectedApplication.toString(),
      services: selectedservices.toString(),
      os: selectedos.toString(),
      infra: selectedinfra.toString(),
      db: selecteddb.toString(),
      date: selectedTopdate,
    };

    dispatch(getListSavingOppurtunitySummary({ companyName, apivalue }))
      .unwrap()
      .then(({ data }) => {
        let potentialCount = 0;
        let potentialSavings = 0;
        const result = [];
        data.saving_opportunity_summary.map((item, index) => {
          result.push({
            key: index + 1,
            savings: item.potential_savings,
            recommendation_type: item.recommendation_rule,
            resources: item.resource,
          });
          potentialCount += parseInt(item.resource);
          potentialSavings += parseFloat(item.potential_savings ?? 0);
        });
        setPotentialSummaryTable(result);
        setTopPotential({
          potential_savings: potentialSavings,
          potential_count: potentialCount,
        });
      })
      .catch((err) => {
        console.log(err);
      });

    dispatch(savingsummarytable({ companyName, apivalue }))
      .unwrap()
      .then(({ data }) => {
        let realisedCount = 0;
        let realisedSavings = 0;
        let deferredCount = 0;
        let deferredSavings = 0;
        let trackedCount = 0;
        let trackedSavings = 0;
        const defer = [];
        const track = [];
        const realise = [];
        data.defer_track_realise_summary.map((item) => {
          if (item.status === "Realised") {
            realise.push({
              key: realise.length + 1,
              savings: item.potential_savings,
              recommendation_type: item.recommendation_rule,
              resources: item.no_of_resources,
            });
            realisedCount += parseInt(item.no_of_resources);
            realisedSavings += parseFloat(item.potential_savings);
          } else if (item.status === "Tracking") {
            track.push({
              key: track.length + 1,
              savings: item.potential_savings,
              recommendation_type: item.recommendation_rule,
              resources: item.no_of_resources,
            });
            trackedCount += parseInt(item.no_of_resources);
            trackedSavings += parseFloat(item.potential_savings);
          } else if (item.status === "Deferred") {
            defer.push({
              key: defer.length + 1,
              savings: item.potential_savings,
              recommendation_type: item.recommendation_rule,
              resources: item.no_of_resources,
            });
            deferredCount += parseInt(item.no_of_resources);
            deferredSavings += parseFloat(item.potential_savings);
          }
        });
        setDeferredSummaryTable(defer);
        setTrackedSummaryTable(track);
        setRealisedSummaryTable(realise);
        setTopRecords({
          realised_savings: realisedSavings,
          tracked_savings: trackedSavings,
          deferred_savings: deferredSavings,
          realised_count: realisedCount,
          tracked_count: trackedCount,
          deferred_count: deferredCount,
        });
      })
      .catch((err) => {
        console.log(err);
      });

    dispatch(deferredsummary({ companyName, apivalue }))
      .unwrap()
      .then(({ data }) => {
        setDeferredSummary(data.summary_deferred);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [
    selectedAccount,
    selectedApplication,
    selectedCloud,
    selectedservices,
    selectedEnvironment,
    selectedTopdate,
    companyName,
    selecteddb,
    selectedinfra,
    selectedos,
    recordsupdate,
  ]);

  const handleGrowBreadCrumb = (value) => {
    setBreadCrumb((prev) => [
      ...prev,
      {
        title: value,
      },
    ]);
  };

  const handleBackTrack = (value) => {
    setBreadCrumb([{ title: showOuterTable }]);
    setShowInnerTable("");
  };

  const handleRowClick = (record) => {
    handleGrowBreadCrumb(record.recommendation_type);
    setShowInnerTable(record.recommendation_type);
  };

  const handleCardClick = (value) => {
    showOuterTable !== value ? setShowOuterTable(value) : <></>;
    setBreadCrumb([
      {
        title: value,
      },
    ]);
    setShowInnerTable("");
    setRecordsUpdate(!recordsupdate);
  };
  return (
    <>
      <Row gutter={[16, 16]}>
        <Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={12}>
          {/* <Badge className="end-defer-count" count={deferredSummary.length}> */}
          <Card
            className={`savop-card ${showOuterTable === "New" ? "active" : ""}`}
            onClick={() => {
              handleCardClick("New");
            }}
          >
            <Row>
              <Col xxl={18} xl={18} lg={18} md={18} sm={18} xs={18}>
                <Row className="savop-card-title">
                  <h1>New Opportunities</h1>
                </Row>
                <Row className="savop-card-header">
                  <h2>${numberFormat(topPotential.potential_savings)}</h2>
                </Row>
              </Col>
              <Col
                xxl={6}
                xl={6}
                lg={6}
                md={6}
                sm={6}
                xs={6}
                className="d-flex align-items-center justify-content-center"
              >
                <h3>{topPotential.potential_count}</h3>
              </Col>
            </Row>

            {/* <h3>Pending Actions : 90</h3> */}
          </Card>
          {/* </Badge> */}
        </Col>
        <Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={12}>
          <Card
            className={`savop-card ${
              showOuterTable === "Track" ? "active" : ""
            }`}
            onClick={() => {
              handleCardClick("Track");
            }}
          >
            <Row>
              <Col xxl={18} xl={18} lg={18} md={18} sm={18} xs={18}>
                <Row className="savop-card-title">
                  <h1>Tracked Opportunities</h1>
                </Row>
                <Row className="savop-card-header">
                  <h2>${numberFormat(topRecords.tracked_savings)}</h2>
                </Row>
              </Col>
              <Col
                xxl={6}
                xl={6}
                lg={6}
                md={6}
                sm={6}
                xs={6}
                className="d-flex align-items-center justify-content-center"
              >
                <h3>{topRecords.tracked_count}</h3>
              </Col>
            </Row>

            {/* <h3>Pending Actions : 90</h3> */}
          </Card>
        </Col>
        <Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={12}>
          <Card
            className={`savop-card ${
              showOuterTable === "Realise" ? "active" : ""
            }`}
            onClick={() => {
              handleCardClick("Realise");
            }}
          >
            <Row>
              <Col xxl={18} xl={18} lg={18} md={18} sm={18} xs={18}>
                <Row className="savop-card-title">
                  <h1>Realised Opportunities</h1>
                </Row>
                <Row className="savop-card-header">
                  <h2>${numberFormat(topRecords.realised_savings)}</h2>
                </Row>
              </Col>
              <Col
                xxl={6}
                xl={6}
                lg={6}
                md={6}
                sm={6}
                xs={6}
                className="d-flex align-items-center justify-content-center"
              >
                <h3>{topRecords.realised_count}</h3>
              </Col>
            </Row>

            {/* <h3>Pending Actions : 90</h3> */}
          </Card>
        </Col>
        <Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={12}>
          <Card
            className={`savop-card ${
              showOuterTable === "Defer" ? "active" : ""
            }`}
            onClick={() => {
              handleCardClick("Defer");
            }}
          >
            <Row>
              <Col xxl={18} xl={18} lg={18} md={18} sm={18} xs={18}>
                <Row className="savop-card-title">
                  <h1>Deferred Opportunities</h1>
                </Row>
                <Row className="savop-card-header">
                  <h2>${numberFormat(topRecords.deferred_savings)}</h2>
                </Row>
              </Col>
              <Col
                xxl={6}
                xl={6}
                lg={6}
                md={6}
                sm={6}
                xs={6}
                className="d-flex align-items-center justify-content-center"
              >
                <h3>{topRecords.deferred_count}</h3>
              </Col>
            </Row>

            {/* <h3>Pending Actions : 90</h3> */}
          </Card>
        </Col>
      </Row>
      <Breadcrumb
        separator=">"
        className="mt-4 savop-breadcrumb"
        items={breadCrumb}
        onClick={() => {
          handleBackTrack();
        }}
        // style={{ cursor: "pointer" }}
      />
      <Row gutter={[16, 16]} className="mt-4">
        {showInnerTable !== "" ? (
          <Col span={24}>
            {showOuterTable === "New" ? (
              <PotentialTable
                recordsupdate={recordsupdate}
                setRecordsUpdate={setRecordsUpdate}
                rolestatus={rolestatus}
                filter={showInnerTable}
              />
            ) : showOuterTable === "Track" ? (
              <TrackedTable
                recordsupdate={recordsupdate}
                setRecordsUpdate={setRecordsUpdate}
                rolestatus={rolestatus}
                filter={showInnerTable}
                isdefer={false}
              />
            ) : showOuterTable === "Realise" ? (
              <RealisedTable
                recordsupdate={recordsupdate}
                setRecordsUpdate={setRecordsUpdate}
                rolestatus={rolestatus}
                filter={showInnerTable}
              />
            ) : showOuterTable === "Defer" ? (
              <TrackedTable
                recordsupdate={recordsupdate}
                setRecordsUpdate={setRecordsUpdate}
                rolestatus={rolestatus}
                filter={showInnerTable}
                isdefer={true}
              />
            ) : (
              <></>
            )}
          </Col>
        ) : (
          <Table
            className="savop-table"
            columns={columns}
            // onRow={(record, rowIndex) => {
            //   return {
            //     onClick: () => {
            //       handleRowClick(record);
            //     },
            //   };
            // }}
            shouldCellUpdate={(record, prevRecord) =>
              !shallowEqual(record, prevRecord)
            }
            dataSource={
              showOuterTable === "New" ? (
                // deferredSummary.length > 0 ? (
                //   [
                //     {
                //       key: potentialSummaryTable.length + 1,
                //       recommendation_type: "Deferred",
                //       savings: deferredSummary[0].potential_savings,
                //       resources: deferredSummary[0].no_of_resources,
                //     },
                //     ...potentialSummaryTable,
                //   ]
                // ) : (
                potentialSummaryTable
              ) : // )
              showOuterTable === "Track" ? (
                trackedSummaryTable
              ) : showOuterTable === "Defer" ? (
                deferredSummaryTable
              ) : showOuterTable === "Realise" ? (
                realisedSummaryTable
              ) : (
                <></>
              )
            }
            pagination={false}
            summary={(pageData) => {
              const potential = pageData.reduce(
                (a, v) => (a = +a + +v?.savings),
                0
              );
              return (
                <>
                  <Table.Summary.Row className="savop-table-footer">
                    {/* <Table.Summary.Cell index={0}></Table.Summary.Cell>
                    <Table.Summary.Cell index={1}></Table.Summary.Cell> */}
                    <Table.Summary.Cell className="d-flex align-items-center justify-content-end">
                      Total:
                      <Text
                        className="white-color"
                        style={{ paddingLeft: "1rem" }}
                      >
                        ${numberFormat(potential)}
                      </Text>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </>
              );
            }}
          />
        )}
      </Row>
    </>
  );
};
export default memo(SavingsOppurtunity);
