import React, { useState, useEffect } from "react";
import A2CSidebar from "../a2c_rover/A2CSidebar";
import { Progress, Row, Col, Tabs, Button, Spin, Modal, Card } from "antd";
import "./../a2c_rover/a2c_rover.css";
import Security from "./Security";
import CostOptmization from "./CostOptmization";
import PerrformanceEfficiency from "./PerrformanceEfficiency";
import OperationalExcellence from "./OperationalExcellence";
import Sustainabality from "./Sustainabality";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import RealiablityWAR from "./RealiablityWAR";
import { Icon } from "@iconify/react";
import { listriskcounts, downloadreport, listriskquestions } from "../action/graphql/a2crover/A2CReducer";


function Realiability() {

  const { selectedWorkload,
    selectWorkloadid } = useSelector((state) => state.a2cRover)



  const navigate = useNavigate();
  const [lastUpdatedTime, setLastUpdatedTime] = useState("");
  const [riskcounts, setRiskCounts] = useState({});
  const [unanswereddata, setUnansweredValue] = useState(0);
  const [highvaluedata, sethighvalue] = useState(0);
  const [mediumvaluedata, setMedium] = useState(0);
  const [NotApplicable, setNotapplicable] = useState(0);
  const [Nonevalue, setNonevalue] = useState(0);
  const [percentagedata, setpercentagedata] = useState(0);
  const [loader, setLoader] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalHighOpen, setIsModalHighOpen] = useState(false);
  const [isModalMediumOpen, setIsModalMediumOpen] = useState(false);
  const [isModalNoneOpen, setIsModalNoneOpen] = useState(false);
  const [isModalNotApplicableOpen, setIsModalNotApplicableOpen] = useState(false);
  const [highriskquestion, setHighRiskQuestion] = useState([])
  const [unansweredquestion, setUnansweredQuestion] = useState([])
  const [noneriskquestion, setNoneRiskQuestion] = useState([])
  const [notapplicablequestion, setNotApplicableQuestion] = useState([])
  const [Mediumriskquestion, setMediumRiskQuestion] = useState([])

  const showModal = () => {
    setIsModalOpen(true);

  };
  const showModalHigh = () => {
    setIsModalHighOpen(true);
  };
  const showModalMedium = () => {
    setIsModalMediumOpen(true);
  };

  const showModalNone = () => {
    setIsModalNoneOpen(true);
  };

  const showModalNotApplicable = () => {
    setIsModalNotApplicableOpen(true);
  };


  const handleOk = () => {
    setIsModalOpen(false);
    setIsModalHighOpen(false);
    setIsModalMediumOpen(false);
    setIsModalNoneOpen(false);
    setIsModalNotApplicableOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setIsModalHighOpen(false);
    setIsModalMediumOpen(false);
    setIsModalNoneOpen(false);
    setIsModalNotApplicableOpen(false);
  };



  const dispatch = useDispatch();

  const handledashboard = () => {
    navigate('/wardashboard');
  };

  useEffect(() => {
    updateLastUpdatedTime();

  }, []);


  console.log(selectWorkloadid)

  useEffect(() => {
    const riskinput = {
      event_type: "list_riskcount",
      workload_id: selectWorkloadid
    };

    dispatch(listriskcounts({ riskinput }))
      .unwrap()
      .then(({ data }) => {

        console.log(data, "riskvalues")

        const riskCountsArray = JSON.parse(data.list_riskcounts);

        if (Array.isArray(riskCountsArray)) {

          riskCountsArray.forEach((riskCount) => {
            setUnansweredValue(riskCount.unanswered);
            sethighvalue(riskCount.high);
            setMedium(riskCount.medium);
            setNonevalue(riskCount.none);
            setNotapplicable(riskCount.notapplicable);
            setpercentagedata(riskCount.percentage);
          });
        } else {
          console.log("invalid riskcounts");
        }

        setLoader(false);

      })
      .catch((err) => {
        console.log(err);
      });
  }, [selectWorkloadid]);

  const updateLastUpdatedTime = () => {
    const currentDate = new Date();
    const formattedDate = `${currentDate.toLocaleDateString()} ${currentDate.toLocaleTimeString()}`;
    setLastUpdatedTime(formattedDate);
  };


  const handleExportReport = async () => {
    const reportlist = {
      event_type: "download-report",
      lens_alias: "wellarchitected",
      workload_id: selectWorkloadid
    };

    dispatch(downloadreport({ reportlist }))
      .unwrap()
      .then(({ data }) => {
        console.log(data, "reportlist");

        if (data && data.download_report) {
          const apidata = data.download_report.replace(/^"|"$/g, '');
          console.log(apidata);

          const link = document.createElement('a');
          link.href = apidata;
          link.download = 'wellarchitected.pdf';
          link.click();
        } else {
          console.error('Download report URL is undefined.');
        }
      })
      .catch((err) => {
        console.log(err);
      });

  }
  useEffect(() => {
    const riskquestions = {
      event_type: "risk-question-list",
      lens_alias: "wellarchitected",
      workload_id: selectWorkloadid
    };

    dispatch(listriskquestions({ riskquestions }))
      .unwrap()
      .then(({ data }) => {
        console.log(data, "listriskquestions");
        const parsedData = data.risk_question_count.map((item) => JSON.parse(item));

        const mappedData = parsedData.map(({ QuestionTitle, Risk }) => ({
          questionTitle: QuestionTitle,
          risk: Risk,
        }));

        console.log(mappedData)

        const highrisk = mappedData.filter(item => item.risk === "HIGH").map(item => item.questionTitle);
        const mediumrisk = mappedData.filter(item => item.risk === "MEDIUM").map(item => item.questionTitle);
        const Unansweredrisk = mappedData.filter(item => item.risk === "UNANSWERED").map(item => item.questionTitle);
        const nonerisk = mappedData.filter(item => item.risk === "NONE").map(item => item.questionTitle);
        const notapplicablerisk = mappedData.filter(item => item.risk === "NOT_APPLICABLE").map(item => item.questionTitle);


        setHighRiskQuestion(highrisk);
        setMediumRiskQuestion(mediumrisk);
        setUnansweredQuestion(Unansweredrisk);
        setNoneRiskQuestion(nonerisk);
        setNotApplicableQuestion(notapplicablerisk);

        console.log(Unansweredrisk)
        console.log(highrisk)


      })
      .catch((err) => {
        console.log(err);
      });
  }, [])


  const items = [
    {
      key: "1",
      label: "Reliability",
      // children: <RealiablityWAR riskcounts={riskcounts} setRiskCounts={setRiskCounts} />,
      children: <CostOptmization />,
    },
    {
      key: "2",
      label: "Security",
      // children: <Security riskcounts={riskcounts} setRiskCounts={setRiskCounts} />,
      children: <OperationalExcellence />,
    },
    {
      key: "3",
      label: "Cost Optimization",
      children: <CostOptmization />,
    },
    {
      key: "4",
      label: "Performance Efficiency",
      // children: <PerrformanceEfficiency />,
      children: <Sustainabality />,
    },
    {
      key: "5",
      label: "Operational Excellence",
      children: <OperationalExcellence />,
    },
    {
      key: "6",
      label: "Sustainability",
      children: <Sustainabality />,
    },
  ];




  return (
    <>
      <div style={{ display: "flex", height: "100%", minHeight: "400px" }}>
        <A2CSidebar />
        <main className="position-main-div" style={{ padding: 10, flex: 1 }}>
          {" "}
          <div className="cams-content">
            <div className="container-fluid">
              <div className="header-filter d-flex justify-content-between">
                <div className="header-title-a2c-main">Well Architected Review</div>

                <span className="d-flex align-items-end">
                  {/* <A2cFilter /> */}
                </span>
              </div>
              <div className="">
                <div className="mb-3">
                  <Row justify={"space-between"}>
                    <Col>
                      {/* {" "}
                      <p className="text-white">

                      </p> */}
                      <div className="arrow-back color-orange">
                        <Icon
                          icon="bi:arrow-left"
                          style={{ fontSize: "25px", cursor: "pointer" }}
                          onClick={() => navigate("/wardashboard")}
                        />
                      </div>
                    </Col>

                    <Col>


                      <Button
                        className="buttonclass"
                        icon={<Icon icon="uil:upload" />}
                        onClick={handleExportReport}
                      >
                        Export Report
                      </Button>




                      &nbsp;&nbsp;
                      <Button
                        className="savebutton "
                        icon={<Icon icon="uil:exit" />}
                        onClick={handledashboard}
                      >
                        Exit Assessment
                      </Button>
                    </Col>
                  </Row>
                </div>
                <Row gutter={[16, 16]} justify={"space-between"}>
                  {/* <br /> */}

                  <Col>
                    <h4 className="text-white">
                      {selectedWorkload}
                    </h4>

                  </Col>
                  <Col>
                    <p className="text-sm white-color">Last Updated on {lastUpdatedTime}</p>
                  </Col>
                </Row>
                <div className="border-line mt-3">
                  <Row
                    className="pt-2"
                    // justify={"center"}
                    align={"top"}
                    gutter={[16, 16]}
                  >
                    <Col lg={1}
                      md={1}
                      sm={1}></Col>
                    <Col
                      lg={3}
                      md={3}
                      sm={3}
                      className="d-flex justify-content-start"
                    >
                      <div className="">
                        <div className="color-orange">
                          WAR Score &nbsp;
                          <Icon icon={"material-symbols:info-outline"} />
                        </div>
                        <div className="text-center text-lg text-normal text-white mt-2">
                          <Progress style={{ color: "white" }} size="small" strokeColor={"#ff6a2f"} circleTextColor={"#FFF"}
                            className="war-progress titletab" type="circle" percent={40} />
                        </div>
                      </div>
                    </Col>

                    <Col
                      lg={3}
                      md={3}
                      sm={3}
                      className="d-flex justify-content-start"
                    >
                      <div className="">
                        <div className="color-orange">
                          Unanswered &nbsp;
                          <Icon icon={"material-symbols:info-outline"} />
                        </div>

                        <div className="text-center text-lg text-normal text-white mt-4">
                          {unanswereddata}
                          <Icon
                            icon={"material-symbols:info-outline"}
                            style={{ color: "yellow" }}
                            onClick={showModal}

                          />

                          <Modal
                            title={<span className="color-orange">UNANSWERED RISK QUESTIONS</span>}

                            footer={false}
                            open={isModalOpen}
                            onOk={handleOk}
                            onCancel={handleCancel}
                            className="scroll-container"
                          >
                            <div>
                              <hr className="titletab"></hr>
                              {unansweredquestion.map((questionTitle, index) => {
                                const match = questionTitle.match(/^([A-Z]+) (\d+)\.(.*)$/);
                                const category = match ? match[1] : '';
                                const number = match ? match[2] : '';
                                const restOfQuestion = match ? match[3] : questionTitle;

                                return (
                                  <div key={index}>
                                    <p className="titletab">
                                      <span className="color-orange">{category}</span>
                                      <span className="color-orange ms-1">{number}</span>. {restOfQuestion}
                                    </p>
                                    {index < unansweredquestion.length - 1 && <hr />}
                                  </div>
                                );
                              })}
                            </div>


                          </Modal>

                        </div>

                      </div>
                    </Col>
                    <Col
                      lg={3}
                      md={3}
                      sm={3}
                      className="d-flex justify-content-center align-items-center"
                    >
                      <div className="">
                        <div className="color-orange">
                          High &nbsp;
                          <Icon icon={"material-symbols:info-outline"} />
                        </div>
                        <div className="text-center text-lg text-normal text-white mt-4">
                          {highvaluedata}
                          &nbsp;
                          <Icon
                            icon={"ph:warning-bold"}
                            style={{ color: "red" }}
                            onClick={showModalHigh}
                          />
                          <Modal
                            title={<span className="color-orange">HIGH RISK QUESTIONS</span>}

                            footer={false}
                            open={isModalHighOpen}
                            onOk={handleOk}
                            onCancel={handleCancel}
                            
                          >
                            <div>
                              <hr className="titletab"></hr>
                              {highriskquestion.map((questionTitle, index) => {
                                const match = questionTitle.match(/^([A-Z]+) (\d+)\.(.*)$/);
                                const category = match ? match[1] : '';
                                const number = match ? match[2] : '';
                                const restOfQuestion = match ? match[3] : questionTitle;

                                return (
                                  <div key={index}>
                                    <p className="titletab">
                                      <span className="color-orange">{category}</span>
                                      <span className="color-orange ms-1">{number}</span>. {restOfQuestion}
                                    </p>
                                    {index < highriskquestion.length - 1 && <hr />}
                                  </div>
                                );
                              })
                              
                              }
                            </div>


                          </Modal>
                        </div>
                      </div>
                    </Col>
                    <Col
                      lg={3}
                      md={3}
                      sm={3}
                      className="d-flex justify-content-center align-items-center"
                    >
                      <div className="">
                        <div className="color-orange">
                          Medium &nbsp;
                          <Icon icon={"material-symbols:info-outline"} />
                        </div>
                        <div className="text-center text-lg text-normal text-white mt-4">
                          {mediumvaluedata}
                          &nbsp;
                          <Icon
                            icon={"ph:warning-bold"}
                            style={{ color: "orange" }}
                            onClick={showModalMedium}
                          />
                      <Modal
  title={<span className="color-orange">MEDIUM RISK QUESTIONS</span>}
  footer={false}
  open={isModalMediumOpen}
  onOk={handleOk}
  onCancel={handleCancel}
  className="scroll-container"
>
  <div>
    <hr className="titletab"></hr>
    {Mediumriskquestion.length > 0 ? (
      Mediumriskquestion.map((questionTitle, index) => {
        const match = questionTitle.match(/^([A-Z]+) (\d+)\.(.*)$/);
        const category = match ? match[1] : '';
        const number = match ? match[2] : '';
        const restOfQuestion = match ? match[3] : questionTitle;

        return (
          <div key={index}>
            <p className="titletab">
              <span className="color-orange">{category}</span>
              <span className="color-orange ms-1">{number}</span>. {restOfQuestion}
            </p>
            {index < Mediumriskquestion.length - 1 && <hr />}
          </div>
        );
      })
    ) : (
      <p className="titletab">No Medium Risk Question Available</p>
    )}
  </div>
</Modal>

                        </div>
                      </div>
                    </Col>
                    <Col
                      lg={3}
                      md={3}
                      sm={3}
                      className="d-flex justify-content-center align-items-center"
                    >
                      <div className="">
                        <div className="color-orange">
                          None &nbsp;
                          <Icon icon={"material-symbols:info-outline"} />
                        </div>
                        <div className="text-center text-lg text-normal text-white mt-4">
                          {Nonevalue}
                          &nbsp;
                          <Icon
                            icon={"bi:x-circle"}
                            style={{ color: "pink" }}
                            onClick={showModalNone}
                          />
                          <Modal
                            title={<span className="color-orange">None RISK QUESTIONS</span>}

                            footer={false}
                            open={isModalNoneOpen}
                            onOk={handleOk}
                            onCancel={handleCancel}
                            className="scroll-container"
                          >
                            <div>
                              <hr className="titletab"></hr>
                            
                                {noneriskquestion.map((questionTitle, index) => {
                                  const match = questionTitle.match(/^([A-Z]+) (\d+)\.(.*)$/);
                                  const category = match ? match[1] : '';
                                  const number = match ? match[2] : '';
                                  const restOfQuestion = match ? match[3] : questionTitle;

                                  return (
                                    <div key={index}>
                                      <p className="titletab">
                                        <span className="color-orange">{category}</span>
                                        <span className="color-orange ms-1">{number}</span>. {restOfQuestion}
                                      </p>
                                      {index < noneriskquestion.length - 1 && <hr />}
                                    </div>
                                  );
                                })}
                            </div>


                          </Modal>
                        </div>
                      </div>
                    </Col>
                    <Col
                      lg={3}
                      md={3}
                      sm={3}
                      className="d-flex justify-content-center align-items-center"
                    >
                      <div className="">
                        <div className="color-orange">
                          NotApplicable
                          <Icon icon={"material-symbols:info-outline"} />
                        </div>
                        <div className="text-center text-lg text-normal text-white mt-4">
                          {NotApplicable}
                          &nbsp;
                          <Icon
                            icon={"mi:ban"}
                            style={{ color: "white" }}
                            onClick={showModalNotApplicable}
                          />
                          <Modal
                            title={<span className="color-orange">NOTAPPLICABLE RISK QUESTIONS</span>}

                            footer={false}
                            open={isModalNotApplicableOpen}
                            onOk={handleOk}
                            onCancel={handleCancel}
                            className="scroll-container"
                          >
                            <div>
                              <hr className="titletab"></hr>

                              {notapplicablequestion.map((questionTitle, index) => {
                                const match = questionTitle.match(/^([A-Z]+) (\d+)\.(.*)$/);
                                const category = match ? match[1] : '';
                                const number = match ? match[2] : '';
                                const restOfQuestion = match ? match[3] : questionTitle;

                                return (
                                  <div key={index}>
                                    <p className="titletab">
                                      <span className="color-orange">{category}</span>
                                      <span className="color-orange ms-1">{number}</span>. {restOfQuestion}
                                    </p>
                                    {index < notapplicablequestion.length - 1 && <hr />}
                                  </div>
                                );
                              })}
                            </div>


                          </Modal>

                        </div>
                      </div>
                    </Col>
                    <Col
                      lg={5}
                      md={5}
                      sm={5}
                      className="d-flex justify-content-center align-items-center"
                    >
                      <div className="">
                        <div className="color-orange">
                          Assessment Progress
                          &nbsp;<Icon icon={"material-symbols:info-outline"} />
                        </div>
                        <div className="text-center text-lg text-normal text-white">
                          <div
                            className="progress-score mt-4"
                            style={{
                              width: 200,
                            }}
                          >
                            <Progress
                              percent={percentagedata}
                              size="small"
                              style={{ color: "white" }}
                              className="war-progress titletab"
                            />
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                <Row>
                  <Tabs
                    className="cams-tabs"
                    defaultActiveKey="1"
                    items={items}
                  />
                </Row>
              </div>

              <br />
              {/* Graph */}
            </div>
          </div>
        </main>
      </div>
    </>
  );
}

export default Realiability;
