import { Row, Col } from "react-bootstrap";
import { Box } from "@mui/material";
import Tapmenu from "./Tapmenu";
import { MDBIcon } from "mdb-react-ui-kit";
import { Icon } from "@iconify/react";
import Typography from "@material-ui/core/Typography";
import Table from "react-bootstrap/Table";
import QuickView from "./QuickView";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllSummaryDashboard,
  getUntaggedResources,
  getCreditChart,
  getProjectedSpend,
  getStartStopScheduler,
  getUderUtilized,
} from "../action/costimizedashboardAction";
import { listAnnualBudget } from "../action/costimizev2DashboardAction";
import {
  spendTrandChartData,
  spendTrandChartMonth,
  setTabActive,
} from "../slice/costimizeSlice";

import React, { useCallback, Fragment, useEffect, useState } from "react";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import QuickGlanceSavings from "./QuickGlanceSavings";
import ZoomIn from "../properties/Zoomin/ZoomIn";
import Trendchart from "./PopupChart/Trendchart";
// import LineChart from './trendChartv2';

// import { HiOutlineArrowsExpand } from "react-icons/hi";
import { CgDetailsMore } from "react-icons/cg";

import Dropdown from "react-bootstrap/Dropdown";
import TaggingDetails from "./PopupDetails/TaggingDetails";
// import CreditChart from './CreditChart';
import Creditchart from "./PopupChart/Creditchart";
import Spinner from "react-bootstrap/Spinner";

//dummy records
// import { dummyTagginCompliance, dummySummaryDashboard, dummyCredits } from '../dummy_records/DummyRecords';
// import PlatToolTip from '../properties/Tooltip/PlatToolTip';
import { Tooltip } from "antd";
import UnderResourcesInfo from "./PopupDetails/UnderResourcesInfo";
import BudgetComplianceInfo from "./PopupDetails/BudgetComplianceInfo";
// import DashboardSidebar from './DashboardSidebar';
import CustimizeFooter from "./CustimizeFooter";
import { listApplicationBudget } from "../action/costimizev2DashboardAction";
import SpendChart from "./QuickGlanceDashboard/SpendChart";
import AssetBarChart from "./QuickGlanceDashboard/AssetBarChart";
import { numberFormat } from "../custom_hook/CustomHook";

const QuickGlance = () => {
  // const { companyName } = useSelector((state) => state.user);
  // var companyName = localStorage.getItem("CognitoIdentityServiceProvider.company");
  const [currrentspend, setCurrrentSpend] = useState(0);
  const [projectedspend, setProjectedSpend] = useState(0);
  const [currrentspenddiff, setCurrrentSpendDiff] = useState({
    diff: 0,
    status_value: "+",
  });
  const [projectedspenddiff, setProjectedSpendDiff] = useState({
    diff: 0,
    status_value: "+",
  });
  const [untaggedresources, setUntaggedResources] = useState({
    partially_tagged: 0,
    untagged: 0,
    percentage: 0,
    list: [],
    update: false,
  });
  const [startstopscheduler, setStartStopScheduler] = useState({
    completed: 0,
    pending: 0,
    percentage: 0,
    status: false,
  });

  const [budgetdetails, setBudgetDetails] = useState({
    overall_budget: 0,
    last_month_budget: 0,
    month_budget: 0,
    status: "",
    month: "",
  });

  const [underresourcesper, setUnderResources] = useState(0);
  const [underresourcesvalue, setUnderResourcesValue] = useState(0);

  const [datestring, setDataString] = useState("");
  const [zoominstatus, setZoomInStatus] = useState(false);
  const [chartzoominstatus, setChartZoomInStatus] = useState(false);
  const [creditchartzoominstatus, setCreditChartZoomInStatus] = useState(false);
  const [taggingstatus, setTaggingStatus] = useState(false);
  const [underresources, setUnderResourcesStatus] = useState(false);
  const [budgetcompliance, setBudgetComplianceStatus] = useState(false);
  const [budgetallocation, setBudgetAllocation] = useState({
    budgetpercentage: 0,
    total_application: 0,
    buget_allocation: 0,
    status: false,
  });

  const [zoominpopupcontent, setZoomInPopupContent] = useState("");

  const [creditchart, setCreditChart] = useState({
    chartdate: [],
    chartvalue: [],
    monthvalue: 0,
  });

  const [trandchartdata, setTrandChartData] = useState([]);
  const [trandchartmonth, setTrandChartMonth] = useState([]);
  const [assetsummaryvm, setAssetSummaryVm] = useState({
    count: 0,
    core: 0,
    ram: 0,
    storage: 0,
  });
  const [assetsummarydb, setAssetSummaryDb] = useState({
    count: 0,
    core: 0,
    ram: 0,
    storage: 0,
  });
  const [assetsummaryothers, setAssetSummaryOthers] = useState({
    count: 0,
    core: 0,
    ram: 0,
    storage: 0,
  });
  const [preloader, setPreloader] = useState(false);

  const {
    applicationList,
    selectedTopdate,
    selectedEnvironment,
    selectedAccount,
    selectedCloud,
    selectedApplication,
    selectedservices,
    selectedos,
    selectedinfra,
    selecteddb,
    dummydatastatus,
  } = useSelector((state) => state.constimize);
  const dispatch = useDispatch();
  const monthNamesShort = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const current_month_text = `${monthNames[new Date().getMonth()]}`;
  const current = new Date(selectedTopdate);
  const [quickdate, setQuickDate] = useState(`${current.getDate()}`);
  const quickmonth = `${monthNamesShort[current.getMonth()]}`;
  const [shortmonth, setShortMonth] = useState();
  const quickfullmonth = `${monthNames[current.getMonth()]}`;
  const quickyear = `${current.getFullYear()}`;
  const currentdate = new Date();
  const currentdatemonth = `${monthNamesShort[currentdate.getMonth()]}`;
  const currentdateday = `${currentdate.getDate()}`;

  // console.log("in");

  // const date = dateOrdinal(14)
  // console.log(dateOrdinal(14));
  function dateOrdinal(dom) {
    if (dom == 31 || dom == 21 || dom == 1) return dom + "st";
    else if (dom == 22 || dom == 2) return dom + "nd";
    else if (dom == 23 || dom == 3) return dom + "rd";
    else return dom + "th";
  }

  useEffect(() => {
    //summary dashboard
    setPreloader(true);

    var temp_chartmonth = [];
    var chartlength = 0;
    var chartcurrentdate = new Date();
    if (selectedTopdate.split(",").length == 3) {
      setDataString("3 Months");
      const lastDayOfMonth = new Date();
      setShortMonth(`${monthNamesShort[lastDayOfMonth.getMonth()]}`);
      setQuickDate(dateOrdinal(lastDayOfMonth.getDate()));
      chartlength = 3;
    } else if (selectedTopdate.split(",").length == 6) {
      setDataString("6 Months");
      const lastDayOfMonth = new Date();
      setShortMonth(`${monthNamesShort[lastDayOfMonth.getMonth()]}`);
      setQuickDate(dateOrdinal(lastDayOfMonth.getDate()));
      chartlength = 6;
    } else if (selectedTopdate.split(",").length == 12) {
      setDataString("One Year");
      const lastDayOfMonth = new Date();
      setShortMonth(`${monthNamesShort[lastDayOfMonth.getMonth()]}`);
      setQuickDate(dateOrdinal(lastDayOfMonth.getDate()));
      chartlength = 12;
    } else {
      setDataString(quickmonth + " " + quickyear);
      if (currentdatemonth === quickmonth) {
        setQuickDate(dateOrdinal(currentdateday));
      } else {
        const lastDayOfMonth = new Date(
          current.getFullYear(),
          current.getMonth() + 1,
          0
        );
        const lastdayofdate = `${lastDayOfMonth.getDate()}`;
        setQuickDate(dateOrdinal(lastdayofdate));
      }
      chartlength = 6;
      chartcurrentdate = new Date(selectedTopdate);
    }

    // setDataString
    var apivalue = {
      environment: selectedEnvironment.toString(),
      account: selectedAccount.toString(),
      cloud: selectedCloud.toString(),
      application: selectedApplication.toString(),
      services: selectedservices.toString(),
      os: selectedos.toString(),
      infra: selectedinfra.toString(),
      db: selecteddb.toString(),
    };

    //chart
    var temp_chartdata = [];
    for (let index = 0; index < chartlength; index++) {
      const month_2_current = new Date(
        chartcurrentdate.getFullYear(),
        chartcurrentdate.getMonth() - index,
        1
      );
      const selectedTopdate_new = `${month_2_current.getFullYear()}-${
        month_2_current.getMonth() + 1
      }-01`;
      temp_chartmonth.push(selectedTopdate_new);
    }

    var selectedTopdate_new = temp_chartmonth.toString();

    // var companyName = localStorage.getItem(
    //   "CognitoIdentityServiceProvider.company"
    // );
    var companyName = "all_cloud";

    dispatch(
      getAllSummaryDashboard({ companyName, selectedTopdate_new, apivalue })
    )
      .unwrap()
      .then(({ data }) => {
        // console.log(data);
        setCurrrentSpendDiff({
          diff: 0,
          status_value: "+",
        });

        //chart
        const chartdatelist = [];
        const chartvaluelist = [];
        setCurrrentSpend(0);
        data.newfunctionquery3.map((data_value) => {
          const month_2_current = new Date(data_value.reportmonth);
          const chartmonth = `${
            monthNamesShort[month_2_current.getMonth()]
          }-${month_2_current.getFullYear().toString().substr(-2)}`;
          chartdatelist.push(chartmonth);
          chartvaluelist.push(Math.round(data_value.spend));
          //box
          if (
            new Date(data_value.reportmonth).getMonth() ===
              new Date(selectedTopdate).getMonth() &&
            selectedTopdate.split(",").length === 1
          ) {
            const tempcurrentspend = Number(data_value.spend);
            setCurrrentSpend(tempcurrentspend);
            var budget_value = Number(budgetdetails.overall_budget);
            var diff_value = 0;
            var projectspendstatus;
            if (tempcurrentspend > budget_value) {
              projectspendstatus = "+";
              diff_value = (tempcurrentspend * 100) / budget_value - 100;
            } else {
              projectspendstatus = "-";
              diff_value = 100 - (tempcurrentspend * 100) / budget_value;
            }
            setCurrrentSpendDiff({
              diff: diff_value.toLocaleString(undefined, {
                maximumFractionDigits: 2,
              }),
              status_value: projectspendstatus,
            });
            // var tempcurrentspenddiff = Number(data_value.actualspend_previous_month_dif);
            // tempcurrentspenddiff = tempcurrentspenddiff === 0 ? tempcurrentspenddiff : tempcurrentspenddiff.toLocaleString(undefined, {maximumFractionDigits:2});
            // setCurrrentSpendDiff(tempcurrentspenddiff);
          }
        });
        dispatch(spendTrandChartData(chartvaluelist));
        dispatch(spendTrandChartMonth(chartdatelist));
        //box
        if (selectedTopdate.split(",").length !== 1) {
          const tempcurrentspend = data.newfunctionquery3.reduce(
            (a, v) => (a = +a + +v.spend),
            0
          );
          setCurrrentSpend(tempcurrentspend);
          var budget_value = budgetdetails.overall_budget;
          var diff_value = 0;
          var projectspendstatus;
          if (tempcurrentspend > budget_value) {
            projectspendstatus = "+";
            diff_value = (tempcurrentspend * 100) / budget_value - 100;
          } else {
            projectspendstatus = "-";
            diff_value = (tempcurrentspend * 100) / budget_value;
          }
          setCurrrentSpendDiff({
            diff: diff_value.toLocaleString(undefined, {
              maximumFractionDigits: 2,
            }),
            status_value: projectspendstatus,
          });
        }
      })
      .catch((err) => {
        console.log(err.message);
        setCurrrentSpend(0);
        setCurrrentSpendDiff(0);
      });

    dispatch(getCreditChart({ companyName, selectedTopdate_new, apivalue }))
      .unwrap()
      .then(({ data }) => {
        //chart
        const chartdatelist = [];
        const chartvaluelist = [];
        var monthvalue = 0;
        data.newfunctionquery4.map((data_value) => {
          if (
            new Date(data_value.reportmonth).getMonth() ===
            new Date(selectedTopdate).getMonth()
          ) {
            const spend = Math.abs(Number(data_value.spend));
            monthvalue = spend;
          }
        });
        setCreditChart({
          chartdate: chartdatelist,
          chartvalue: chartvaluelist,
          monthvalue: monthvalue,
        });
      })
      .catch((err) => {
        console.log(err.message);
      });

    //UntaggedResources
    dispatch(getUntaggedResources({ companyName, selectedTopdate, apivalue }))
      .unwrap()
      .then(({ data }) => {
        // console.log(data);
        const total_tagged = data.newfunctionquery2?.reduce(
          (a, v) => (a = +a + +v.count_untagged_resource),
          0
        );

        const partially_tagged = data.newfunctionquery2?.filter(
          (v, index) => v.tagging_details === "partially_tagged"
        );
        const untagged = data.newfunctionquery2?.filter(
          (v, index) => v.tagging_details === "Untagged"
        );
        const tagged = data.newfunctionquery2?.filter(
          (v, index) => v.tagging_details === "tagged"
        );
        // const total_partially_tagged = partially_tagged?.reduce((a,v) =>  a = +a + +v.count_untagged_resource , 0 );
        const total_partially_tagged = partially_tagged?.reduce(
          (a, v) => (a = +a + +v.count_untagged_resource),
          0
        );
        const total_untagged = untagged?.reduce(
          (a, v) => (a = +a + +v.count_untagged_resource),
          0
        );
        const total_tagged_value = tagged?.reduce(
          (a, v) => (a = +a + +v.count_untagged_resource),
          0
        );

        // const percentage_value = (+total_partially_tagged + +total_untagged) * 100 / total_tagged;
        const percentage_value = (total_tagged_value * 100) / total_tagged;
        // console.log(0 / 0)
        // console.log(
        //     {
        //         total_tagged: total_tagged,
        //         partially_tagged : total_partially_tagged,
        //         untagged: total_untagged,
        //         percentage: percentage_value
        //     }
        // )
        setUntaggedResources({
          partially_tagged: total_partially_tagged.toString(),
          untagged: total_untagged.toString(),
          percentage: Number.isNaN(percentage_value)
            ? 0
            : percentage_value.toLocaleString(undefined, {
                maximumFractionDigits: 2,
              }),
          list: data.newfunctionquery2,
          update: true,
        });
        setPreloader(false);
      })
      .catch((err) => {
        console.log(err.message);
        setPreloader(false);
      });
    var currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - 1);
    let yesterdayDate = `${currentDate.getFullYear()}-${
      currentDate.getMonth() + 1
    }-${currentDate.getDate()}`;
    //start / stop scheduler
    dispatch(getStartStopScheduler({ companyName, yesterdayDate, apivalue }))
      .unwrap()
      .then(({ data }) => {
        const completedList = data.newfunctionquery17.filter(
          (v) => v.status.toLowerCase() === "completed"
        );
        const pendingList = data.newfunctionquery17.filter(
          (v) => v.status.toLowerCase() === "pending"
        );
        var percentage = 0;
        if (data.newfunctionquery17.length > 0) {
          percentage =
            (completedList.length * 100) / data.newfunctionquery17.length;
        }
        setStartStopScheduler({
          completed: completedList.length,
          pending: pendingList.length,
          percentage: percentage,
          status: true,
        });
      })
      .catch((err) => {
        console.log(err.message);
        setPreloader(false);
      });

    //application budget allocation
    var budget_year;
    if (selectedTopdate.split(",").length > 1) {
      const datelist = selectedTopdate.split(",").map((data) => {
        return new Date(data).getFullYear();
      });
      const uniqueArr = new Set([...datelist]);
      budget_year = [...uniqueArr].toString();
    } else {
      budget_year = new Date(selectedTopdate).getFullYear();
    }
    const input = {
      account_name: selectedAccount.toString(),
      application: selectedApplication.toString(),
      budget_year: budget_year,
      cloud: selectedCloud.toString(),
      company_name: companyName,
      environment: selectedEnvironment.toString(),
    };
    dispatch(listApplicationBudget({ input }))
      .unwrap()
      .then(({ data }) => {
        var percentage = 0;
        if (
          Number.isInteger(
            Math.round(
              (data.list_application_budget.length * 100) /
                applicationList.length
            )
          )
        ) {
          percentage = Math.round(
            (data.list_application_budget.length * 100) / applicationList.length
          );
        }
        setBudgetAllocation({
          budgetpercentage: percentage,
          total_application: applicationList.length,
          buget_allocation: data.list_application_budget.length,
          status: true,
        });
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [
    selectedAccount,
    selectedApplication,
    selectedCloud,
    selectedservices,
    selectedEnvironment,
    selectedTopdate,
    selecteddb,
    selectedinfra,
    selectedos,
    budgetdetails,
    applicationList,
  ]);

  useEffect(() => {
    //annual budget
    const cloud = selectedCloud.toString();
    const companyName = localStorage.getItem(
      "CognitoIdentityServiceProvider.company"
    );
    dispatch(listAnnualBudget({ companyName, cloud }))
      .unwrap()
      .then(({ data }) => {
        data.list_annual_budget.map((data) => {
          if (Number(data.budget_year) == new Date().getFullYear()) {
            var allocated_buget = 0;
            var last_month_allocated = 0;
            //condition
            //    console.log(selectedTopdate);
            const current_month = new Date(selectedTopdate).getMonth() + 1;
            const last_month = new Date(selectedTopdate).getMonth();

            //current month
            if (current_month === 1) {
              allocated_buget = data.budget_jan;
            } else if (current_month === 2) {
              allocated_buget = data.budget_feb;
            } else if (current_month === 3) {
              allocated_buget = data.budget_mar;
            } else if (current_month === 4) {
              allocated_buget = data.budget_apr;
            } else if (current_month === 5) {
              allocated_buget = data.budget_may;
            } else if (current_month === 6) {
              allocated_buget = data.budget_jun;
            } else if (current_month === 7) {
              allocated_buget = data.budget_jul;
            } else if (current_month === 8) {
              allocated_buget = data.budget_aug;
            } else if (current_month === 9) {
              allocated_buget = data.budget_sept;
            } else if (current_month === 10) {
              allocated_buget = data.budget_oct;
            } else if (current_month === 11) {
              allocated_buget = data.budget_nov;
            } else {
              allocated_buget = data.budget_dec;
            }
            //last month
            if (last_month === 1) {
              last_month_allocated = data.budget_jan;
            } else if (last_month === 2) {
              last_month_allocated = data.budget_feb;
            } else if (last_month === 3) {
              last_month_allocated = data.budget_mar;
            } else if (last_month === 4) {
              last_month_allocated = data.budget_apr;
            } else if (last_month === 5) {
              last_month_allocated = data.budget_may;
            } else if (last_month === 6) {
              last_month_allocated = data.budget_jun;
            } else if (last_month === 7) {
              last_month_allocated = data.budget_jul;
            } else if (last_month === 8) {
              last_month_allocated = data.budget_aug;
            } else if (last_month === 9) {
              last_month_allocated = data.budget_sept;
            } else if (last_month === 10) {
              last_month_allocated = data.budget_oct;
            } else if (last_month === 11) {
              last_month_allocated = data.budget_nov;
            } else {
              last_month_allocated = data.budget_dec;
            }

            var status_value = "";
            var diff_value = "";
            if (Number(allocated_buget) === Number(last_month_allocated)) {
              status_value = "=";
              diff_value = 0;
            } else {
              if (allocated_buget > last_month_allocated) {
                status_value = "+";
                diff_value =
                  (allocated_buget * 100) / last_month_allocated - 100;
              } else {
                status_value = "-";
                diff_value = (allocated_buget * 100) / last_month_allocated;
              }
            }
            const last_month_date = `${new Date(
              selectedTopdate
            ).getFullYear()}-${new Date(selectedTopdate).getMonth()}-${new Date(
              selectedTopdate
            ).getDate()}`;
            setBudgetDetails({
              overall_budget: Number(allocated_buget),
              last_month_budget: Number(last_month_allocated),
              month_budget: diff_value,
              status: status_value,
              month: `${monthNamesShort[new Date(last_month_date).getMonth()]}`,
            });
          }
        });
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [selectedTopdate]);

  useEffect(() => {
    var apivalue = {
      environment: selectedEnvironment.toString(),
      account: selectedAccount.toString(),
      cloud: selectedCloud.toString(),
      application: selectedApplication.toString(),
      services: selectedservices.toString(),
      os: selectedos.toString(),
      infra: selectedinfra.toString(),
      db: selecteddb.toString(),
    };

    var projected_date = [
      `${new Date().getFullYear()}-${new Date().getMonth() + 1}-01`,
      `${new Date().getFullYear()}-${new Date().getMonth()}-01`,
    ];
    projected_date = projected_date.toString();
    const companyName = localStorage.getItem(
      "CognitoIdentityServiceProvider.company"
    );
    dispatch(getProjectedSpend({ companyName, projected_date, apivalue }))
      .unwrap()
      .then(({ data }) => {
        // console.log("data");
        // console.log(data);
        //current month
        const current_month_records = data.newfunctionquery8.filter((x) => {
          if (
            `${new Date().getFullYear()}-${new Date().getMonth()}` ===
            `${new Date(x.reportmonth).getFullYear()}-${new Date(
              x.reportmonth
            ).getMonth()}`
          ) {
            return x;
          }
        });
        var tempprojectspend = current_month_records.reduce(
          (a, v) => (a = +a + +v.projected_spend),
          0
        );
        const new_tempprojectspend = tempprojectspend;

        setProjectedSpend(0);
        setProjectedSpendDiff({
          diff: 0,
          status_value: "+",
        });
        var tempprojectspend_last_month = budgetdetails.overall_budget;
        var projectspendstatus;
        var diff_value = 0;
        if (tempprojectspend > tempprojectspend_last_month) {
          projectspendstatus = "+";
          diff_value =
            (tempprojectspend * 100) / tempprojectspend_last_month - 100;
        } else {
          projectspendstatus = "-";
          diff_value = (tempprojectspend * 100) / tempprojectspend_last_month;
        }
        setProjectedSpend(new_tempprojectspend);
        setProjectedSpendDiff({
          diff: diff_value.toLocaleString(undefined, {
            maximumFractionDigits: 2,
          }),
          status_value: projectspendstatus,
        });
      })
      .catch((err) => {
        console.log(err.message);
        setProjectedSpend(0);
        setProjectedSpendDiff({
          diff: 0,
          status_value: "+",
        });
      });

    dispatch(getUderUtilized({ companyName, selectedTopdate, apivalue }))
      .unwrap()
      .then(({ data }) => {
        // console.log(data);
        setUnderResources(
          Math.round(
            (Math.round(data.newfunctionquery23[0].underutilized_resources) *
              100) /
              Math.round(data.newfunctionquery23[0].total_resources)
          )
        );
        setUnderResourcesValue(
          Math.round(data.newfunctionquery23[0].underutilized_resources)
        );
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [
    selectedAccount,
    selectedApplication,
    selectedCloud,
    selectedservices,
    selectedEnvironment,
    selectedTopdate,
    selecteddb,
    selectedinfra,
    selectedos,
    currrentspend,
  ]);

  const ExpandPopup = () => {
    return <ExpandPopup status="true" />;
  };

  const screen1 = useFullScreenHandle();
  const screen2 = useFullScreenHandle();

  const policy_col1 = (
    <Box className="plat-box">
      <Row>
        <Col lg="12">
          <p className="plat-quick-inline policy-managment">
            Under Utilized Resources
          </p>
        </Col>
        <div className="plat-box-body policy-managment">
          <Typography
            className="poppins-font cardnumber plat-totle-value"
            color="black"
            gutterBottom
          >
            <label className="poppins-font cardnumber">
              {" "}
              {underresourcesper}%{" "}
            </label>
          </Typography>
          <p className="policy-bottom-line">
            Underutilized Assets - {underresourcesvalue}
          </p>
        </div>
      </Row>
    </Box>
  );
  const credit_box = (
    <Box className="plat-box plat_cursor_pointer">
      <Row>
        <Col lg="12">
          <p className="plat-quick-inline saving-model"> Credits Applied</p>
        </Col>
        <div className="plat-box-body saving-model">
          <Typography
            className="poppins-font cardnumber plat-totle-value"
            color="black"
            gutterBottom
          >
            {creditchart.monthvalue === 0 ? (
              <label className="poppins-font cardtext"> none </label>
            ) : (
              <label className="poppins-font cardnumber">
                {" "}
                $ {numberFormat(creditchart.monthvalue)}{" "}
              </label>
            )}
          </Typography>
        </div>
      </Row>
    </Box>
  );
  // const policy_col2 = <Box className='plat-box'>
  //     <Row>
  //         <Col lg="12">
  //             <p className='plat-quick-inline policy-managment'>Untagged Resources</p>
  //         </Col>
  //         <div className='plat-box-body policy-managment'>
  //             <Typography className='poppins-font cardnumber plat-totle-value'
  //                 color="black"
  //                 gutterBottom
  //             >
  //                 <label className='poppins-font cardnumber'> {untaggedresources} </label>
  //             </Typography>
  //         </div>
  //     </Row>
  // </Box>;

  const policy_col2 = (
    <Box className="plat-box">
      <Row>
        <Col lg="12">
          <p className="plat-quick-inline policy-managment">Tagging</p>
        </Col>
        <div className="plat-box-body policy-managment">
          <Typography
            className="poppins-font cardnumber plat-totle-value"
            color="black"
            gutterBottom
          >
            <label className="poppins-font cardnumber">
              {" "}
              {untaggedresources.percentage}%{" "}
            </label>
          </Typography>
        </div>
        <div className="scheduler-bottom-line-body">
          <p>
            Partially tagged -{" "}
            {untaggedresources.partially_tagged.length > 4 ? (
              <>
                <Tooltip
                  placement="bottom"
                  title={untaggedresources.partially_tagged}
                >
                  {untaggedresources.partially_tagged.substring(0, 4)}...
                </Tooltip>
              </>
            ) : (
              untaggedresources.partially_tagged
            )}
          </p>

          <p>
            Untagged -{" "}
            {untaggedresources.untagged.length > 4 ? (
              <>
                <Tooltip placement="bottom" title={untaggedresources.untagged}>
                  {untaggedresources.untagged.substring(0, 4)}...
                </Tooltip>
              </>
            ) : (
              untaggedresources.untagged
            )}
          </p>
        </div>
      </Row>
    </Box>
  );

  const policy_col3 = (
    <Box className="plat-box">
      <Row>
        <Col lg="12">
          <p className="plat-quick-inline policy-managment">Policy</p>
        </Col>
        <div className="plat-box-body policy-managment">
          <Typography
            className="poppins-font cardnumber plat-totle-value"
            color="black"
            gutterBottom
          >
            <label className="poppins-font cardnumber"> 70% </label>
          </Typography>
          <p className="policy-bottom-line">
            Click for detailed compliance information
          </p>
        </div>
      </Row>
    </Box>
  );

  const asset_col1 = (
    <Box
      className="plat-box plat_cursor_pointer plat-box-hover"
      onClick={() => dispatch(setTabActive(2))}
    >
      <Row>
        {/* <div className='plat-asset-body'>
                    <div className='plat-asset-month'>
                        
                        <p className='plat-quick-inline plat-quick-inline-sm-1'>{datestring}</p>
                    </div>
                    <div className='plat-asset-date'>
                        {
                            quickmonth !== 'undefined' ?
                            <p className='plat-quick-inline plat-quick-inline-sm-2'>As of {quickdate} {quickmonth}</p>
                            :
                            <p className='plat-quick-inline plat-quick-inline-sm-2'>As of {quickdate} {shortmonth}</p>
                        }
                        
                    </div>
                </div> */}

        <Col lg="12">
          <p className="plat-quick-inline saving-model">Spend for the month</p>
        </Col>

        <div className="plat-box-body  saving-model">
          <Typography
            className="poppins-font cardnumber plat-totle-value"
            color="black"
            gutterBottom
          >
            <label className="poppins-font cardnumber">
              {" "}
              ${numberFormat(currrentspend)}
            </label>
            &nbsp;&nbsp;
          </Typography>
          <p className="policy-bottom-line">
            {currrentspenddiff.diff > 0 ? (
              <>
                <Icon
                  className={
                    "me-1 " +
                    (currrentspenddiff.status_value === "-"
                      ? "text-success"
                      : "text-danger-label")
                  }
                  icon={
                    currrentspenddiff.status_value === "-"
                      ? "material-symbols:arrow-drop-up"
                      : "material-symbols:arrow-drop-down"
                  }
                  style={{ fontSize: "20px" }}
                />

                {/* <MDBIcon className={"me-1 "+(currrentspenddiff.status_value === '-' ? "text-success" : "text-danger-label")}  fas icon={(currrentspenddiff.status_value === '-' ? "caret-down" : "caret-up")} style={{ fontSize: '12px' }} /> */}
                {/* <label className={"labelsmallest poppins-font "+(currrentspenddiff.status_value === '-' ? "text-success" : "text-danger-label")}>{currrentspenddiff.diff}%</label> */}
              </>
            ) : (
              <>
                {/* <label className={"labelsmallest poppins-font text-gray-color"}>0</label>  */}
              </>
            )}
            {currrentspenddiff.diff}% vs budget
          </p>
        </div>
      </Row>
    </Box>
  );
  const asset_col3 = (
    <Box
      className="plat-box plat_cursor_pointer plat-box-hover"
      onClick={() => dispatch(setTabActive(4))}
    >
      <Row>
        <Col lg="12">
          <p className="plat-quick-inline saving-model">
            Spend forecast for {current_month_text}
          </p>
        </Col>
        <div className="plat-box-body saving-model">
          <Typography
            className="poppins-font cardnumber plat-totle-value"
            color="black"
            gutterBottom
          >
            <label className="poppins-font cardnumber">
              {" "}
            {projectedspend ? (<>${numberFormat(projectedspend)}</>) :"0"}
            </label>
            &nbsp;&nbsp;
          </Typography>
          <p className="policy-bottom-line">
            {projectedspenddiff.diff == 0 ? (
              <>
                <label className={"labelsmallest poppins-font text-gray-color"}>
                  ${projectedspenddiff.diff}
                </label>
              </>
            ) : (
              <>
                <Icon
                  className={
                    "me-1 " +
                    (projectedspenddiff.status_value === "-"
                      ? "text-success"
                      : "text-danger-label")
                  }
                  icon={
                    projectedspenddiff.status_value === "-"
                      ? "material-symbols:arrow-drop-up"
                      : "material-symbols:arrow-drop-down"
                  }
                  style={{ fontSize: "20px" }}
                />

                {/* <MDBIcon className={"me-1 "+(projectedspenddiff.status_value === "+" ? "text-danger-label" : "text-success")}  fas icon={(projectedspenddiff.status_value === "+" ? "caret-up" : "caret-down")} style={{ fontSize: '12px' }} /> */}
                {/* <label className={"labelsmallest poppins-font "+(projectedspenddiff.status_value === "+" ? "text-danger-label" : "text-success")}>{projectedspenddiff.diff}%</label> */}
              </>
            )}{" "}
            {projectedspenddiff.diff}% vs budget
          </p>
        </div>
      </Row>
    </Box>
  );
  const asset_col4 = (
    <Box className="plat-box">
      <div className="plat-table">
        <p className="quick-asset-title">Asset Summary</p>
        <Table bordered size="sm" className="plat-table-custom">
          <thead className="table-asset-thead">
            <tr>
              <th className="plat-table-head-3">Asset Type</th>
              <th className="plat-table-head-3">VM Count</th>
              <th className="plat-table-head-3">Core</th>
              <th className="plat-table-head-3">RAM(TB)</th>
              <th className="plat-table-head-3">Storage(TB)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="plat-table-head-2">VM</td>
              <td className="plat-table-body">{assetsummaryvm.count}</td>
              <td className="plat-table-body">{assetsummaryvm.core}</td>
              <td className="plat-table-body">{assetsummaryvm.ram}</td>
              <td className="plat-table-body">{assetsummaryvm.storage}</td>
            </tr>
            <tr>
              <td className="plat-table-head-2">DBaaS</td>
              <td className="plat-table-body">{assetsummarydb.count}</td>
              <td className="plat-table-body">{assetsummarydb.core}</td>
              <td className="plat-table-body">{assetsummarydb.ram}</td>
              <td className="plat-table-body">{assetsummarydb.storage}</td>
            </tr>
            <tr>
              <td className="plat-table-head-2">Others</td>
              <td className="plat-table-body">{assetsummaryothers.count}</td>
              <td className="plat-table-body">{assetsummaryothers.core}</td>
              <td className="plat-table-body">{assetsummaryothers.ram}</td>
              <td className="plat-table-body">{assetsummaryothers.storage}</td>
            </tr>
          </tbody>
        </Table>
      </div>
    </Box>
  );

  const sectionZoomin = (type) => {
    if (type === "row1-col2") {
      setTaggingStatus(true);
    } else if (type === "row2-col2") {
      setChartZoomInStatus(true);
    } else if (type === "row2-credit") {
      setCreditChartZoomInStatus(true);
    } else if (type === "untiled_resources") {
      setUnderResourcesStatus(true);
    } else if (type === "budget_compliance") {
      setBudgetComplianceStatus(true);
    }
  };

  return (
    <Fragment>
      <Trendchart
        zoominstatus={chartzoominstatus}
        setZoomInStatus={setChartZoomInStatus}
      />
      <Creditchart
        zoominstatus={creditchartzoominstatus}
        setZoomInStatus={setCreditChartZoomInStatus}
        creditchart={creditchart}
      />
      <TaggingDetails
        zoominstatus={taggingstatus}
        setZoomInStatus={setTaggingStatus}
        records={untaggedresources}
      />
      <UnderResourcesInfo
        zoominstatus={underresources}
        setZoomInStatus={setUnderResourcesStatus}
      />
      <BudgetComplianceInfo
        zoominstatus={budgetcompliance}
        setZoomInStatus={setBudgetComplianceStatus}
      />
      <ZoomIn zoominstatus={zoominstatus} setZoomInStatus={setZoomInStatus}>
        {zoominpopupcontent}
      </ZoomIn>
      <div className="plat-dashboard-body">
        <div className="plat-dashboard-quickview">
          <QuickView />
          {/* <DashboardSidebar /> */}
        </div>
        <div className="plat-dashboard-tabs">
          <Tapmenu
            type="quickglance"
            osstatus={true}
            dbstatus={true}
            title="Quick Glance"
            tagging={untaggedresources.percentage}
            budget={budgetallocation.budgetpercentage}
            scheduler={startstopscheduler.percentage}
            tagging_status={untaggedresources.update}
            budget_status={budgetallocation.status}
            scheduler_status={startstopscheduler.status}
          />
          <div className="plat-full-asset-spend-cover">
            {preloader ? (
              <Spinner animation="grow" className="plat-spinner-grow" />
            ) : (
              <>
                <div className="plat-full-cover">
                  <Row className="plat-quick-box-body cart-title-bar">
                    <h1 className="Plat-quick-title">Budget / Variance</h1>

                    <Col>
                      <Row>
                        <Col lg="3" className="mt-3 plat-main-box">
                          <Box
                            className="plat-box  plat-box-hover"
                            onClick={() => dispatch(setTabActive(4))}
                          >
                            <Row>
                              <Col lg="12">
                                <p className="plat-quick-inline saving-model">
                                  Budget for the period
                                </p>
                              </Col>
                              <div className="plat-box-body saving-model">
                                <Typography
                                  className="poppins-font cardnumber plat-totle-value"
                                  color="black"
                                  gutterBottom
                                >
                                  {budgetdetails.overall_budget === 0 ? (
                                    <label className="poppins-font cardtext">
                                      {" "}
                                      Not Planned
                                    </label>
                                  ) : (
                                    <>
                                      <label className="poppins-font cardnumber">
                                        {" "}
                                        $
                                        {numberFormat(
                                          budgetdetails.overall_budget
                                        )}
                                      </label>
                                      &nbsp;
                                    </>
                                  )}
                                </Typography>
                                <p className="policy-bottom-line">
                                  {budgetdetails.status === "=" ? (
                                    <>Same as {budgetdetails.month} Month</>
                                  ) : (
                                    <>
                                      <Icon
                                        className={
                                          "me-1 " +
                                          (budgetdetails.status === "-"
                                            ? "text-success"
                                            : "text-danger-label")
                                        }
                                        icon={
                                          budgetdetails.status === "-"
                                            ? "material-symbols:arrow-drop-up"
                                            : "material-symbols:arrow-drop-down"
                                        }
                                        style={{ fontSize: "20px" }}
                                      />
                                      {budgetdetails.month_budget}% vs{" "}
                                      {budgetdetails.month} Month
                                    </>
                                  )}
                                </p>
                              </div>
                            </Row>
                          </Box>
                        </Col>

                        <Col lg="3" className="mt-3 plat-main-box">
                          {asset_col1}
                        </Col>

                        <Col lg="3" className="mt-3 plat-main-box">
                          {asset_col3}
                        </Col>
                        {/* credit */}
                        <Col lg="3" className="mt-3 plat-main-box">
                          <Box className="plat-box">{credit_box}</Box>
                        </Col>
                        {/* <Col lg="3" className='mt-3 plat-main-box'>
                                            <Box className='plat-box'>
                                                <LineChart />
                                            </Box>
                                            <div className='plat-box-topicon-option'>
                                                <Dropdown className='plat-costimize-dropdown plat-box-costimize-dropdown'>
                                                    <Dropdown.Toggle className='plat-three-dort plat-tap-menu plat-box-three-dort' id="dropdown-button-dark-example1" variant="secondary">
                                                        <Icon icon="ph:dots-three-bold" class='plat-expandalt-icon-view'></Icon>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu className='plat-box-menu-list'>
                                                    <Dropdown.ItemText onClick={() => sectionZoomin('row2-col2')} className="plat-dropdown-item-text"><span className='plat-box-item-title list-expand-left'>
                                                        Expand
                                                        </span>
                                                        <span className='plat-box-item-title list-expand-right'><HiOutlineArrowsExpand /></span> </Dropdown.ItemText>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        </Col> */}
                      </Row>
                    </Col>
                    <Col>
                      <Row>
                        <Col lg={5} className="dashbaord-chart-bg">
                          <Row>
                            <h4 className="dashbaord-chart-title">
                              Spend vs Forecast
                            </h4>
                          </Row>
                          <SpendChart />
                        </Col>
                        <Col lg={5} className="dashbaord-chart-bg">
                          <Row>
                            <h4 className="dashbaord-chart-title">
                              Top 5 Application
                            </h4>
                          </Row>
                          <AssetBarChart />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
                <QuickGlanceSavings />
                <div className="plat-full-cover">
                  <Row className="plat-quick-box-body cart-title-bar">
                    <h1 className="Plat-quick-title">Compliance</h1>
                    <Col lg="3" className="mt-3 plat-main-box">
                      {policy_col1}
                      {/* <div className='plat-box-topicon-option'>
                                        <Dropdown className='plat-costimize-dropdown plat-box-costimize-dropdown'>
                                            <Dropdown.Toggle className='plat-three-dort plat-tap-menu plat-box-three-dort' id="dropdown-button-dark-example1" variant="secondary">
                                                <Icon icon="ph:dots-three-bold" class='plat-expandalt-icon-view'></Icon>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className='plat-box-menu-list more-details'>
                                                <Dropdown.ItemText onClick={() => sectionZoomin('untiled_resources')} className="plat-dropdown-item-text">
                                                    <span className='plat-box-item-title list-expand-left'>
                                                        More Info
                                                    </span>
                                                    <span className='plat-box-item-title list-expand-right'>
                                                        <CgDetailsMore />
                                                    </span>
                                                </Dropdown.ItemText>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div> */}
                    </Col>

                    <Col lg="3" className="mt-3 plat-main-box">
                      {policy_col2}
                      <div className="plat-box-topicon-option">
                        <Dropdown className="plat-box-costimize-dropdown">
                          <Dropdown.Toggle
                            className="plat-three-dort plat-tap-menu plat-box-three-dort"
                            id="dropdown-button-dark-example1"
                            variant="secondary"
                          >
                            <Icon
                              icon="ph:dots-three-bold"
                              class="plat-expandalt-icon-view"
                            ></Icon>
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="plat-box-menu-list more-details">
                            <Dropdown.ItemText
                              onClick={() => sectionZoomin("row1-col2")}
                              className="plat-dropdown-item-text"
                            >
                              <span className="plat-box-item-title list-expand-left">
                                More Details
                              </span>
                              <span className="plat-box-item-title list-expand-right">
                                <CgDetailsMore />
                              </span>
                            </Dropdown.ItemText>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </Col>
                    {/* <Col lg="3" className='mt-3 plat-main-box'>
                                    {policy_col3}
                                </Col> */}
                    <Col lg="3" className="mt-3 plat-main-box">
                      <Box
                        className="plat-box plat_cursor_pointer plat-box-hover"
                        onClick={() => dispatch(setTabActive(4))}
                      >
                        <Row>
                          <Col lg="12">
                            <p className="plat-quick-inline policy-managment">
                              Budget Allocation
                            </p>
                          </Col>
                          <div className="plat-box-body policy-managment">
                            <Typography
                              className="poppins-font cardnumber plat-totle-value"
                              color="black"
                              gutterBottom
                            >
                              <label className="poppins-font cardnumber">
                                {" "}
                                {budgetallocation.budgetpercentage}%{" "}
                              </label>
                            </Typography>
                            <div className="policy-bottom-line-body">
                              <h1 className="budget-bottom-line-left">
                                Total No of Application -{" "}
                                {budgetallocation.total_application}
                              </h1>
                              <h1 className="budget-bottom-line-right">
                                Budget Allocation -{" "}
                                {budgetallocation.buget_allocation}
                              </h1>
                            </div>
                          </div>
                        </Row>
                      </Box>
                      {/* <div className='plat-box-topicon-option'>
                                        <Dropdown className='plat-costimize-dropdown plat-box-costimize-dropdown'>
                                            <Dropdown.Toggle className='plat-three-dort plat-tap-menu plat-box-three-dort' id="dropdown-button-dark-example1" variant="secondary">
                                                <Icon icon="ph:dots-three-bold" class='plat-expandalt-icon-view'></Icon>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className='plat-box-menu-list more-details'>
                                                <Dropdown.ItemText onClick={() => dispatch(setTabActive(4))} className="plat-dropdown-item-text">
                                                    <span className='plat-box-item-title list-expand-left'>
                                                        More Info
                                                    </span>
                                                    <span className='plat-box-item-title list-expand-right'>
                                                        <CgDetailsMore />
                                                    </span>
                                                </Dropdown.ItemText>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div> */}
                    </Col>

                    <Col lg="3" className="mt-3 plat-main-box">
                      <Box className="plat-box">
                        <Row>
                          <Col lg="12">
                            <p className="plat-quick-inline policy-managment">
                              Start/Stop Scheduler
                            </p>
                          </Col>
                          <div className="plat-box-body policy-managment">
                            <Typography
                              className="poppins-font cardnumber plat-totle-value"
                              color="black"
                              gutterBottom
                            >
                              <label className="poppins-font cardnumber">
                                {" "}
                                {startstopscheduler.percentage}%{" "}
                              </label>
                            </Typography>
                            <div className="scheduler-bottom-line-body">
                              <p>Completed - {startstopscheduler.completed}</p>
                              <p>Pending - {startstopscheduler.pending}</p>
                            </div>
                          </div>
                        </Row>
                      </Box>
                      {/* <div className='plat-box-topicon-option'>
                                        <Dropdown className='plat-costimize-dropdown plat-box-costimize-dropdown'>
                                            <Dropdown.Toggle className='plat-three-dort plat-tap-menu plat-box-three-dort' id="dropdown-button-dark-example1" variant="secondary">
                                                <Icon icon="ph:dots-three-bold" class='plat-expandalt-icon-view'></Icon>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className='plat-box-menu-list more-details'>
                                                <Dropdown.ItemText onClick={() => sectionZoomin('budget_compliance')} className="plat-dropdown-item-text">
                                                    <span className='plat-box-item-title list-expand-left'>
                                                        More Info
                                                    </span>
                                                    <span className='plat-box-item-title list-expand-right'>
                                                        <CgDetailsMore />
                                                    </span>
                                                </Dropdown.ItemText>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div> */}
                    </Col>
                  </Row>
                </div>

                {/* <div className='plat-full-cover'>   
                            <h1 className='Plat-quick-title'>Assets/Spend</h1>
                            <Row className='plat-quick-box-body'>
                                <Col lg="3" className='mt-3 plat-main-box'>
                                    {asset_col4}
                                    <div className='plat-box-topicon'>
                                        <FaExpandAlt   className='plat-expandalt-icon' onClick={() => sectionZoomin('row2-col4')}/>
                                    </div>
                                </Col>
                            </Row>
                        </div> */}
              </>
            )}
          </div>
        </div>
      </div>
      <CustimizeFooter />
    </Fragment>
  );
};
export default React.memo(QuickGlance);
