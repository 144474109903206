import { Icon } from "@iconify/react";
import { Card, Spin } from "antd";

import React, { useEffect, useState } from "react";
import { BiSolidDownArrowAlt, BiSolidUpArrowAlt } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import {
  getUsageForMonthApiData,
  getUsageForMonthTrendApiData,
} from "../../../action/costimizedashboardAction";
import CardGraph from "./CardGraph";

function UsageForTheMonth({ handleUsageForTheMonthModal }) {
  const [usageMonthData, setUsageMonthData] = useState("");
  const [usageMonthChartData, setUsageMonthChartData] = useState("");
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  // var company_name = localStorage.getItem(
  //   "CognitoIdentityServiceProvider.company"
  // );
  var company_name ="all_cloud"
  const {
    selectedCloud,
    selectedTopdate,
    selectedEnvironment,
    selectedAccount,
    selectedApplication,
    selectedservices,
    selectedos,
    selectedinfra,
    selecteddb,
  } = useSelector((state) => state.constimize);
  useEffect(() => {
    setLoading(true);
    const remainingKeys = {
      screen: "qg",
      environment: selectedEnvironment.toString(),
      account_name: selectedAccount.toString(),
      application: selectedApplication.toString(),
      company: company_name,
      cloud: selectedCloud.toString(),
      db: selecteddb.toString(),
      infra: selectedinfra.toString(),
      month: selectedTopdate,
      os: selectedos.toString(),
      service: selectedservices.toString(),
    };
    let apiVariablesMonth = {
      event_type: "usage for the month",
      ...remainingKeys,
    };
    let apiVariablesMonthTrend = {
      event_type: "usage for the month trend",
      ...remainingKeys,
    };
    // --api call for graph data-----
    dispatch(getUsageForMonthTrendApiData(apiVariablesMonthTrend))
      .unwrap()
      .then(({ data }) => {
        const jsonString = data?.usageforthemonthtrend;
        // Parse the JSON-encoded string to a JavaScript object
        let resp = JSON.parse(jsonString);
        setUsageMonthChartData(resp);
      })
      .catch((err) => {
        console.log(err?.message);
      });
    // --api call for card data-----
    dispatch(getUsageForMonthApiData(apiVariablesMonth))
      .unwrap()
      .then(({ data }) => {
        const jsonString = data?.usageforthemonth;
        // Parse the JSON-encoded string to a JavaScript object
        let resp = JSON.parse(jsonString);
        setUsageMonthData(resp);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err?.message);
        setLoading(false);
      });
  }, [
    selectedCloud,
    selectedTopdate,
    selectedEnvironment,
    selectedAccount,
    selectedApplication,
    selectedservices,
    selectedos,
    selectedinfra,
    selecteddb,
  ]);

  const currencyFormatter = (val) => {
    if (isNaN(Number(val))) {
      return 0;
    } else {
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD", // Change to your desired currency code
        minimumFractionDigits: 0, // Minimum number of fraction digits
        maximumFractionDigits: 2, // Maximum number of fraction digits
      }).format(Number(val));
    }
  };
  return (
    <div>
      {/* <pre className="text-white">
        {" "}
        {JSON.stringify(usageMonthData, null, 3)}
      </pre> */}
      <Spin spinning={loading} wrapperClassName="costimize-loader">
        <Card className="plat-card-1 plat-card-p-0 p-0 m-0 mnH overflowHidden">
          <h1 className={` spendCardTitle  ptl-3`}>
            Usage for the month{" "}
            {selectedTopdate !== "2023-5-01" && (
              <Icon
                icon="iconamoon:information-circle-fill"
                style={{
                  // marginTop: "7px",
                  fontSize: "14px",
                  color: "#848484",
                  cursor: "pointer",
                }}
                onClick={handleUsageForTheMonthModal}
              />
            )}
          </h1>
          <div className="usmon">
            {/* <BiSolidUpArrowAlt clolor="red" /> */}
            {/* {console.log(current_month_spend)} */}
            <p className="t1">
              {usageMonthData && (
                <>{currencyFormatter(usageMonthData?.current_month_spend)}</>
              )}

              {Number(usageMonthData?.difference) < 0 ? (
                <BiSolidDownArrowAlt color="#12cf12" size={12} />
              ) : (
                <>
                  {usageMonthData && (
                    <BiSolidUpArrowAlt color="#f2364f" size={12} />
                  )}
                </>
              )}

              <span
                className={`usg-mon-diff-t ${
                  Number(usageMonthData?.difference) > 0 && "text-danger"
                }`}
              >
                {usageMonthData && (
                  <>{currencyFormatter(usageMonthData?.difference)}</>
                )}
              </span>
            </p>
            <p className="t2">
              Same time last month:{" "}
              {usageMonthData && (
                <>{currencyFormatter(usageMonthData?.previous_month_spend)}</>
              )}
            </p>
          </div>
          <div className="crdGrpBt">
            <CardGraph chartData={usageMonthChartData} />
          </div>
        </Card>
      </Spin>
    </div>
  );
}

export default UsageForTheMonth;
