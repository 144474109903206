import { Button, Card, Col, Row, Switch, Spin } from "antd";
import apexcharts from "apexcharts";
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Companylogo from "../../costimize_v2/WeeklyReport/company_logo.png";
import CHLOGO from "../../costimize_v2/WeeklyReport/onechlogo.jpg";
import "../../costimize_v2/costimize_v2.css";
import Appdata from "./Appdata";

import ApplicationLevelSummaryUI from "./ApplicationLevelSummaryUI";
import ApplicationLvelSummarySpentMoreUI from "./ApplicationLvelSummarySpentMoreUI";

import CostimizeSidebar from "../layout/CostimizeSidebar";
import OverallSummaryUI from "./OverallSummaryUI";
import ProductionTable from "./ProductionTable";
import Rep from "./Rep";
import Reportdata from "./Reportdata";

import ThreeMonthsSpend from "./ThreeMonthsSpend";
import ReactApexChart from "react-apexcharts";
import ServiceSummary from "./ServiceSummary";
import { headercustomerlogo } from "../../action/header_logo";
import { DatePicker, Space } from "antd";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import DetailedReport from "./DetailedReport";
import { Redirect } from "react-router-dom";
import { ConnectingAirportsOutlined, Rowing } from "@mui/icons-material";
import {
  PDFDownloadLink,
  PDFViewer,
  Document,
  Page,
  BlobProvider,
  Text,
} from "@react-pdf/renderer";
import { Icon } from "@iconify/react";
import { renderToStaticMarkup } from "@react-pdf/renderer";
// import jsPDF from "jspdf";
import Test from "./Test";
import { saveAs } from "file-saver";
import html2canvas from "html2canvas";
function Report() {
  const downloadLinkRef = useRef(null);
  const location = useLocation();
  //kamalika
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() - 3);

  const date = `${currentDate.getDate().toString().padStart(2, "0")}/${(
    currentDate.getMonth() + 1
  )
    .toString()
    .padStart(2, "0")}/${currentDate.getFullYear()}`;
  const company_name = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );
  const companyName = company_name.toUpperCase();
  //API's kamalika
  const [reportmonth, setReportmonth] = useState([]); //integrated
  const [reportweek, setReportweek] = useState([]); //Intergrated
  const [reportweekwise, setReportweekwise] = useState([]);
  // const [reportweekwisesum, setReportweekwisesum] = useState([]);
  const [urlimg, setUrlimg] = useState(false);
  const [reportweekwiseappsum, setReportweekwiseappsum] = useState([]);
  const [reportweekwiseappsuminc, setReportweekwiseappsuminc] = useState([]); //Value is coming
  const [reportweekwiseappsumdec, setReportweekwiseappsumdec] = useState([]); // values is coming
  const [reportweekwiseappsumservice, setReportweekwiseappsumservice] =
    useState([]); //values is coming
  const [reportappwise, setReportweekappservice] = useState([]);
  const [reportweekenvironment, setReportweekenvironment] = useState([]); //environemnt week
  const [reportDetailedService, setReportdetailedservice] = useState([]);
  const [overall, setOverall] = useState([]);
  const [company_logo, setCompanyLogo] = useState();
  const [reportApplication, setReportApplication] = useState("");
  const [reportService, setReportService] = useState("");
  const [isShowSpinner, setShowSpinner] = useState(true);
  const [loading, setLoading] = useState(true);
  const today = moment();
  const daysSinceSunday = 9 - today.day();
  const todayWeekday = today.isoWeekday(); // Get the weekday (1 for Monday, 2 for Tuesday, ..., 7 for Sunday)
  const daysToLastSaturday = todayWeekday < 7 ? todayWeekday + 1 : 0;

  const defaultStartDate = today
    .subtract(daysToLastSaturday + 7, "days")
    .format("YYYY-MM-DD");

  const defaultEndDate = moment(defaultStartDate)
    .add(6, "days")
    .format("YYYY-MM-DD");
  const [selectedDate, setSelectedDate] = useState(defaultStartDate);
  const [endDate, setEndDate] = useState(defaultEndDate);
  const [dates, setDates] = useState(false);
  const [applicationService, setApplicationService] = useState("");
  const [servicepastfourweeks, setServiceFourWeeks] = useState("");
  const [applicationFourweeks, setApplicationFourweeks] = useState("");

  console.log(reportDetailedService);
  console.log(servicepastfourweeks);
  console.log(applicationFourweeks);
  const chartData = reportweekwise.map((data) => ({
    environment: data.environment,
    pre_previous_week: parseInt(data.pre_previous_week),
    previous_week: parseInt(data.previous_week),
    current_week: parseInt(data.current_week),
    fourth_week: parseInt(data.fourth_week),
  }));
  const dispatch = useDispatch();

  //kamalika
  const options_Grouped = {
    chart: {
      id: "groupedbar",
      type: "bar",
      height: 430,
    },
    plotOptions: {
      bar: {
        borderRadius: 5,
        horizontal: false,
        dataLabels: {
          position: "top",
        },
      },
    },

    dataLabels: {
      enabled: true,
      position: "top",
      style: {
        fontSize: "12px",
        colors: ["#3B3B3B"],
      },
      formatter: function (val) {
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
      offsetY: -20,
    },
    xaxis: {
      categories: chartData.map((data) => data.environment),
    },
    title: {
      text: "Spend by Environments & Week ($)",
      align: "center",
      //   width: "1rem",
      style: {
        fontSize: "15px",
        fontWeight: "500",
        color: "#3B3B3B",
      },
    },
    legend: {
      position: "top",
      // containerMargin: {
      //   top: 10, // Space between the title and legend
      // },
      // border: {
      //   show: true, // Display the legend border
      //   radius: 5, // Border radius
      //   width: 1, // Border width
      //   strokeColor: "#000", // Border color
      //   offsetX: 0,
      //   offsetY: 0,
      // },
    },
    yaxis: {
      title: {
        text: "Spend in $",
        style: {
          fontSize: "1rem",
          color: "#3B3B3B",
          width: "1rem", // Set the width of the title

          fontWeight: "500",
        },
      },
    },
  };
  const series_Grouped = [
    {
      name: "22/10-28/10",
      data: chartData.map((data) => data.pre_previous_week),
    },
    {
      name: "29/10-4/11",
      data: chartData.map((data) => data.previous_week),
    },
    {
      name: "05/11-11/11",
      data: chartData.map((data) => data.current_week),
    },
  ];
  const chartDataapp = Array.isArray(reportApplication)
    ? reportApplication.map((data) => ({
        application: data.application,
        pre_previous_week: parseInt(data.pre_previous_week),
        previous_week: parseInt(data.previous_week),
        current_week: parseInt(data.current_week),
      }))
    : [];
  const options_WeeklySpend = {
    chart: {
      id: "weeklyspendapp",
      type: "bar",
      height: 250,
    },
    plotOptions: {
      bar: {
        borderRadius: 5,
        horizontal: false,
        dataLabels: {
          position: "top",
        },
      },
    },
    dataLabels: {
      enabled: true,
      position: "top",
      style: {
        fontSize: "12px",
        colors: ["#3B3B3B"],
      },
      formatter: function (val) {
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
      offsetY: -20,
    },
    tooltip: {
      shared: true,
      intersect: false,
    },
    xaxis: {
      categories: chartDataapp.map((data) => data.application),
      title: {
        text: "Application",
        style: {
          fontSize: "1rem",
          color: "#3B3B3B",
          width: "1rem", // Set the width of the title

          fontWeight: "500",
        },
      },
    },
    title: {
      text: "Weekly spend by top 5 Application ($)",
      align: "center",
      //   width: "1rem",
      style: {
        fontSize: "15px",
        fontWeight: "500",
        color: "#3B3B3B",
      },
    },
    legend: {
      position: "top",
    },
    yaxis: {
      title: {
        text: "Spend",
        style: {
          fontSize: "1rem",
          color: "#3B3B3B",
          width: "1rem", // Set the width of the title

          fontWeight: "500",
        },
      },
    },
  };
  const series_WeeklySpend = [
    {
      name: "22/10-28/10",
      data: chartDataapp.map((data) => data.pre_previous_week),
    },
    {
      name: "29/10-4/11",
      data: chartDataapp.map((data) => data.previous_week),
    },
    {
      name: "05/11-11/11",
      data: chartDataapp.map((data) => data.current_week),
    },
  ];

  const getDataUri = () => {
    apexcharts.exec("threemonthspend", "dataURI").then(({ imgURI }) => {
      setChartImageURI(imgURI);
    });
  };
  const threeWeeks = () => {
    apexcharts.exec("threeweekspend", "dataURI").then(({ imgURI }) => {
      setChartImageweek(imgURI);
    });
  };
  // const groupedBarchart = () => {
  //   apexcharts.exec("groupedbar", "dataURI").then(({ imgURI }) => {
  //     setGroupedbarIamge(imgURI);
  //   });
  // };
  // const weeklyspendapplication = () => {
  //   apexcharts.exec("weeklyspendapp", "dataURI").then(({ imgURI }) => {
  //     setWeeklyspendTopapp(imgURI);
  //   });
  // };

  //kamalika
  const [chartImages, setChartImages] = useState({});
  const [appDatas, setAppData] = useState("");
  const generateBase64Images = () => {
    const images = {};

    environments.forEach((env, element, index) => {
      setTimeout(() => {
        const chartElement = document.getElementById(`chart-${env}`);
        if (chartElement) {
          apexcharts
            .exec(`applicationsummary-${env}`, "dataURI")
            .then(({ imgURI }) => {
              images[env] = imgURI; // Store images with their respective environment names
              setChartImages(images); // Update state once all images are generated
            });
        }
      }, 0);
    });
  };
  // const convertComponentToBase64 = () => {
  //   const component = document.getElementById("app");

  //   html2canvas(component).then((canvas) => {
  //     console.log("hi");
  //     const base64Image = canvas.toDataURL("image/png");

  //     console.log(base64Image);
  //     setAppData(base64Image)
  //   });
  // };
  const spendService = () => {
    apexcharts.exec("spendbyservice", "dataURI").then(({ imgURI }) => {
      setServiceSpend(imgURI);
    });
  };
  const applicationSpend = () => {
    apexcharts.exec("spendbyapp", "dataURI").then(({ imgURI }) => {
      setapplicationSpend(imgURI);
    });
  };
  // const appsummary = () => {
  //   apexcharts.exec("applicationsummary", "dataURI").then(({ imgURI }) => {
  //     setappSummary(imgURI);
  //   });
  // };
  const servicefourweekschart = () => {
    apexcharts.exec("spendbyservicefourweeks", "dataURI").then(({ imgURI }) => {
      setSpendFourweeks(imgURI);
    });
  };
  const applicationfourweekscharts = () => {
    apexcharts.exec("spendbyappfourweeks", "dataURI").then(({ imgURI }) => {
      setapplicationFourWeeks(imgURI);
    });
  };
  const buttonclick = () => {
    // const url = `/detailedreport?start=${selectedDate}&end=${endDate}`;
    // window.open(url, "_blank");
    navigate(`/detailedreport?start=${selectedDate}&end=${endDate}`);
  };
  const redirecttonextPage = () => {
    const url = `/reportpdf?start=${selectedDate}&end=${endDate}&chartImageURI=${chartImageURI}&chartImageweek=${chartImageweek}&serviceSpend=${serviceSpend}
   &applicationSpends=${applicationSpends}&spendFourWeeks=${spendFourWeeks}&applicationfourweeks=${applicationfourweeks}&chartImages=${chartImages}`;
  };
  // const [chartImagesBase64, setChartImagesBase64] = useState([]);
  // const captureCharts = async () => {
  //   const images = await Promise.all(
  //     environments.map(async (env) => {
  //       const environmentData = reportApplication.filter(
  //         (item) => item.environment === env && item.current_week !== "null"
  //       );

  //       const seriesData = environmentData.map((item) =>
  //         parseInt(item.current_week)
  //       );
  //       const labelsData = environmentData.map((item) => item.application);

  //       const options = {
  //         chart: {
  //           type: "donut",
  //           height: "250",
  //           width: "500",
  //           id: "applicationsummary",
  //         },
  //         labels: labelsData,
  //         title: {
  //           text: env,
  //           style: {
  //             fontWeight: "bold",
  //             fontFamily: undefined,
  //             color: "#ff6a2f",
  //           }, // Use environment name as the chart title
  //         },
  //         legend: {
  //           offsetY: 35,
  //         },
  //         responsive: [
  //           {
  //             breakpoint: 480,
  //             options: {
  //               chart: {
  //                 width: 200,
  //               },
  //               legend: {
  //                 position: "bottom",
  //               },
  //             },
  //           },
  //         ],
  //         tooltip: {
  //           enabled: true,
  //           theme: "light",
  //           y: {
  //             formatter: function (val) {
  //               return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  //             },
  //           },
  //           fillSeriesColor: false,
  //         },
  //       };

  //       const canvas = await html2canvas(
  //         <ReactApexChart
  //           options={options}
  //           series={seriesData}
  //           type="donut"
  //           width={560}
  //           height={250}
  //         />
  //       );
  //       const chartImageBase64 = canvas.toDataURL("image/png");
  //       return { env, chartImageBase64 };
  //     })
  //   );

  //   setChartImagesBase64(images);
  //   console.log(chartImagesBase64);
  // };
  const [showDownloadLink, setShowDownloadLink] = useState(false);
  const fetchDataAndPassURIs = () => {
    getDataUri();
    threeWeeks();
    spendService();
    applicationSpend();
    // appsummary();
    servicefourweekschart();
    applicationfourweekscharts();
    // captureCharts();
    generateBase64Images();
    setUrlimg(true);
    // await convertComponentToBase64();
    // setTriggerDownload(true);
    // groupedBarchart();
    // weeklyspendapplication();

    // if (
    //   chartImageURI &&
    //   chartImageweek &&
    //   serviceSpend &&
    //   applicationSpends &&
    //   appSummary &&
    //   spendFourWeeks &&
    //   applicationfourweeks
    // ) {
    //   console.log("data");
    //   setShowDownloadLink(true);
    //   generatePDF();
    // }
  };
  // const generatePDF = () => {
  //   const pdfBlob = new Blob(
  //     // [<MyDocument />],
  //     // <Rep
  //     //   imgURI={chartImageURI}
  //     //   imgURIWeek={chartImageweek}
  //     //   // imgURIGROUPED={groupedbarImage}
  //     //   tableENVIRONMENT={reportWeekwiseSum}
  //     //   // imgURIWeeklyTopapp={weeklyspendTopapp}
  //     //   imgSpend={serviceSpend}
  //     //   imgApplication={applicationSpends}
  //     //   tableDATA={reportweekwiseappsumservice}
  //     //   tableWeekDec={reportweekwiseappsumdec}
  //     //   tableweekINC={reportweekwiseappsuminc}
  //     //   applicationData={reportweekwiseappsum}
  //     //   applicationweeklydetailed={reportappwise}
  //     //   environmentweeklydetailed={reportweekenvironment}
  //     //   serviceDetailed={reportDetailedService}
  //     //   overallsummary={overall}
  //     //   appSummary={appSummary}
  //     //   startDate={selectedDate}
  //     //   endDate={endDate}
  //     //   dates={date}
  //     //   spendFourWeeks={spendFourWeeks}
  //     //   applicationfourweeks={applicationfourweeks}
  //     //   company_logo={company_logo}
  //     // />,

  //     { type: "application/pdf" }
  //   );
  //   saveAs(pdfBlob, "my_document.pdf");
  // };

  const [display, setDisplay] = useState(false);
  const navigate = useNavigate();
  const displayDetailed = () => {
    if (selectedDate && endDate) {
      setDisplay(true);
      console.log(selectedDate);
    } else {
      console.log("no dates");
      setDisplay(false);
    }
    // if (startDate && endDate) {
    //   location.push({
    //     pathname: "/costdemaze/report/detailedreport",
    //     state: {
    //       startDate: startDate,
    //       endDate: endDate,
    //     },
    //   });
    // } else {
    //   // Handle error or show a message if dates are not selected properly
    // }
    // console.log("kamalika");
    // <Link
    //   to={{
    //     pathname: "/detailedreport",
    //     search: `?startDate=${encodeURIComponent(
    //       startDate
    //     )}&endDate=${encodeURIComponent(endDate)}`,
    //   }}
    // ></Link>;
  };
  const [chartImageURI, setChartImageURI] = useState("");
  const [chartImageweek, setChartImageweek] = useState("");
  const [serviceSpend, setServiceSpend] = useState("");
  const [applicationSpends, setapplicationSpend] = useState("");
  const [reportWeekwiseSum, setReportweekwisesum] = useState("");
  const [appSummary, setappSummary] = useState("");
  const [spendFourWeeks, setSpendFourweeks] = useState("");
  const [applicationfourweeks, setapplicationFourWeeks] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [spinner, setSpinner] = useState(true);
  console.log(isLoading);
  console.log(reportWeekwiseSum);
  let keys = [];
  let seriesData = [];
  console.log(chartImageURI);
  const monthdata = reportweek[0];
  if (monthdata && Object.keys(monthdata).length > 0) {
    keys = Object.keys(reportweek[0]);
    seriesData = Object.values(monthdata).map((value) => parseFloat(value));
    //.log(keys);
    //.log(seriesData);
  } else {
    //.log("no data available");
  }
  const formattedData = seriesData.map((value) =>
    value.toLocaleString("en-US")
  );

  //.log(formattedData);
  // const keys = Object.keys(reportweek[0]);
  // const values = Object.values(reportweek[0]);
  // //.log(keys);
  // //.log(values);
  const startDate = new Date(selectedDate);
  const end_Date = new Date(endDate);

  const oneWeek = 7 * 24 * 60 * 60 * 1000;

  // // Calculate the start and end dates for previous week and pre-previous week
  const prevWeekStartDate = new Date(startDate.getTime() - oneWeek);
  const prevWeekEndDate = new Date(end_Date.getTime() - oneWeek);

  const prePrevWeekStartDate = new Date(startDate.getTime() - 2 * oneWeek);
  const prePrevWeekEndDate = new Date(end_Date.getTime() - 2 * oneWeek);
  const threeweekbeforeStart = new Date(startDate.getTime() - 3 * oneWeek);
  const threeweekbeforeEnd = new Date(end_Date.getTime() - 3 * oneWeek);
  const lastMonthSpend = new Date(startDate.getTime() - 7 * oneWeek);
  const lastMonthendSpend = new Date(end_Date.getTime() - 4 * oneWeek);
  // const formatDate = (date) => {
  //   const day = date.getDate().toString().padStart(2, "0");
  //   const month = (date.getMonth() + 1).toString().padStart(2, "0");
  //   return `${day}/${month}`;
  // };

  // const prevWeekRange = `${formatDate(prevWeekStartDate)} - ${formatDate(
  //   prevWeekEndDate
  // )}`;
  // const prePrevWeekRange = `${formatDate(prePrevWeekStartDate)} - ${formatDate(
  //   prePrevWeekEndDate
  // )}`;
  // const currentWeekRange = `${formatDate(startDate)}-${formatDate(end_Date)}`;
  const formatDate = (date) => {
    const day = date.getDate();
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const month = monthNames[date.getMonth()];

    // Function to add suffix to day of the month
    const addSuffix = (day) => {
      if (day > 3 && day < 21) return `${day}th`;
      switch (day % 10) {
        case 1:
          return `${day} st`;
        case 2:
          return `${day}nd`;
        case 3:
          return `${day}rd`;
        default:
          return `${day}th`;
      }
    };

    const formattedDay = addSuffix(day);

    return `${formattedDay} ${month}`;
  };

  const threeweekRange = `${formatDate(threeweekbeforeStart)}-${formatDate(
    threeweekbeforeEnd
  )}`;
  const prevWeekRange = `${formatDate(prevWeekStartDate)} - ${formatDate(
    prevWeekEndDate
  )}`;
  const prePrevWeekRange = `${formatDate(prePrevWeekStartDate)} - ${formatDate(
    prePrevWeekEndDate
  )}`;
  const currentWeekRange = `${formatDate(startDate)} - ${formatDate(end_Date)}`;

  const options_weeks = {
    chart: {
      id: "threeweekspend",
      type: "bar",
      height: 350,
      stacked: "true",
    },
    plotOptions: {
      bar: {
        borderRadius: 5,
        horizontal: false,
        dataLabels: {
          position: "top",
          enabled: true,
          formatter: function (val) {
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          },
          total: {
            enabled: true,
            formatter: function (w) {
              return w.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            },
            offsetX: 0,
            style: {
              fontSize: "13px",
              fontWeight: 600,
            },
          },
        },
      },
    },
    dataLabels: {
      enabled: true,
      position: "bottom",
      style: {
        fontSize: "12px",
        colors: ["#3B3B3B"],
      },
      formatter: function (val) {
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
      offsetY: -1,
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: [
        threeweekRange,
        prePrevWeekRange,
        prevWeekRange,
        currentWeekRange,
      ],
      labels: {
        rotate: 0,
        style: {
          fontSize: "10px",
          colors: ["#3B3B3B"],
        },
      },
    },
    title: {
      text: "Last Four Weeks Spend ($)",
      align: "center",
      //   width: "1rem",
      style: {
        fontSize: "15px",
        fontWeight: "500",
        color: "#3B3B3B",
      },
    },
    yaxis: {
      title: {
        text: "Spend in $",
        style: {
          fontSize: "1rem",
          color: "#3B3B3B",
          width: "1rem", // Set the width of the title

          fontWeight: "500",
        },
      },
      labels: {
        formatter: function (value) {
          // return Math.round(value);
          return value.toLocaleString("en-US"); 
        },
      },
    },
    colors: ["#FF6A2F", "#A7EE7C", "#40A2B8"],
    tooltip: {
      enabled: true,
      shared: true, // Enables a shared tooltip
      intersect: false,
      x: {
        formatter: function (val) {
          return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
      },
      y: {
        formatter: function (value) {
          return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
      },
    },
    colors: ["#9FDEF1", "#2A5D78"],
  };
  const series_weeks = reportweekwise.map((item) => {
    return {
      name: item.environment,
      data: [
        parseInt(item.fourth_week),
        parseInt(item.pre_previous_week),
        parseInt(item.previous_week),
        parseInt(item.current_week),
      ],
    };
  });
  const envi = [
    ...new Set(reportweekwiseappsuminc.map((item) => item.environment)),
  ];
  //.log(envi);
  const appli = envi.map((item) => item.application);
  //.log(appli);
  const nonProdData = reportweekwiseappsuminc.filter(
    (item) => item.environment === "Non-Production"
  );
  const prodData = reportweekwiseappsuminc.filter(
    (item) => item.environment === "Production"
  );

  //api

  const environmentData = {};
  reportweekwiseappsuminc.forEach((item) => {
    const { application, environment, current_week } = item;
    if (current_week && current_week !== "null") {
      if (!environmentData[environment]) {
        environmentData[environment] = [];
      }
      environmentData[environment].push({
        application,
        value: parseInt(current_week),
      });
    }
  });

  // Sort applications within each environment based on value (current week)
  Object.keys(environmentData).forEach((env) => {
    environmentData[env].sort((a, b) => b.value - a.value); // Sort by value
  });

  // Extract top 2 applications for each environment
  const series_nonprod = [];
  const labels = [];
  Object.keys(environmentData).forEach((env) => {
    if (environmentData[env].length >= 2) {
      series_nonprod.push(
        environmentData[env].slice(0, 2).map((app) => app.value)
      );
      labels.push(
        `${env}: ${environmentData[env][0].application}, ${environmentData[env][1].application}`
      );
    }
  });

  const options_nonprod = {
    chart: {
      id: "nonprod",
      type: "donut",
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
    labels: labels,
  };

  //api data
  let environments = [];
  if (Array.isArray(reportApplication)) {
    environments = [
      ...new Set(reportApplication.map((item) => item.environment)),
    ];
  }
  // Generate charts for each environment
  console.log(spinner);
  const charts = environments.map((env) => {
    // Filter data for the current environment
    const environmentData = reportApplication.filter(
      (item) => item.environment === env && item.current_week !== "null"
    );

    // Extract current week count for series and application names for labels
    const seriesData = environmentData.map((item) =>
      parseInt(item.current_week)
    );
    const labelsData = environmentData.map((item) => item.application);

    // ApexCharts options for the current environment

    const options = {
      chart: {
        type: "donut",
        height: "250",
        width: "500",
        id: `applicationsummary-${env}`,
      },
      labels: labelsData,
      title: {
        text: env,
        style: {
          fontWeight: "bold",
          fontFamily: undefined,
          color: "#ff6a2f",
        }, // Use environment name as the chart title
      },
      legend: {
        offsetY: 35,
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
      tooltip: {
        enabled: true,
        theme: "light",
        y: {
          formatter: function (val) {
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          },
        },
        fillSeriesColor: false,
      },
      // Other chart options...
    };
    const legendLabels = labelsData.map((label, index) => {
      const formattedSeries = seriesData[index].toLocaleString(); // Formatting number with commas
      return `${label} ($${formattedSeries})`;
    });

    // Update the 'labels' property in the chart options
    options.labels = legendLabels;
    const series = seriesData;
    const chartDiv = document.createElement("div");

    return (
      <>
        <div id={`chart-${env}`} key={env}>
          <Col span={24} key={env}>
            <Card className="report-cards">
              <ReactApexChart
                options={options}
                series={series}
                type="donut"
                width="510"
                height="250"
              />
            </Card>
          </Col>
        </div>
      </>
    );
  });

  // const series_nonprod = [44, 55, 41, 17, 15];
  useEffect(() => {
    dispatch(headercustomerlogo({ company_name }))
      .unwrap()
      .then(({ data }) => {
        var encodedStringAtoB = data.getCustomerLogo.platform_logo;
        var testimg = `data:image/svg+xml;base64,${encodedStringAtoB}`;
        console.log("test base64", testimg);
        setCompanyLogo(testimg);
      })
      .catch((err) => {
        //.log(err);
      });
  }, []);

  const onChange = (date) => {
    //.log(date);
    const dates = new Date(date.$d); // Convert to Date object
    const formattedDate = `${dates.getFullYear()}-${(dates.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${dates.getDate().toString().padStart(2, "0")}`;
    //.log(formattedDate);
    setSelectedDate(formattedDate);
  };

  const onChangeEnd = (date) => {
    //.log(date);
    const dates = new Date(date.$d);

    const formattedDate = `${dates.getFullYear()}-${(dates.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${dates.getDate().toString().padStart(2, "0")}`;
    //.log(formattedDate);

    setEndDate(formattedDate);
  };

  // const handleDateChange = (dates, dateStrings) => {
  //   if (dates) {
  //     console.log("Start Date:", dates[0]);
  //     console.log("End Date:", dates[1]);
  //     console.log("Start Date String:", dateStrings[0]);
  //     console.log("End Date String:", dateStrings[1]);
  //     setSelectedDate(dateStrings[0]);
  //     setEndDate(dateStrings[1]);
  //   }
  // };
  const onChanges = (date, dateString) => {
    setChartImageURI("");
    setDates(true);
    setShowSpinner(false);
    console.log("Start Date:", dateString);
    setSelectedDate(dateString);
    // Calculate end date by adding 7 days to the selected start date
    const endDate = moment(dateString, "YYYY-MM-DD")
      .add(6, "days")
      .format("YYYY-MM-DD");

    setEndDate(endDate);
    console.log("End Date:", endDate);
  };

  let service_options, service_series;
  if (reportService && Array.isArray(reportService)) {
    const report_service_labels = reportService.map((item) => item.service);
    const report_service_series = reportService.map((item) =>
      parseFloat(item.total_spend)
    );
    console.log(report_service_labels);
    console.log(report_service_series);

    service_options = {
      chart: {
        type: "donut",
        id: "spendbyservice",
      },

      noData: {
        text: "No Data",
        align: "center",
        verticalAlign: "middle",
        offsetX: 0,
        offsetY: 0,
        style: {
          color: "#fff",
          fontSize: "20px",
          fontFamily: "Poppins",
        },
      },
      tooltip: {
        enabled: true,
        theme: "light",
        y: {
          formatter: function (value) {
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          },
        },
        fillSeriesColor: false,
        // custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        //   const label = w.globals.labels[dataPointIndex];
        //   const value = w.globals.series[seriesIndex][dataPointIndex];
        //   return `<div>${label}: ${value.toLocaleString()}</div>`;
        // },
      },

      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: false,
              value: {
                show: false,
                fontSize: "23px",
                fontFamily: "Helvetica, Arial, sans-serif",
                fontWeight: 600,
                color: "#ff6a2f",
                offsetY: -5,
                formatter: function (val) {
                  return "";
                },
              },
            },
          },
        },
      },
      dataLabels: {
        enabled: true,
      },
      colors: [
        "#9FDEF1",
        "#2A5D78",
        "#F6AA54",
        "#A7EE7C",
        "#F65454",
        "#057EC1",
        "#230B34",
        "#FE632B",
      ],
      labels: report_service_labels,

      stroke: {
        show: false,
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            xaxis: {
              labels: {
                style: {
                  colors: "#000",
                },
              },
            },
            yaxis: {
              labels: {
                style: {
                  colors: "#000",
                },
              },
            },
            // dataLabels: {
            //   formatter: function (val) {
            //     // Remove the percentage symbol from the labels
            //     return val.toFixed(0);
            //   },
            // },
            tooltip: {
              enabled: true,
              theme: "light",
              y: {
                formatter: function (value) {
                  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                },
              },
              fillSeriesColor: true, // You can use 'light' or 'dark' theme
            },
            legend: {
              position: "bottom",
              labels: {
                colors: "#ffffff",
              },
            },
          },
        },
      ],
    };
    const legendLabels = report_service_labels.map((label, index) => {
      const formattedSeries = report_service_series[index].toLocaleString(); // Formatting number with commas
      return `${label} ($${formattedSeries})`;
    });

    // Update the 'labels' property in the chart options
    service_options.labels = legendLabels;
    service_series = report_service_series;

    // Now use service_options and service_series in your chart component
  } else {
    // console.error("reportService is not an array:", reportService);
  }

  // service past four weeks
  let options_servicelastmonth, series_lastmonth_service;
  if (servicepastfourweeks && Array.isArray(servicepastfourweeks)) {
    const service_four_weeks_labels = servicepastfourweeks.map(
      (item) => item.service
    );
    const service_fourt_weeks_series = servicepastfourweeks.map((item) =>
      parseFloat(item.total_spend)
    );

    options_servicelastmonth = {
      chart: {
        type: "donut",
        id: "spendbyservicefourweeks",
      },

      noData: {
        text: "No Data",
        align: "center",
        verticalAlign: "middle",
        offsetX: 0,
        offsetY: 0,
        style: {
          color: "#fff",
          fontSize: "20px",
          fontFamily: "Poppins",
        },
      },
      tooltip: {
        enabled: true,
        theme: "light",
        y: {
          formatter: function (value) {
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          },
        },
        fillSeriesColor: false,
        // custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        //   const label = w.globals.labels[dataPointIndex];
        //   const value = w.globals.series[seriesIndex][dataPointIndex];
        //   return `<div>${label}: ${value.toLocaleString()}</div>`;
        // },
      },

      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: false,
              value: {
                show: false,
                fontSize: "23px",
                fontFamily: "Helvetica, Arial, sans-serif",
                fontWeight: 600,
                color: "#ff6a2f",
                offsetY: -5,
                formatter: function (val) {
                  return "";
                },
              },
            },
          },
        },
      },
      dataLabels: {
        enabled: true,
      },
      colors: [
        "#9FDEF1",
        "#2A5D78",
        "#F6AA54",
        "#A7EE7C",
        "#F65454",
        "#057EC1",
        "#230B34",
        "#FE632B",
      ],
      labels: service_four_weeks_labels,

      stroke: {
        show: false,
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            xaxis: {
              labels: {
                style: {
                  colors: "#000",
                },
              },
            },
            yaxis: {
              labels: {
                style: {
                  colors: "#000",
                },
              },
            },
            // dataLabels: {
            //   formatter: function (val) {
            //     // Remove the percentage symbol from the labels
            //     return val.toFixed(0);
            //   },
            // },
            tooltip: {
              enabled: true,
              theme: "light",
              y: {
                formatter: function (value) {
                  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                },
              },
              fillSeriesColor: true, // You can use 'light' or 'dark' theme
            },
            legend: {
              position: "bottom",
              labels: {
                colors: "#ffffff",
              },
            },
          },
        },
      ],
    };
    const legendLabels = service_four_weeks_labels.map((label, index) => {
      const formattedSeries =
        service_fourt_weeks_series[index].toLocaleString(); // Formatting number with commas
      return `${label} ($${formattedSeries})`;
    });

    // Update the 'labels' property in the chart options
    options_servicelastmonth.labels = legendLabels;
    series_lastmonth_service = service_fourt_weeks_series;

    // Now use service_options and service_series in your chart component
  } else {
    // console.error("reportService is not an array:", reportService);
  }
  let application_lastmonth_options, application_lastmonth_series;
  if (applicationFourweeks && Array.isArray(applicationFourweeks)) {
    const applicationfourweeks_labels = applicationFourweeks.map(
      (item) => item.application
    );
    const applicationfourweeks_series = applicationFourweeks.map((item) =>
      parseFloat(item.total_spend)
    );
    application_lastmonth_options = {
      chart: {
        id: "spendbyappfourweeks",
        type: "donut",
      },
      // dataLabels: {
      //   formatter: function (val) {
      //     return val.toFixed(0);
      //   },
      // },
      noData: {
        text: "No Data",
        align: "center",
        verticalAlign: "middle",
        offsetX: 0,
        offsetY: 0,
        style: {
          color: "#fff",
          fontSize: "20px",
          fontFamily: "Poppins",
        },
      },
      tooltip: {
        enabled: true,
        theme: "light",
        y: {
          formatter: function (value) {
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          },
        },
        fillSeriesColor: false, // You can use 'light' or 'dark' theme
      },

      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: false,
              value: {
                show: false,
                fontSize: "23px",
                fontFamily: "Helvetica, Arial, sans-serif",
                fontWeight: 600,
                color: "#ff6a2f",
                offsetY: -5,
                formatter: function (val) {
                  return "";
                },
              },
            },
          },
        },
      },
      dataLabels: {
        enabled: true,
      },
      colors: [
        "#9FDEF1",
        "#2A5D78",
        "#F6AA54",
        "#A7EE7C",
        "#F65454",
        "#057EC1",
        "#230B34",
        "#FE632B",
      ],
      labels: applicationfourweeks_labels,
      stroke: {
        show: false,
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    };
    const legendLabels = applicationfourweeks_labels.map((label, index) => {
      const formattedSeries =
        applicationfourweeks_series[index].toLocaleString(); // Formatting number with commas
      return `${label} ($${formattedSeries})`;
    });

    // Update the 'labels' property in the chart options
    application_lastmonth_options.labels = legendLabels;

    application_lastmonth_series = applicationfourweeks_series;
  } else {
    // console.error("reportService is not an array:", applicationService);
  }
  let application_options, application_series;
  if (applicationService && Array.isArray(applicationService)) {
    const application_labels = applicationService.map(
      (item) => item.application
    );
    const application_series_legend = applicationService.map((item) =>
      parseFloat(item.total_spend)
    );
    application_options = {
      chart: {
        id: "spendbyapp",
        type: "donut",
      },
      // dataLabels: {
      //   formatter: function (val) {
      //     return val.toFixed(0);
      //   },
      // },
      noData: {
        text: "No Data",
        align: "center",
        verticalAlign: "middle",
        offsetX: 0,
        offsetY: 0,
        style: {
          color: "#fff",
          fontSize: "20px",
          fontFamily: "Poppins",
        },
      },
      tooltip: {
        enabled: true,
        theme: "light",
        y: {
          formatter: function (value) {
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          },
        },
        fillSeriesColor: false, // You can use 'light' or 'dark' theme
      },

      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: false,
              value: {
                show: false,
                fontSize: "23px",
                fontFamily: "Helvetica, Arial, sans-serif",
                fontWeight: 600,
                color: "#ff6a2f",
                offsetY: -5,
                formatter: function (val) {
                  return "";
                },
              },
            },
          },
        },
      },
      dataLabels: {
        enabled: true,
      },
      colors: [
        "#9FDEF1",
        "#2A5D78",
        "#F6AA54",
        "#A7EE7C",
        "#F65454",
        "#057EC1",
        "#230B34",
        "#FE632B",
      ],
      labels: application_labels,
      stroke: {
        show: false,
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    };
    const legendLabels = application_labels.map((label, index) => {
      const formattedSeries = application_series_legend[index].toLocaleString(); // Formatting number with commas
      return `${label} ($${formattedSeries})`;
    });

    // Update the 'labels' property in the chart options
    application_options.labels = legendLabels;

    application_series = application_series_legend;
  } else {
    // console.error("reportService is not an array:", applicationService);
  }
  const currentDates = new Date();
  const currentDate_new = currentDates.getDate();

  let startDates;
  if (currentDate_new <= 3) {
    const previousMonth = new Date(
      currentDates.getFullYear(),
      currentDates.getMonth() - 1,
      1
    );
    const year =
      previousMonth.getMonth() === 11
        ? currentDates.getFullYear() - 1
        : currentDates.getFullYear();
    startDates = new Date(year, previousMonth.getMonth(), 1);
  } else {
    startDates = new Date(
      currentDates.getFullYear(),
      currentDates.getMonth(),
      1
    );
  }

  // Format the date as YYYY-MM-DD for the start date
  const stdate = `${startDates.getFullYear()}-${(startDates.getMonth() + 1)
    .toString()
    .padStart(2, "0")}-01`;

  console.log(date);

  console.log(stdate, "startdate");

  useEffect(() => {
    scrollToElement();
  }, []);

  const scrollToElement = () => {
    const hash = window.location.hash;
    const hashString = hash.slice(1);
    const element = document.getElementById(hashString);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };
  let isDisabled =
    reportweek.length &&
    reportweekwise.length &&
    reportweekwiseappsum.length &&
    reportweekenvironment.length &&
    reportDetailedService.length &&
    overall.length &&
    reportweekwiseappsuminc.length &&
    reportweekwiseappsumdec.length
      ? false
      : true;
  return (
    <div>
      {/* //kamalika */}
      <Row>
        <Col span={1}>
          <CostimizeSidebar />
        </Col>
        <Col span={23}>
          <Reportdata
            setReportmonth={setReportmonth}
            setReportweek={setReportweek}
            setReportweekwise={setReportweekwise}
            setReportweekwisesum={setReportweekwisesum}
            setReportweekwiseappsum={setReportweekwiseappsum}
            setReportweekwiseappsuminc={setReportweekwiseappsuminc}
            setReportweekwiseappsumdec={setReportweekwiseappsumdec}
            setReportweekwiseappsumservice={setReportweekwiseappsumservice}
            setReportweekappservice={setReportweekappservice}
            setReportweekenvironment={setReportweekenvironment}
            setReportdetailedservice={setReportdetailedservice}
            setOverall={setOverall}
            setReportApplication={setReportApplication}
            selectedDate={selectedDate}
            endDate={endDate}
            setReportService={setReportService}
            setApplicationService={setApplicationService}
            setServiceFourWeeks={setServiceFourWeeks}
            setApplicationFourweeks={setApplicationFourweeks}
            setIsLoading={setIsLoading}
            setSpinner={setSpinner}
          />
          {/* kamalika */}
          <div className="white-bg">
            {/* <div class="horizontal-bar"></div>
        <div className="page1">
          <div>
            <div className="headingcenter">
              <Row className="headerrow">
                <Col>
                  <img src={CHLOGO} alt="image" />
                </Col>
              </Row>
              <Row className="subheadingrow">
                <h3 className="subheadingtab">
                  Weekly Consumption Report<br></br>
                  <span className="texts-center">
                    {" "}
                    for <span className="companyname">{companyName}</span>
                  </span>
                </h3>
              </Row>
            </div>
          </div>
        </div> */}
            {/* kamalika */}
            {urlimg ? (
              <>
                {" "}
                {chartImageURI ? (
                  <>
                    <Row>
                      <Col
                        span={24}
                        style={{ display: "flex", justifyContent: "end" }}
                      >
                        <Button
                          className="previous-btn"
                          onClick={() => setUrlimg(false)}
                        >
                          Back to Previous Page
                        </Button>
                      </Col>
                    </Row>

                    <br />
                    <Row>
                      <Rep
                        imgURI={chartImageURI}
                        imgURIWeek={chartImageweek}
                        // imgURIGROUPED={groupedbarImage}
                        tableENVIRONMENT={reportWeekwiseSum}
                        // imgURIWeeklyTopapp={weeklyspendTopapp}
                        imgSpend={serviceSpend}
                        imgApplication={applicationSpends}
                        tableDATA={reportweekwiseappsumservice}
                        tableWeekDec={reportweekwiseappsumdec}
                        tableweekINC={reportweekwiseappsuminc}
                        applicationData={reportweekwiseappsum}
                        applicationweeklydetailed={reportappwise}
                        environmentweeklydetailed={reportweekenvironment}
                        serviceDetailed={reportDetailedService}
                        overallsummary={overall}
                        // appSummary={appSummary}
                        startDate={selectedDate}
                        endDate={endDate}
                        dates={date}
                        spendFourWeeks={spendFourWeeks}
                        applicationfourweeks={applicationfourweeks}
                        company_logo={company_logo}
                        chartImages={chartImages}
                        // appDatas={appDatas}
                      />
                    </Row>
                  </>
                ) : (
                  ""
                )}
              </>
            ) : (
              <>
                {" "}
                <div className="page">
                  <div class="horizontal-bar"></div>
                  <div className="scroll">
                    <Row>
                      <Col span={12}>
                        <Button
                          className="display-button"
                          onClick={() => buttonclick()}
                        >
                          Display Detailed Report
                        </Button>
                      </Col>
                      <Col span={12} className="d-flex justify-content-end">
                        {/* {showDownloadLink && (
              <PDFDownloadLink
                document={
                  <Document>
                    <Page>
                      <Test
                        serviceDetailed={reportDetailedService}
                        startDate={selectedDate}
                        endDate={endDate}
                      />
                    </Page>
                  </Document>
                }
                fileName="generated.pdf"
              >
                {({ blob, url, loading, error }) =>
                  loading ? "Loading document..." : "Download PDF"
                }
              </PDFDownloadLink>
            )} */}

                        <Button
                          disabled={isDisabled}
                          className={
                            isDisabled ? "opacity-50 yes-btn" : " yes-btn"
                          }
                          onClick={() => {
                            fetchDataAndPassURIs();
                          }}
                        >
                          Generate PDF
                        </Button>
                      </Col>
                    </Row>
                    <br></br>
                    <Row>
                      <Col span={16}>
                        {company_logo && (
                          <img
                            className="costimize_header_logo"
                            src={company_logo}
                          />
                        )}
                      </Col>
                      <Col span={8}>
                        <h6 className="headerheading">
                          Overall Summary & Spend Trend as of {date}
                        </h6>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={16}>
                        <h5 className="boldheading">
                          Weekly Consumption Report
                        </h5>
                        <br></br>

                        <pre className="dateheading">
                          Start Date: {selectedDate}
                          <br></br>End Date: &nbsp; &nbsp;{endDate}
                        </pre>
                      </Col>
                      <Col span={8}>
                        {/* {isLoading ? (
                <div className=" d-flex justify-content-center align-items-center spinner-height">
                  <Spin />
                </div>
              ) : ( */}
                        <OverallSummaryUI
                          spendoverall={overall}
                          stdate={stdate}
                        />
                        {/* )} */}
                      </Col>
                    </Row>
                    <hr className="ruler"></hr>
                    <Row className="chartsrow">
                      <Col span={18}>
                        {/* <button
                className="yes-button"
                onClick={() => displayDetailed()}
              >
                Display Detailed Report
              </button> */}
                      </Col>

                      <Col span={6}>
                        <DatePicker
                          onChange={onChanges}
                          className="daterangefilter"
                          format="YYYY-MM-DD"
                        />
                      </Col>
                    </Row>
                    <Row className="chartsrow">
                      <Col span={12}>
                        <h4 className="chartheading">
                          Last Six Months Spend Trend
                        </h4>
                      </Col>
                      <Col span={12}>
                        <h4 className="chartheading">
                          Last Four Weeks Spend Trend
                        </h4>
                      </Col>
                    </Row>
                    <Row gutter={[16, 16]} className="chartsrow">
                      <Col span={12}>
                        <Card className="report-cards">
                          {isLoading ? (
                            <div className=" d-flex justify-content-center align-items-center spinner-height">
                              <Spin />
                            </div>
                          ) : (
                            <ThreeMonthsSpend
                              reportformonth={reportmonth}
                              stdate={stdate}
                            />
                          )}
                          <p className="para">
                            The dotted line Represents the current Month
                          </p>
                        </Card>
                      </Col>
                      <Col span={12}>
                        <Card className="report-cards">
                          {isLoading ? (
                            <div className=" d-flex justify-content-center align-items-center spinner-height">
                              <Spin />
                            </div>
                          ) : (
                            <ReactApexChart
                              options={options_weeks}
                              series={series_weeks}
                              height={250}
                              type="bar"
                            />
                          )}
                        </Card>
                      </Col>
                    </Row>
                    <Row className="chartsrow">
                      <Col span={12}>
                        <h4 className="tableheading">
                          Spend By Service for Previous Four Weeks ({" "}
                          {formatDate(lastMonthSpend).toString()} -
                          {formatDate(lastMonthendSpend).toString()})
                        </h4>
                      </Col>
                      <Col span={12}>
                        <h4 className="tableheading">
                          Spend By Service for Current Four Weeks (
                          {formatDate(threeweekbeforeStart).toString()} -
                          {formatDate(end_Date).toString()})
                        </h4>
                      </Col>
                    </Row>
                    <Row className="chartsrow" gutter={[16, 16]}>
                      <Col span={12}>
                        <Card className="report-cards">
                          {
                            isLoading ? (
                              <div className="d-flex justify-content-center align-items-center spinner-height">
                                <Spin />
                              </div>
                            ) : options_servicelastmonth &&
                              series_lastmonth_service ? (
                              <div id="chart">
                                <ReactApexChart
                                  options={options_servicelastmonth}
                                  series={series_lastmonth_service}
                                  type="donut"
                                  height={250} // Notice the curly braces to set a dynamic height
                                />
                              </div>
                            ) : null // Render nothing if options or series are not available
                          }
                        </Card>
                      </Col>
                      <Col span={12}>
                        <Card className="report-cards">
                          {/* {service_options && service_series ? ( // Check if both variables are defined
                  <div id="chart">
                    <ReactApexChart
                      options={service_options}
                      series={service_series}
                      type="donut"
                      height="250"
                    />
                  </div>
                ) : (
                  ""
                )} */}
                          {isLoading ? (
                            <div className=" d-flex justify-content-center align-items-center spinner-height">
                              <Spin />
                            </div>
                          ) : service_options && service_series ? (
                            <div id="chart">
                              <ReactApexChart
                                options={service_options}
                                series={service_series}
                                type="donut"
                                height="250"
                              />
                            </div>
                          ) : null}
                        </Card>
                      </Col>
                    </Row>

                    <Row className="chartsrow">
                      <Col span={12}>
                        <h4 className="tableheading">
                          Spend By Application for Previous Four Weeks (
                          {formatDate(lastMonthSpend).toString()} -
                          {formatDate(lastMonthendSpend).toString()})
                        </h4>
                      </Col>
                      <Col span={12}>
                        <h4 className="tableheading">
                          Spend By Application for Current Four Weeks (
                          {formatDate(threeweekbeforeStart).toString()} -
                          {formatDate(end_Date).toString()})
                        </h4>
                      </Col>
                    </Row>
                    <Row className="chartsrow" gutter={[16, 16]}>
                      <Col span={12}>
                        <Card className="report-cards">
                          {application_lastmonth_options &&
                          application_lastmonth_series ? (
                            <div id="chart">
                              {isLoading ? (
                                <div className=" d-flex justify-content-center align-items-center spinner-height">
                                  <Spin />
                                </div>
                              ) : (
                                <ReactApexChart
                                  options={application_lastmonth_options}
                                  series={application_lastmonth_series}
                                  type="donut"
                                  height="250"
                                />
                              )}
                            </div>
                          ) : (
                            ""
                          )}
                        </Card>
                      </Col>
                      <Col span={12}>
                        <Card className="report-cards">
                          {application_options && application_series ? (
                            <div id="chart">
                              {" "}
                              {isLoading ? (
                                <div className=" d-flex justify-content-center align-items-center spinner-height">
                                  <Spin />
                                </div>
                              ) : (
                                <ReactApexChart
                                  options={application_options}
                                  series={application_series}
                                  type="donut"
                                  height="250"
                                />
                              )}
                            </div>
                          ) : (
                            ""
                          )}
                        </Card>
                      </Col>
                    </Row>
                    <Row className="chartsrow">
                      <Col span={24}>
                        <h4 className="tableheading" id="env">
                          Environment Level Weekly Spend Summary
                        </h4>
                      </Col>
                    </Row>
                    {/* <Row gutter={[16, 16]} className="chartsrow" align={"middle"}>
            <Col span={24}>
              <Card className="report-cards">
                <ReactApexChart
                  options={options_Grouped}
                  series={series_Grouped}
                  height={250}
                  type="bar"
                />
              </Card>
            </Col>

          </Row> */}
                    <Row
                      gutter={[16, 16]}
                      className="chartsrow"
                      align={"middle"}
                    >
                      <Col span={24}>
                        <ProductionTable
                          // envitable={reportWeekwiseSum}
                          selectedDate={selectedDate}
                          endDate={endDate}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
                <hr className="ruler footerruler"></hr>
                <Row className="footerrow">
                  <Col span={12}>
                    <p>© 1CloudHub Pvt Ltd. 2023. All Rights Reserved.</p>
                  </Col>
                  <Col span={10}>
                    <p>-1-</p>
                  </Col>
                  <Col span={2}>
                    <p>Confidential</p>
                  </Col>
                </Row>
                {/* kamalika */}
                <div className="page">
                  <div class="horizontal-bar"></div>
                  <div className="scroll">
                    <Row>
                      <Col>
                        {company_logo && (
                          <img
                            className="costimize_header_logo"
                            src={company_logo}
                          />
                        )}
                      </Col>
                      <Col span={6}>
                        <h5 className="boldheading">
                          Weekly Consumption Report
                        </h5>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={20}></Col>
                      <Col span={4}>
                        <a href="#" className="linktag">
                          Back to Home
                        </a>
                      </Col>
                    </Row>
                    <h4 className="subtableheading">
                      Application Level Weekly Spend Summary
                    </h4>

                    <Row className="chartsrow" gutter={[16, 16]}>
                      {charts}
                    </Row>

                    <Row className="chartsrow">
                      <Col span={12}>
                        <h5 className="subtableheading">
                          Top 5 Increase in Spend By Application ($)
                        </h5>
                      </Col>
                    </Row>
                    <Row gutter={[16, 16]} className="chartsrow">
                      <Col span={24}>
                        {spinner ? (
                          <div className=" d-flex justify-content-center align-items-center spinner-height">
                            <Spin />
                          </div>
                        ) : (
                          <ApplicationLvelSummarySpentMoreUI
                            applicationlevelspentmore={reportweekwiseappsuminc}
                            selectedDate={selectedDate}
                            endDate={endDate}
                          />
                        )}
                      </Col>
                    </Row>

                    <Row className="chartsrow">
                      <Col span={12}>
                        <h5 className="subtableheading">
                          Top 5 Decrease in Spend By Application ($)
                        </h5>
                      </Col>
                      {/* <Col span={12}>
              <h5 className="subtableheading">
                Application Level Weekly Spend Summary (Spent More) ($)
              </h5>
            </Col> */}
                    </Row>
                    <Row gutter={[16, 16]} className="chartsrow">
                      <Col span={24}>
                        {/* <ApplicationLvelSummarySpentMoreUI
                applicationlevelspentmore={reportweekwiseappsuminc}
              /> */}
                        {spinner ? (
                          <div className=" d-flex justify-content-center align-items-center spinner-height">
                            <Spin />
                          </div>
                        ) : (
                          <ApplicationLevelSummaryUI
                            applicationlevelspendless={reportweekwiseappsumdec}
                            selectedDate={selectedDate}
                            endDate={endDate}
                          />
                        )}
                      </Col>
                      {/* <Col span={12}>
              <ApplicationLvelSummarySpentMoreUI
                applicationlevelspentmore={reportweekwiseappsuminc}
              />
            </Col> */}
                    </Row>
                  </div>
                </div>
                <hr className="ruler footerruler"></hr>
                <Row className="footerrow">
                  <Col span={12}>
                    <p>© 1CloudHub Pvt Ltd. 2023. All Rights Reserved.</p>
                  </Col>
                  <Col span={10}>
                    <p>-2-</p>
                  </Col>
                  <Col span={2}>
                    <p>Confidential</p>
                  </Col>
                </Row>
                {/* kamalika */}
                <div className="page">
                  <div class="horizontal-bar"></div>
                  <div className="scroll">
                    <Row>
                      <Col span={18}>
                        {company_logo && (
                          <img
                            className="costimize_header_logo"
                            src={company_logo}
                          />
                        )}
                      </Col>
                      <Col span={6}>
                        <h5 className="boldheading">
                          Weekly Consumption Report
                        </h5>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={20}></Col>
                      <Col span={4}>
                        <a href="#" className="linktag">
                          Back to Home
                        </a>
                      </Col>
                    </Row>
                    <h4 className="subtableheading" id="all">
                      Application Level Weekly Spend Summary
                    </h4>

                    <Row className="chartsrow">
                      <Col span={24}>
                        {spinner ? (
                          <div className=" d-flex justify-content-center align-items-center spinner-height">
                            <Spin />
                          </div>
                        ) : (
                          <div id="app">
                            {" "}
                            <Appdata
                              applicationweekly={reportappwise}
                              selectedDate={selectedDate}
                              endDate={endDate}
                            />
                          </div>
                        )}
                      </Col>
                    </Row>
                  </div>
                </div>
                <hr className="ruler footerruler"></hr>
                <Row className="footerrow">
                  <Col span={12}>
                    <p>© 1CloudHub Pvt Ltd. 2023. All Rights Reserved.</p>
                  </Col>
                  <Col span={10}>
                    <p>-3-</p>
                  </Col>
                  <Col span={2}>
                    <p>Confidential</p>
                  </Col>
                </Row>
                {/* kamalika */}
                <div className="page">
                  <div class="horizontal-bar"></div>
                  <div className="scroll">
                    <Row>
                      <Col span={18}>
                        {company_logo && (
                          <img
                            className="costimize_header_logo"
                            src={company_logo}
                          />
                        )}
                      </Col>
                      <Col span={6}>
                        <h5 className="boldheading">
                          Weekly Consumption Report
                        </h5>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={20}></Col>
                      <Col span={4}>
                        <a href="#" className="linktag">
                          Back to Home
                        </a>
                      </Col>
                    </Row>
                    <h4 className="subtableheading">
                      {" "}
                      Service Level Weekly Spend Summary
                    </h4>
                    <Row className="chartsrow" id="service">
                      <Col span={24}>
                        {spinner ? (
                          <div className=" d-flex justify-content-center align-items-center spinner-height">
                            <Spin />
                          </div>
                        ) : (
                          <ServiceSummary
                            servicelevelWeekly={reportweekwiseappsumservice}
                            selectedDate={selectedDate}
                            endDate={endDate}
                          />
                        )}
                      </Col>
                    </Row>
                    {/* <Row className="chartsrow" id="service">
            <Col span={24}>
              <ServiceLevelWeeklySpendUI
                servicelevelweekly={reportDetailedService}
                selectedDate={selectedDate}
                endDate={endDate}
              />
            </Col>
          </Row> */}

                    <Row className="chartsrow">
                      <Col span={18}>
                        {/* <Link
              to={{
                pathname: "/detailedreport",
                search: `?startDate=${encodeURIComponent(
                  selectedDate
                )}&endDate=${encodeURIComponent(endDate)}`,
              }}
            > */}
                        {/* {dates ? (
                <Button className="yes-btn" onClick={() => buttonclick()}>
                  Display Detailed View
                </Button>
              ) : (
                " "
              )} */}
                      </Col>
                    </Row>
                  </div>

                  <hr className="ruler footerruler"></hr>
                  <Row className="footerrow">
                    <Col span={12}>
                      <p>© 1CloudHub Pvt Ltd. 2023. All Rights Reserved.</p>
                    </Col>
                    <Col span={10}>
                      <p>-4-</p>
                    </Col>
                    <Col span={2}>
                      <p>Confidential</p>
                    </Col>
                  </Row>

                  {/* <a
              href="./detailedreport"
              target="_blank"
              rel="noopener noreferrer"
            >
              Open in new tab
            </a> */}
                  {/* </Link> */}
                </div>
              </>
            )}

            {/* kamalika */}
            <div className="d-flex justify-content-end p-3 mb-5"></div>

            {/* {showDownloadLink && (
          <BlobProvider document={generatePDF()}>
            {({ blob, url, loading, error }) => {
              if (loading) {
                return "Loading document...";
              }
              if (error) {
                return "Error loading document";
              }
              // Display a download link when the blob is ready
              return (
                <a href={url} download="somename.pdf">
                  Download now!
                </a>
              );
            }}
          </BlobProvider>
        )} */}
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Report;
