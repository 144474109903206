import { useState } from "react";
import { useEffect } from "react";
import ReactApexChart from "react-apexcharts";

var colors = ["#F4D772", "#BB3D9F", "#5228AC"];

const BudgetPlanningAreaChart = (props) => {
  const [plannedchartrecords, setplannedChartRecords] = useState([]);
  const [actualchartrecords, setactualChartRecords] = useState([]);

  useEffect(() => {
    if (props.actualtablerecords) {
      const actual = props.actualtablerecords;
      var actual_list = [];
      for (let index = 0; index < 12; index++) {
        const actual_spend = actual?.filter(
          (datas) => new Date(datas.reportmonth).getMonth() + 1 === +index + +1
        );
        if (actual_spend.length > 0) {
          actual_list.push(Math.round(Number(actual_spend[0].spend)));
        } else {
          actual_list.push(0);
        }
      }
      //planned
      var planned_records = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      props.yearlistrecords.map((data) => {
        if (Number(data.budget_year) == new Date().getFullYear()) {
          planned_records = [
            data.budget_jan,
            data.budget_feb,
            data.budget_mar,
            data.budget_apr,
            data.budget_may,
            data.budget_jun,
            data.budget_jul,
            data.budget_aug,
            data.budget_sept,
            data.budget_oct,
            data.budget_nov,
            data.budget_dec,
          ];
        }
      });
      setplannedChartRecords(planned_records);
      setactualChartRecords(actual_list);
    }
  }, [props]);

  const options = {
    chart: {
      type: "area",
      height: 160,
      // offsetY: 5,
      toolbar: {
        show: false,
      },
    },
    stroke: {
      show: false,
      curve: "smooth",
      // lineCap: 'butt',
      // colors: ["#000","#000"],
      // width: 1,
      // dashArray: 0,
    },
    // markers: {
    //     show: false,
    //     size: [3,3,3],
    //     strokeColors: '#fff',
    //     strokeWidth: 0,
    //     strokeOpacity: 0,
    //     strokeDashArray: 0,
    //     fillOpacity: 0,
    //     discrete: [],
    //     shape: "circle",
    //     radius: 2,
    //     offsetX: 0,
    //     offsetY: 0,
    //     onClick: undefined,
    //     onDblClick: undefined,
    //     showNullDataPoints: true,
    //     hover: {
    //     size: undefined,
    //     sizeOffset: 3
    //     }
    // },
    grid: {
      show: false,
    },
    colors: ["#FF6A2F", "#FFF"],
    xaxis: {
      //   categories: spendtrandschartmonth,
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],

      axisBorder: {
        show: true,
        color: "#78909C",
        height: 1,
        width: "100%",
        offsetX: 0,
        offsetY: 0,
      },
      axisTicks: {
        show: false,
        borderType: "solid",
        color: "#78909C",
        height: 6,
        offsetX: 0,
        offsetY: 0,
      },
      labels: {
        offsetX: 0,
        offsetY: -6,
        style: {
          fontSize: "12px",
          fontWeight: 400,
          colors: "white",
        },
      },
      //   title: {
      //         text: "Month",
      //         rotate: -180,
      //         offsetX: 0,
      //         offsetY: -10,
      //         style: {
      //             color: undefined,
      //             fontSize: '12px',
      //             fontWeight: 500,
      //         },
      //     }
    },
    yaxis: {
      // tickAmount: 2,
      axisBorder: {
        show: true,
        color: "#78909C",
        offsetX: 0,
        offsetY: 0,
      },
      title: {
        text: "Spend in $",
       
        style: {
          color: "white",
          fontSize: "12px",
          fontWeight: 500,
        },
      },
      labels: {
        style: {
          fontSize: "8px",
          fontWeight: 400,
          colors: "white",
        },
        formatter: (num) => {
          return Math.abs(num) > 999
            ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
            : Math.sign(num) * Math.abs(num);
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: true,
      offsetY: -6,
      labels: {
        colors: "white",
      },
    },
    title: {
      text: "Budget Plan",
      align: "center",
      margin: 0,
      offsetX: 0,
      offsetY: 0,
      floating: false,
      style: {
        fontSize: "14px",
        fontWeight: "500",
        color: "white",
      },
    },
  };
  const series = [
    {
      name: "Planned",
      // data: [300,400,350,310,390,340,370,400,300,330,320,380]
      data: plannedchartrecords,
    },
    {
      name: "Actual",
      // data: [250,210,200,290,240,270,290,220,200,230,260,290]
      data: actualchartrecords,
    },
  ];
  return (
    <>
      <ReactApexChart
        options={options}
        series={series}
        type="area"
        height={220}
        className="trendchartv2"
      />
      {/* <FaRegCalendarAlt className='quick-glance-spend-chart-cal' /> */}
    </>
  );
};

export default BudgetPlanningAreaChart;
