import { Card, Spin } from "antd";

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCreditTrendApiData } from "../../../action/costimizedashboardAction";
import CardGraph from "./CardGraph";
import moment from "moment";

function CreditsApplied() {
  const [creditDataState, setCreditDataState] = useState("");
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();
  // var company_name = localStorage.getItem(
  //   "CognitoIdentityServiceProvider.company"
  // );

  var company_name = "all_cloud";
  const {
    selectedCloud,
    selectedTopdate,
    selectedEnvironment,
    selectedAccount,
    selectedApplication,
    selectedservices,
    selectedos,
    selectedinfra,
    selecteddb,
  } = useSelector((state) => state.constimize);

  useEffect(() => {
    setLoading(true);
    const apiVariables = {
      event_type: "credit trend",
      screen: "qg",
      environment: selectedEnvironment.toString(),
      account_name: selectedAccount.toString(),
      application: selectedApplication.toString(),
      company: company_name,
      cloud: selectedCloud.toString(),
      db: selecteddb.toString(),
      infra: selectedinfra.toString(),
      month: selectedTopdate,
      os: selectedos.toString(),
      service: selectedservices.toString(),
    };

    dispatch(getCreditTrendApiData(apiVariables))
      .unwrap()
      .then(({ data }) => {
        const jsonString = data?.credittrend;
        // Parse the JSON-encoded string to a JavaScript object
        let resp = JSON.parse(jsonString);
        const sortedData = resp.sort((a, b) => {
          const dateA = new Date(a.report_month);
          const dateB = new Date(b.report_month);
          return dateA - dateB;
        });

        setCreditDataState({
          creditTrend: sortedData,
          currentCredit: sortedData[sortedData.length - 1],
        });
        setLoading(false);
      })
      .catch((err) => {
        console.log(err?.message);
        setLoading(false);
      });
  }, [
    selectedTopdate,
    selectedAccount,
    selectedEnvironment,
    selectedApplication,
    selectedCloud,
    selectedinfra,
    selectedos,
    selectedservices,
  ]);

  const currencyFormatter = (val) => {
    if (val) {
      const absoluteValue = Math.abs(Number(val)); //to make negative val to +ve

      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD", // Change to your desired currency code
        minimumFractionDigits: 0, // Minimum number of fraction digits
        maximumFractionDigits: 2, // Maximum number of fraction digits
      }).format(absoluteValue);
    } else {
      return 0;
    }
  };
  return (
    <div>
      {/* <pre className="text-white">
        {JSON.stringify(creditDataState, null, 3)}
      </pre> */}
      <Spin spinning={loading} wrapperClassName="costimize-loader">
        <Card className="plat-card-1 plat-card-p-0 p-0 m-0 mnH overflowHidden">
          <h1 className={` spendCardTitle ptl-3`}>Credits Applied</h1>
          <div className="usmon">
            {/* <BiSolidUpArrowAlt clolor="red" /> */}
            <p className="t1">
              {currencyFormatter(creditDataState?.currentCredit?.credit_cost)}
            </p>
            <p className="t2">
              Credits applied month:{" "}
              {moment(creditDataState?.currentCredit?.report_month).format(
                "DD MMM"
              )}
            </p>
          </div>
          <div className="crdGrpBt">
            <CardGraph chartData={creditDataState?.creditTrend} />
          </div>
        </Card>
      </Spin>
    </div>
  );
}

export default CreditsApplied;
