import {React,useState} from "react";
import { Form, Input, Button, Row, Col,Tabs } from "antd";
import { useNavigate } from "react-router-dom";
import A2CSidebar from "../a2c_rover/A2CSidebar";
function LandingZoneFormPage(){
    const [form] = Form.useForm();
    const { TabPane } = Tabs;

    const [state, setState] = useState({
        version: "",
        governedregions: "",
        securityouname: "",
        sandboxouname: "",
        loggingaccountid: "",
        securityaccountid: "",
        loggingbucketretentionperiod: "",
        accessloggingBucketretentionperiod: "",
        kmskey: "",
      });
      
      // const handleInputChange = (event) => {
      //   const { name, value } = event.target;
      //   setState((prevProps) => ({
      //     ...prevProps,
      //     [name]: value,
      //   }));
      // };

      const handleInputChange = (event) => {
        const { name, value } = event.target;
        console.log(`Input changed: ${name} - ${value}`);
        setState((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      };
 
      

      // const handleSubmit = (event) => {
      //   event.preventDefault();
      //   console.log(state);
      // };

      
      const onFinish = (values) => {
        console.log("Form values:", values);
        console.log("State values:", state);
      };

      const navigate = useNavigate();

      function HandleOpen() {
        navigate("/landingzoneadd");
      }

      const HandleSave = async () => {
        try {
          await form.validateFields();
          
          console.log("Form validation succeeded");
          console.log("State values:", state);
          const jsonFormat = JSON.stringify(state);
          console.log("JSON format:", jsonFormat);
          navigate("/landingzoneadd");
        } catch (errorInfo) {
          console.log("Form validation failed:", errorInfo);
        }
      };

      // const HandleSave = () => {
      //   form.validateFields().then(() => {
      //     navigate("/landingzone");
      //   }).catch((errorInfo) => {
      //     console.log("Form validation failed:", errorInfo);
      //   });
      // };


    return(
        <>

<div style={{ display: "flex", height: "100%", minHeight: "400px" }}>
        <A2CSidebar />
        <main
          className="position-main-div"
          style={{ padding: 10, flex: 1, width: "87%" }}
        >
<Tabs defaultActiveKey="1" className="ant-steps-item-title1">
        <TabPane tab="Basic Details" key="1" className="p-3">
       
      
        <Form onSubmit={onFinish} form={form}>
<div className="landingzoneadd1">
    <Row gutter={[16, 16]} className="d-flex justify-content-center">
      <Col xxl={18} xl={18} lg={18} md={18} sm={24}>
        <Row gutter={[16, 16]}>
          <Col xxl={5} xl={5} lg={5} md={5} sm={24}>
            <span className="devops-form-names2">Version</span>
          </Col>
          <Col xxl={1} xl={1} lg={1} md={1} sm={1}></Col>
          <Col xxl={18} xl={18} lg={18} md={18} sm={24}>
            <Form.Item
              name="version"
              rules={[{ required: true, message: "Please enter your version" }]}
            >
            <Input type="text" className="devops-antd-select"  placeholder="Enter version" name="version" value={state.version} onChange={handleInputChange}/>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col xxl={5} xl={5} lg={5} md={5} sm={24}>
            <span className="devops-form-names2">Governed Regions</span>
          </Col>
          <Col xxl={1} xl={1} lg={1} md={1} sm={1}></Col>
          <Col xxl={18} xl={18} lg={18} md={18} sm={24}>
            <Form.Item
              name="governedregions"
              rules={[{ required: true, message: "Please enter your governed regions" }]}
            >
            <Input type="text" className="devops-antd-select" placeholder="Enter governed regions"name="governedregions" value={state.governedregions} onChange={handleInputChange}/>
      
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col xxl={6} xl={6} lg={6} md={6} sm={24}>
            <span className="devops-form-names2">
            Security OuName
            </span>
          </Col>
          <Col xxl={18} xl={18} lg={18} md={18} sm={24}>
            <Form.Item
              name="securityouname"
              rules={[{ required: true, message: "Please enter your security ouname" }]}
            >


<Input type="text" className="devops-antd-select" 
placeholder="Enter securityouname" name="security ouname" value={state.securityouname} 
onChange={handleInputChange} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col xxl={5} xl={5} lg={5} md={5} sm={24}>
            <span className="devops-form-names2">
            Sandbox OuName
            </span>
          </Col>
          <Col xxl={1} xl={1} lg={1} md={1} sm={1}></Col>
          <Col xxl={18} xl={18} lg={18} md={18} sm={24}>
            <Form.Item
              name="sandboxouname"
              rules={[{ required: true, message: "Please enter your sandbox ouname" }]}
            >
            <Input type="text" className="devops-antd-select" placeholder="Enter  sandbox ouname" name="sandboxouname" value={state.sandboxouname} onChange={handleInputChange}/>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col xxl={5} xl={5} lg={5} md={5} sm={24}>
            <span className="devops-form-names2">
            Logging AccountId
            </span>
          </Col>
          <Col xxl={1} xl={1} lg={1} md={1} sm={1}></Col>
          <Col xxl={18} xl={18} lg={18} md={18} sm={24}>
            <Form.Item
              name="logging accountid"
              rules={[{ required: true, message: "Please enter your logging accountid" }]}
            >
            <Input type="text" className="devops-antd-select" placeholder="Enter  logging accountid" name="loggingaccountid" value={state.loggingaccountid} onChange={handleInputChange}/>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col xxl={5} xl={5} lg={5} md={5} sm={24}>
            <span className="devops-form-names2">
            Security AccountId
            </span>
          </Col>
          <Col xxl={1} xl={1} lg={1} md={1} sm={1}></Col>
          <Col xxl={18} xl={18} lg={18} md={18} sm={24}>
            <Form.Item
              name="securityaccountid"
              rules={[{ required: true, message: "Please enter your security accountid" }]}
            >
            <Input type="text" className="devops-antd-select" placeholder="Enter  security accountid" name="securityaccountid" value={state.securityaccountid} onChange={handleInputChange}/>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col xxl={5} xl={5} lg={5} md={5} sm={24}>
            <span className="devops-form-names2">
            Logging Bucket</span>
          </Col>
          <Col xxl={1} xl={1} lg={1} md={1} sm={1}></Col>
          <Col xxl={18} xl={18} lg={18} md={18} sm={24}>
            <Form.Item
              name="loggingbucketretentionperiod"
              rules={[{ required: true, message: "Please enter your logging bucket" }]}
            >
            <Input type="text" className="devops-antd-select" placeholder="Enter  logging bucket" name="loggingbucketretentionperiod" value={state.loggingbucketretentionperiod} onChange={handleInputChange}/>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col xxl={5} xl={5} lg={5} md={5} sm={24}>
            <span className="devops-form-names2">
            Access Logging</span>
          </Col>
          <Col xxl={1} xl={1} lg={1} md={1} sm={1}></Col>
          <Col xxl={18} xl={18} lg={18} md={18} sm={24}>
            <Form.Item
              name="accessloggingBucketretentionperiod"
              rules={[{ required: true, message: "Please enter your access logging" }]}
            >
            <Input type="text" className="devops-antd-select" placeholder="Enter  access logging" name="accessloggingBucketretentionperiod" value={state.accessloggingBucketretentionperiod} onChange={handleInputChange}/>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col xxl={5} xl={5} lg={5} md={5} sm={24}>
            <span className="devops-form-names2">
            KMSKey</span>
          </Col>
          <Col xxl={1} xl={1} lg={1} md={1} sm={1}></Col>
          <Col xxl={18} xl={18} lg={18} md={18} sm={24}>
            <Form.Item
              name="kmskey"
              rules={[{ required: true, message: "Please enter your kms key" }]}
            >
            <Input type="text" className="devops-antd-select" placeholder="Enter  kmskey"  name="kms key" value={state.kmskey} onChange={handleInputChange}/>
            </Form.Item>
          </Col>
        </Row>
      </Col>
    </Row>
  </div>
  <br />
  {/* <div className="SaveBack"> */}
  <Row gutter={[16, 16]} className="d-flex justify-content-between landing-zone-footer ">
    <Col span={12}>
      {/* <Form.Item style={{ textAlign: "right" }}> */}
        <Button
          className="form-back-button"
          htmlType="button"
          onClick={HandleOpen}

        >
          Back
        </Button>
    </Col>
    <Col span={12}>
        <Button
          type="primary"
          className="form-button save-form-button"
          onClick={HandleSave}
        >
          Save
        </Button>
        {/* </Form.Item> */}
      </Col>
    </Row>
    {/* </div> */}
  </Form>
  </TabPane>
   <TabPane
          tab="Configuration"
          key="2"
          disabled={true}
          // disabled={!basicDetailsFilled}
          className="p-4"
        >
          {/* <LandingZoneFormPage /> */}
        </TabPane>
  </Tabs>
 </main>
        </div>
        </>
    )

}
export default LandingZoneFormPage;