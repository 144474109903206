import React, { useState, useEffect } from "react";
import "./../a2c_rover/a2c_rover.css";
import { Row, Col, Button, Card ,Switch} from "antd";
import warQuestion from "./costoptimization.json";
import { Input } from "antd";
// import { getwarassessment } from "../action/graphql/a2crover/A2CReducer";
import { useDispatch} from "react-redux";
const { TextArea } = Input;

function CostOptmization(props) {
  const [showOptions, setShowOptions] = useState(false);
  const [options, setOptions] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [isSwitchChecked, setIsSwitchChecked] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState(() => {
    const storedOptions = localStorage.getItem('selectedOptions');
    return storedOptions ? JSON.parse(storedOptions) : [];
  });

  const [answers, setAnswers] = useState(() => {
    const storedAnswers = localStorage.getItem('answers');
    return storedAnswers ? JSON.parse(storedAnswers) : Array(warQuestion.data.length).fill({ answers: [] });
  });


  const openQuestion = (index) => {
    setShowOptions(true);
    const opts = warQuestion.data[index].option;
    setOptions(opts);
    setSelectedQuestion(warQuestion.data[index]);
    setCurrentIndex(index);
  };

  const handleCheckboxChange = (option) => {
    const updatedOptions = selectedOptions.includes(option)
      ? selectedOptions.filter((selected) => selected !== option)
      : [...selectedOptions, option];
  
    setSelectedOptions(updatedOptions);
  
    // Save to local storage
    localStorage.setItem('selectedOptions', JSON.stringify(updatedOptions));
  
    // const newCount = updatedOptions.filter(opt => options.indexOf(opt) !== -1).length;
    // setCheckedSwitchCount(newCount);
    // console.log(newCount,"applicablecount")
  };
  

  useEffect(() => {
    openQuestion(0);
  }, []);

  const dispatch = useDispatch();
  
    var customer = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );

  const handleSwitchChange = (checked) => {
    setIsSwitchChecked(checked);
  };



const saveOptions = () => {
  const updatedAnswers = [...answers];
  updatedAnswers[currentIndex] = {
    question: selectedQuestion.question,
    answers: selectedOptions,
  };
  setAnswers(updatedAnswers);
  localStorage.setItem('answers', JSON.stringify(updatedAnswers));


if (isSwitchChecked) {
  props.setNotApplicable(props.notapplicable + 1);
}
 



    const formattedQuestion = selectedQuestion.question;
    const optionIndexes = selectedOptions.map(option => options.indexOf(option));


    const input = {
      workload_name: "war workload test",
      updated_date: "2023-11-21",
      question: formattedQuestion,
      piller_type: "cost optimization", 
      customer: customer,
      created_date: "2023-11-20",
      answer_options:  JSON.stringify(optionIndexes), 
    };
  
    // dispatch(getwarassessment({ input }))
    //   .unwrap()
    //   .then(({ data }) => {
    //     console.log(data, "assessmentqus");
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  
    // Check if all questions are answered and update the file count
    if (nonEmptyArrayCount === warQuestion.data.length - 1) {
      props.setFile(props.file + 1);
      console.log("counterval", props.file);
    }
  };

  const nonEmptyArrayCount = answers.filter(
    (answer) => Array.isArray(answer.answers) && answer.answers.length > 0
  ).length;

  const questions = warQuestion.data.map((item, index) => (
    <div
      data-index={index}
      onClick={() => openQuestion(index)}
      key={index}
      className={`question ${currentIndex === index ? "active" : ""}`}
      style={{ cursor: "pointer" }}
    >
      <span style={{ color: "#ff6a2f" }}>Q{index + 1}.</span> {item.question}
      {index !== warQuestion.data.length - 1 && <hr />}
    </div>
  ));

  const isSaveDisabled = selectedOptions.length === 0;
  const isNextDisabled = currentIndex === warQuestion.data.length - 1;

  return (
    <div>
      <Row className="mt-2">
        <h4 className="titletab">Cost Optimization</h4>
        <Button className="buttonclass">
          {nonEmptyArrayCount}/{warQuestion.data.length}
        </Button>
      </Row>
      <Row gutter={[16, 16]}>
        <Col xl={11} lg={11} md={11}>
          <Card className="operate-cards mt-4 scroll-container">
            <Row className="p-3">
              <h5 className="white-color font-of-qus">{questions}</h5>
            </Row>
          </Card>
        </Col>
        <Col xl={1} lg={1} md={1}></Col>
        <Col xl={11} lg={11} md={11}>
          {showOptions && (
            <Card className="operate-cards answer-card mt-4 scroll-container">
              <div>
                <h4 className="headingh4">Question {currentIndex + 1} </h4>
                <span className="white-color">{selectedQuestion.question}</span><br></br>
                <Row><Col>
                <Switch
        className="war_switch"
        checked={isSwitchChecked}
        onChange={handleSwitchChange}
      />{" "}</Col><Col><p className="color-orange ms-2">This Question is Not Applicable</p></Col></Row>
                <ul className="whitecolor">
                  {options.map((opt, index) => (
                    <li key={index}>
                      <input
                        type="checkbox"
                        onChange={() => handleCheckboxChange(opt)}
                        checked={selectedOptions.includes(opt)}
                      />{" "}
                      {opt}
                    </li>
                  ))}
                </ul>
                <Row>
                  <Col span={24}>
                    <TextArea
                      rows={4}
                      placeholder="Message"
                      className="textareacls"
                    />
                  </Col>
                </Row>
                <br></br>
                <Row gutter={[16, 16]} style={{ display: "flex", justifyContent: "center" }}>
             
             <Col>
            
                <Button
                  className="buttonclass "
                  onClick={() => openQuestion(currentIndex - 1)}
                  disabled={isNextDisabled}
                >
                  BACK
                </Button>
             
            </Col>
           
            <Col>
             
                <Button
                  className="savebutton"
                  onClick={saveOptions}
                  // disabled={isSaveDisabled}
                >
                  SAVE
                </Button>
            
           
               
             
            </Col>
              <Col>
              {currentIndex !== warQuestion.data.length - 1 && (
                <Button
                  className="buttonclass ms-1"
                  onClick={() => openQuestion(currentIndex + 1)}
                  disabled={isNextDisabled}
                >
                  NEXT
                </Button>
              )}
            </Col>
          </Row>
              </div>
            </Card>
          )}
        </Col>
      </Row>
    </div>
  );
}

export default CostOptmization;
