import { memo, useEffect, useState } from "react";
import { Col, Row, Card, Spin, Dropdown, Space, message } from "antd";
import { Icon } from "@iconify/react";
import { useDispatch, useSelector } from "react-redux";
import { DownOutlined } from "@ant-design/icons";
import {
  getUderUtilized,
  listApplicationBudget,
  getStartStopScheduler,
  taggingResources,
} from "../../../action/costimizeV2Active";
import TaggingPopup from "../TaggingPopup";
import TaggingBestPractice from "../TaggingBestPractice";
import BudgetTables from "./BudgetTables";
import TaggingChart from "./TaggingChart";
import "./compliance.css";
import UnderUtilizedTable from "./UnderUtilizedTable";
import TaggingTable from "./TaggingTable";
const Compliance = () => {
  const {
    selectedCloud,
    selectedTopdate,
    selectedAccount,
    selectedApplication,
    selectedEnvironment,
    applicationList,
    selecteddb,
    selectedinfra,
    selectedos,
    selectedservices,
  } = useSelector((state) => state.constimize);
  const dispatch = useDispatch();
  // const companyName = localStorage.getItem(
  //   "CognitoIdentityServiceProvider.company"
  // );
  var companyName = "all_cloud";

  //state
  const [tableIndex, setTableIndex] = useState("0");
  const [showTable, setShowTable] = useState("underutilized");
  const [activeCard, setActiveCard] = useState(1);
  const [underresources, setUnderResources] = useState({
    underresourcesper: 0,
    value: 0,
  });
  const [budgetallocation, setBudgetAllocation] = useState({
    budgetpercentage: 0,
    total_application: 0,
    buget_allocation: 0,
    status: false,
  });
  const [startstopscheduler, setStartStopScheduler] = useState({
    completed: 0,
    pending: 0,
    percentage: 0,
    status: false,
  });
  const [modelopen, setModelOpen] = useState(false);
  const [untaggedresources, setUntaggedResources] = useState({
    mandatory_tag: 0,
    automated_tag: 0,
    no_cost_impact_tags: 0,
    total_tag: 0,
    percentage: 0,
    list: [],
  });
  const handleCardClick = (cardNumber) => {
    setActiveCard(cardNumber);
  };
  const [taggingpracticestatus, setTaggingPracticeStatus] = useState(false);

  //Under Utilized Resources & Start Stop Scheduler
  useEffect(() => {
    var apivalue = {
      cloud: selectedCloud.toString(),
      account: selectedAccount.toString(),
    };
    //Under Utilized Resources
    // console.log({ companyName, selectedTopdate, apivalue });
    dispatch(getUderUtilized({ companyName, selectedTopdate, apivalue }))
      .unwrap()
      .then(({ data }) => {
        if (data.newfunctionquery23.length > 0) {
          setUnderResources({
            underresourcesper: Math.round(
              (Math.round(data.newfunctionquery23[0].underutilized_resources) *
                100) /
                Math.round(data.newfunctionquery23[0].total_resources)
            ),
            value: Math.round(
              data.newfunctionquery23[0].underutilized_resources
            ),
          });
        } else {
          setUnderResources({
            underresourcesper: 0,
            value: 0,
          });
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
    var yesterdayDate = [
      `${new Date().getFullYear()}-${new Date().getMonth() + 1}-01`,
    ];
    yesterdayDate = yesterdayDate.toString();
    dispatch(getStartStopScheduler({ companyName, yesterdayDate, apivalue }))
      .unwrap()
      .then(({ data }) => {
        const completedList = data.newfunctionquery17.filter(
          (v) => v.status.toLowerCase() === "completed"
        );
        const pendingList = data.newfunctionquery17.filter(
          (v) => v.status.toLowerCase() === "failed"
        );
        var percentage = 0;
        if (data.newfunctionquery17.length > 0) {
          percentage = Math.round(
            (completedList.length * 100) / data.newfunctionquery17.length
          );
        }
        setStartStopScheduler({
          completed: completedList.length,
          pending: pendingList.length,
          percentage: percentage,
          status: true,
        });
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [selectedCloud, selectedTopdate, companyName, selectedAccount]);

  //Budget Allocation
  useEffect(() => {
    var budget_year;
    if (selectedTopdate.split(",").length > 1) {
      const datelist = selectedTopdate.split(",").map((data) => {
        return new Date(data).getFullYear();
      });
      const uniqueArr = new Set([...datelist]);
      budget_year = [...uniqueArr].toString();
    } else {
      budget_year = new Date(selectedTopdate).getFullYear();
    }

    const input = {
      account_name: selectedAccount.toString(),
      application: selectedApplication.toString(),
      budget_year: budget_year,
      cloud: selectedCloud.toString(),
      company_name: companyName,
      environment: selectedEnvironment.toString(),
    };
    dispatch(listApplicationBudget({ input }))
      .unwrap()
      .then(({ data }) => {
        var percentage = 0;
        if (
          Number.isInteger(
            Math.round(
              (data.list_application_budget.length * 100) /
                applicationList.length
            )
          )
        ) {
          percentage = Math.round(
            (data.list_application_budget.length * 100) / applicationList.length
          );
        }
        if(data.list_application_budget.length>0){
          setBudgetAllocation({
            budgetpercentage: percentage,
            total_application: applicationList.length,
            buget_allocation: data.list_application_budget.length,
            status: true,
          });
        }else{
          setBudgetAllocation({
            budgetpercentage: "0",
            total_application:"0",
            buget_allocation: "0",
            status: true,
          });
        }
      
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [
    selectedCloud,
    selectedTopdate,
    companyName,
    selectedAccount,
    selectedApplication,
    selectedEnvironment,
    applicationList,
  ]);
  const onClick = ({ key }) => {
    setTableIndex(key);
    setShowTable("budget");
  };

  //tagging
  useEffect(() => {
    const input = {
      company_name: companyName,
      account_name: selectedAccount.toString(),
      application: selectedApplication.toString(),
      cloud: selectedCloud.toString(),
      environment: selectedEnvironment.toString(),
      services: selectedservices.toString(),
      os: selectedos.toString(),
      db: selecteddb.toString(),
      infra: selectedinfra.toString(),
      month: selectedTopdate,
    };

    dispatch(taggingResources({ input }))
      .unwrap()
      .then(({ data }) => {
        // console.log("issue checking");
        // console.log(data);
        const total_tagged = data.newfunctionquery2?.reduce(
          (a, v) => (a = +a + +v.count_tag_category),
          0
        );
        const automated_tag = data.newfunctionquery2?.filter(
          (v, index) => v.tag_category === "Automated-tag"
        );

        const mandatory_tag = data.newfunctionquery2?.filter(
          (v, index) => v.tag_category === "Mandatory-tag"
        );
        const no_cost_impact_tags = data.newfunctionquery2?.filter(
          (v, index) => v.tag_category === "No-cost-impact-tags"
        );
        //count
        const automated_tag_count = automated_tag?.reduce(
          (a, v) => (a = +a + +v.count_tag_category),
          0
        );
        const mandatory_tag_count = mandatory_tag?.reduce(
          (a, v) => (a = +a + +v.count_tag_category),
          0
        );
        const no_cost_impact_tags_count = no_cost_impact_tags?.reduce(
          (a, v) => (a = +a + +v.count_tag_category),
          0
        );

        //tagging count
        const tagged_records = mandatory_tag?.filter(
          (v, index) => v.tagging_details === "tagged"
        );
        const tagged_records_count = tagged_records?.reduce(
          (a, v) => (a = +a + +v.count_tag_category),
          0
        );
        //partially count
        const partially_records = mandatory_tag?.filter(
          (v, index) => v.tagging_details === "partially_tagged"
        );
        const partially_records_count = partially_records?.reduce(
          (a, v) => (a = +a + +v.count_tag_category),
          0
        );
        //untagged
        const untagged_records = mandatory_tag?.filter(
          (v, index) => v.tagging_details === "Untagged"
        );
        const untagged_records_count = untagged_records?.reduce(
          (a, v) => (a = +a + +v.count_tag_category),
          0
        );
        const percentage_value =
          (tagged_records_count * 100) /
          (tagged_records_count +
            partially_records_count +
            untagged_records_count);

        setUntaggedResources({
          mandatory_tag: mandatory_tag_count,
          automated_tag: automated_tag_count,
          no_cost_impact_tags: no_cost_impact_tags_count,
          total_tag: total_tagged,
          percentage: Number.isNaN(percentage_value)
            ? 0
            : percentage_value.toLocaleString(undefined, {
                maximumFractionDigits: 2,
              }),
          list: data.newfunctionquery2,
        });
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [
    selectedCloud,
    selectedTopdate,
    companyName,
    selectedAccount,
    selectedApplication,
    selectedEnvironment,
    selecteddb,
    selectedinfra,
    selectedos,
    selectedservices,
  ]);

  const items = [
    {
      label: "Budget Comparison",
      key: "1",
    },
    {
      label: "Application Budget",
      key: "2",
    },
  ];

  return (
    <>
      {modelopen && (
        <TaggingPopup
          modelopen={modelopen}
          setModelOpen={setModelOpen}
          untaggedresources={untaggedresources}
        />
      )}

      <Row gutter={[16, 16]} className="mt-1">
        <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6}>
          <Card
            className="plat-card-1"
            style={{
              cursor: "pointer",
              border: activeCard === 1 ? "1px solid #ff6a2f" : "1px solid rgba(255, 255, 255, 0.2)",
            }}
            onClick={() => {
              setShowTable((prev) =>
                prev === "underutilized" ? "no-table" : "underutilized"
              );
              handleCardClick(1);
            }}
          >
            <div>
              <h1 className="card-title-1">Under Utilized Resources</h1>
            </div>
            <div className="mt-3">
              <h1 className="card-value-1">
                <Icon
                  icon="grommet-icons:resources"
                  className="quick-glance-icon"
                />
                {underresources.underresourcesper}%
              </h1>
            </div>
            <div className="mt-3">
              <h1 className="card-1-bottom-value">
                Under Utilized Assets - {underresources.value}
              </h1>
            </div>
          </Card>
        </Col>
        <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6}>
          <Card
            className="plat-card-1"
            style={{ cursor: "pointer",
            border: activeCard === 2 ? "1px solid #ff6a2f" : "1px solid rgba(255, 255, 255, 0.2)", }}
            onClick={() => {
              tableIndex !== "0" ? setTableIndex("0") : <></>;
              showTable === "budget" ? setShowTable("no-table") : <></>;
              showTable ==="underutilized" && setShowTable("no-table"); 
              handleCardClick(2);
            }}
          >
            <div>
              <h1 className="card-title-1">
                <div className="tagging-absolute">
                  {showTable !== "budget" ? (
                    <Dropdown
                      menu={{
                        items,
                        onClick,
                      }}
                      overlayClassName="budget-table-dropdown"
                    >
                      <a onClick={(e) => e.preventDefault()}>
                        <Space>
                          <DownOutlined style={{ fontSize: "18px" }} />
                        </Space>
                      </a>
                    </Dropdown>
                  ) : (
                    <></>
                  )}
                </div>
                Budget Allocation
              </h1>
            </div>
            <div className="mt-3">
              <h1 className="card-value-1">
                <Icon
                  icon="solar:money-bag-bold"
                  className="quick-glance-icon"
                />
                {budgetallocation.budgetpercentage}%
              </h1>
            </div>
            <div className="mt-3 card-1-bottom-flex">
              <p className="card-1-bottom-value">
                Total No of Application -{budgetallocation.total_application}
              </p>
              <p className="card-1-bottom-value">
                Budget Allocation - {budgetallocation.buget_allocation}
              </p>
            </div>
          </Card>
        </Col>
        <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6}>
          <Card
            className="plat-card-1 tagging-relative"
            style={{ cursor: "pointer",
            border: activeCard === 3 ? "1px solid #ff6a2f" : "1px solid rgba(255, 255, 255, 0.2)",}}
            onClick={() => {
              setShowTable((prev) =>
              prev === "tagging" ? "no-table" : "tagging"
              );
              showTable ==="underutilized" && setShowTable("no-table"); 
              handleCardClick(3);
            }}
          >
            {taggingpracticestatus && (
              <TaggingBestPractice
                taggingpracticestatus={taggingpracticestatus}
                setTaggingPracticeStatus={setTaggingPracticeStatus}
              />
            )}

            <div className="mt-3">
              <h1 className="card-title-1">
                <div className="tagging-absolute">
                  <Icon
                    icon="solar:menu-dots-bold"
                    style={{
                      fontSize: "24px",
                      color: "#fff",
                      cursor: "pointer",
                    }}
                    onClick={() => setModelOpen(true)}
                  />
                </div>
                Tagging{" "}
                <Icon
                  style={{ cursor: "pointer", color: "#fff" }}
                  icon="icons8:idea"
                  onClick={() => setTaggingPracticeStatus(true)}
                />
              </h1>
            </div>
            <div className="mt-3">
              <h1 className="card-value-1">
                <Icon icon="mdi:tag" className="quick-glance-icon" />
                {untaggedresources.percentage}%
              </h1>
            </div>
          </Card>
        </Col>
        <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6}>
          <Card className="plat-card-1" style={{ cursor: "pointer", cursor: "pointer",
            border: activeCard === 4 ? "1px solid #ff6a2f" : "1px solid rgba(255, 255, 255, 0.2)",}} onClick={() => {
              handleCardClick(4);
              showTable ==="underutilized" && setShowTable("no-table"); 
            }}>
            <div>
              <h1 className="card-title-1">Start/Stop Scheduler </h1>
            </div>
            <div className="mt-3">
              <h1 className="card-value-1">
                <Icon
                  icon="mdi:receipt-text-clock"
                  className="quick-glance-icon"
                />
                {startstopscheduler.percentage}%
              </h1>
            </div>
            <div className="mt-3 card-1-bottom-flex">
              <p className="card-1-bottom-value">
                Completed - {startstopscheduler.completed}
              </p>
              <p className="card-1-bottom-value">
                Failed - {startstopscheduler.pending}
              </p>
            </div>
          </Card>
        </Col>
      </Row>
      {showTable === "budget" ? (
        <BudgetTables index={tableIndex} />
      ) : showTable === "tagging" ? (
        // <TaggingTable />
        <></>
      ) : showTable === "underutilized" ? (
        <UnderUtilizedTable />
      ) : (
        <></>
      )}
      {/* <Row gutter={[16, 16]}>
        <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
          <Card className="plat-card-1 mt-4">
            <TaggingChart untaggedresources={untaggedresources} />
          </Card>
        </Col>
      </Row> */}
    </>
  );
};
export default memo(Compliance);
