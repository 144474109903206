import { Spin } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useDispatch, useSelector } from "react-redux";
import "../../../costimize_v2/costimize_v2.css";

import { getWeeklyTrendApiData } from "./../../../action/accountAction";

function LastFourWeeksSpendTrend() {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const {
    selectedCloud,
    selectedTopdate,
    selectedEnvironment,
    selectedAccount,
    selectedApplication,
    selectedservices,
    selectedos,
    selectedinfra,
    selecteddb,
  } = useSelector((state) => state.constimize);
  // const company_name = localStorage.getItem(
  //   "CognitoIdentityServiceProvider.company"
  // );
  var company_name = "all_cloud";
  const [reportweekwise, setReportweekwise] = useState([]);
  // const endDateNsub3 = () => {
  //   return `  ${
  //     moment(selectedTopdate).isSame(new Date(), "month")
  //       ? moment().subtract(3, "days").format("YYYY-MM-DD")
  //       : moment(selectedTopdate).endOf("month").format("YYYY-MM-DD")
  //   } `;
  // };
  // console.log(endDateNsub3())


const endDateNsub3 = () => {
  const currentDate = moment();
  const threeDaysBefore = currentDate.subtract(3, "days").format("YYYY-MMM-DD");
  return `  ${
     moment(selectedTopdate).isSame(currentDate, "month")
      ? threeDaysBefore
      : moment(selectedTopdate).endOf("month").format("YYYY-MMM-DD")
  } `;
};

  useEffect(() => {
    setLoading(true);

    const apiVariables = {
      event_type: "weekly trend",
      screen: "qg",
      account_name: selectedAccount.toString(),
      company: company_name,
      cloud: selectedCloud.toString(),
      date: endDateNsub3(),
      application: selectedApplication.toString(),
      db: selecteddb.toString(),
      environment: selectedEnvironment.toString(),
      os: selectedos.toString(),
      service: selectedservices.toString(),
      infra: selectedinfra.toString(),
    };
    dispatch(getWeeklyTrendApiData(apiVariables))
      .unwrap()
      .then(({ data }) => {
        // Handle the response data as needed

        const jsonString = data.weeklytrendchart_qg;
        // Parse the JSON-encoded string to a JavaScript object
        let resp = JSON.parse(jsonString);
        const reportweek = resp.map((item) => {
          return {
            current_week: item.fourth_week,
            previous_week: item.third_week,
            pre_previous_week: item.secound_week,
            environment: item.environment,
            fourth_week: item.first_week,
          };
        });

        setReportweekwise(reportweek);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err?.message);
      });
  }, [selectedTopdate]);
  function calculateWeekRanges() {
    const currentDate = moment();
    const startDate = moment(endDateNsub3()).startOf("week");
    let currentWeekEnd = startDate.clone().endOf("week");

    // Adjust current week end if it exceeds the current date
    if (currentWeekEnd.isAfter(currentDate)) {
      currentWeekEnd = currentDate;
    }

    const currentWeekStart = startDate.format("Do MMM");
    const currentWeekEndFormatted = currentWeekEnd.format("Do MMM");

    const previousWeekStart = startDate
      .clone()
      .subtract(1, "week")
      .format("Do MMM");
    const previousWeekEnd = startDate
      .clone()
      .subtract(1, "week")
      .endOf("week")
      .format("Do MMM");

    const prePreviousWeekStart = startDate
      .clone()
      .subtract(2, "weeks")
      .format("Do MMM");
    const prePreviousWeekEnd = startDate
      .clone()
      .subtract(2, "weeks")
      .endOf("week")
      .format("Do MMM");

    const fourthWeekStart = startDate
      .clone()
      .subtract(3, "weeks")
      .format("Do MMM");
    const fourthWeekEnd = startDate
      .clone()
      .subtract(3, "weeks")
      .endOf("week")
      .format("Do MMM");

    let generatedWeeks = {
      current_week: `${currentWeekStart} - ${moment(endDateNsub3()).format(
        "Do MMM"
      )}`,
      previous_week: `${previousWeekStart} - ${previousWeekEnd}`,
      pre_previous_week: `${prePreviousWeekStart} - ${prePreviousWeekEnd}`,
      fourth_week: `${fourthWeekStart} - ${fourthWeekEnd}`,
    };
    return [
      generatedWeeks.fourth_week,
      generatedWeeks.pre_previous_week,
      generatedWeeks.previous_week,
      generatedWeeks.current_week,
    ];
  }
  // below color will dynamically apply when tags changes
  const colors = [
    "#ff6a2f",
    "#fd8f63",
    "#fd8f89",
    "#b23cfd",
    "#ff0000",
    "#00ff00",
    "#0000ff",
    "#ffff00",
    "#ff00ff",
    "#00ffff",
  ];

  const options_weeks = {
    chart: {
      id: "threeweekspend",
      type: "bar",
      height: 350,
      stacked: "true",
    },
    plotOptions: {
      bar: {
        borderRadius: 5,
        horizontal: false,
        columnWidth: "50%",
        dataLabels: {
          position: "top",
          enabled: true,
          formatter: function (val) {
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          },
          total: {
            enabled: true,
            formatter: function (w) {
              return w.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            },
            offsetX: 0,
            style: {
              fontSize: "13px",
              fontWeight: 600,
              color: "white", //bar data color
            },
          },
        },
      },
    },
    dataLabels: {
      enabled: true,
      position: "bottom",
      style: {
        fontSize: "12px",
      },
      formatter: function (val) {
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
      offsetY: -1,
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: calculateWeekRanges(),
      labels: {
        rotate: 0,
        style: {
          fontSize: "10px",
          colors: "white",
        },
      },
    },

    yaxis: {
          labels: {
            style: {
              fontSize: "10px",
              colors: "white", 
            },
            formatter: function (value) {
              return value.toLocaleString("en-US"); 
            },
          },
          title: {
            text: "Spend in $",
            style: {
              fontSize: "13px",
              color: "white",
              fontWeight: "500",
            },
          },
        },
        tooltip: {
          enabled: true,
          shared: true,
          intersect: false,
          y: {
            formatter: function (value) {
              return `$${value.toLocaleString("en-US")}`; 
            },
          },
        },
        legend: {
          show: true,
          labels: {
            colors: "white", 
          },
        },
      };

  
  
  const series_weeks = reportweekwise.map((item, index) => {
    return {
      name: item.environment,
      data: [
        parseInt(item.fourth_week),
        parseInt(item.pre_previous_week),
        parseInt(item.previous_week),
        parseInt(item.current_week),
      ],
      color: colors[index],
    };
  });

  const currentDates = new Date();
  const currentDate_new = currentDates.getDate();

  let startDates;
  if (currentDate_new <= 3) {
    const previousMonth = new Date(
      currentDates.getFullYear(),
      currentDates.getMonth() - 1,
      1
    );
    const year =
      previousMonth.getMonth() === 11
        ? currentDates.getFullYear() - 1
        : currentDates.getFullYear();
    startDates = new Date(year, previousMonth.getMonth(), 1);
  } else {
    startDates = new Date(
      currentDates.getFullYear(),
      currentDates.getMonth(),
      1
    );
  }
  return (
    <>
      {/* <pre>{JSON.stringify(series_weeks, null, 4)}</pre> */}
      <Spin spinning={loading} wrapperClassName="costimize-loader">
        <ReactApexChart
          options={options_weeks}
          series={series_weeks}
          height={250}
          type="bar"
        />
      </Spin>
    </>
  );
}

export default LastFourWeeksSpendTrend;
