import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import {
  Menu,
  MenuItem,
  Sidebar,
  SubMenu,
  menuClasses,
} from "react-pro-sidebar";
import Assets from "../assets_a2c/Assets";
import { Link } from "react-router-dom";
import { color } from "echarts";
import { useNavigate } from 'react-router-dom';
import { jwttokengeneration } from "../action/graphql/devops/DevOpsAction";
import { useDispatch } from "react-redux";
import cloudhubimg from "../../assets/images/cloudhub.png"


function A2CSidebar() {
  const dispatch = useDispatch();

 
  const [menuOpen, setMenuOpen] = useState(false);
 
  const apiUrl = "https://7e0xtijua7.execute-api.ap-south-1.amazonaws.com/asr/upload"
 
  const handleAutoLogin =  () => {
 
    const input = {
      event_type: "Jwt-Generation",
      username: "vijayakumar.r@1cloudhub.com",
      password: "Demo@2024",


    };
    console.log(input, "inputtt")


    dispatch(jwttokengeneration({ input }))
      .unwrap()
      .then(({ data }) => {
        console.log(data.devops_workflow, "token_generation");
        const tokenvalue = JSON.parse(data.devops_workflow)
       const token = tokenvalue.token
        window.open(`https://demo.tokenoptix.ai/auth-tokenoptix/${token}`);  

      })
      .catch((error) => {
        console.error("Error:", error);
      });

  };
 
 
  const handleclickslm = () => {

    const input = {
      event_type: "Jwt-Generation",
      username: "demo@1cloudhub.com",
      password: "Demo@2024",


    };
    console.log(input, "inputtt")


    dispatch(jwttokengeneration({ input }))
      .unwrap()
      .then(({ data }) => {
        console.log(data.devops_workflow, "token_generation");
        const tokenvalue = JSON.parse(data.devops_workflow)
       const token = tokenvalue.token
        window.open(`https://dev.d3kfvz8tcn3ycd.amplifyapp.com/auth-itsm/${token}`);    

      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
 
  const handleobservability =  () => {
 
    const input = {
      event_type: "Jwt-Generation",
      username: "demo@1cloudhub.com", 
      password: "Demo@2024",


    };
    console.log(input, "inputtt")


    dispatch(jwttokengeneration({ input }))
      .unwrap()
      .then(({ data }) => {
        console.log(data.devops_workflow, "token_generation");
        const tokenvalue = JSON.parse(data.devops_workflow)
       const token = tokenvalue.token
         window.open(`https://applicationlogbranch.d3kfvz8tcn3ycd.amplifyapp.com/auth-obserbility/${token}`);  

      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
 
 
  return (
    <div>
      <div
        className="a2c-rover-sidebar"
        style={{ display: "flex", height: "100%", minHeight: "400px" }}
      >
        <Sidebar
          width="200px"
          backgroundColor="#0f0f0f"
          rootStyles={{
            borderRight: "1px solid rgba(255, 255, 255, 0.20)",
            ["." + menuClasses.button]: {
              background: "#0f0f0f",
              color: "white",
              "&:hover": {
                backgroundColor: "#202020",
              },
            },
            ["." + menuClasses.label]: {
              color: "white",
            },
            ["." + menuClasses.icon]: {
              color: "white",
            },
          }}
          collapsedWidth="200px"
          collapsed={false}
          defaultCollapsed={false}
        >
          <Menu>
        
            <SubMenu
              icon={<Icon icon={"uis:chart"} />}
              rootStyles={{
                ["." + menuClasses.button]: {
                  background: "#0f0f0f",
                  color: "white",
                  "&:hover": {
                    background: "#0f0f0f",
                  },
                },
                ["." + menuClasses.label]: {
                  color: "white",
                },
                ["." + menuClasses.SubMenuExpandIcon]: {
                  color: "white",
                },
                ["." + menuClasses.icon]: {
                  color: "white",
                },
              }}
              label="A2C Rover"
            >
              <MenuItem component={<Link to={"/assess"} />}> Assess</MenuItem>
              {/* <MenuItem component={<Link to={"/cost-analysis"} />}>
                {" "}
                Cost Analysis
              </MenuItem> */}
              <MenuItem component={<Link to={"/migrate"} />}> Migrate</MenuItem>
              <MenuItem component={<Link to={"/operate"} />}> Operate</MenuItem>
 
            </SubMenu>
            {/* <MenuItem
              icon={<Icon icon={"uis:chart"} />}
              rootStyles={{
                ["." + menuClasses.button]: {
                  background: "#0f0f0f",
                  color: "white",
                  "&:hover": {
                    background: "#0f0f0f",
                  },
                },
                ["." + menuClasses.label]: {
                  color: "white",
                },
                ["." + menuClasses.icon]: {
                  color: "white",
                },
              }}
              component={<Link to={"/devops-requestor"} />}
            >
              {" "}
              DevOps Express
            </MenuItem> */}
 
   <SubMenu
              icon={<Icon icon={"uis:chart"} />}
              rootStyles={{
                ["." + menuClasses.button]: {
                  background: "#0f0f0f",
                  color: "white",
                  "&:hover": {
                    background: "#0f0f0f",
                  },
                },
                ["." + menuClasses.label]: {
                  color: "white",
                },
                ["." + menuClasses.SubMenuExpandIcon]: {
                  color: "white",
                },
                ["." + menuClasses.icon]: {
                  color: "white",
                },
              }}
              label="Devops Express"
            >
              <MenuItem component={<Link to={"/devops-requestor"} />}>Deployment Request</MenuItem>
              <MenuItem component={<Link to={"/landingzoneadd"} />}>Landing Zone</MenuItem>
            </SubMenu> 
            <SubMenu
              icon={<Icon icon={"uis:chart"} />}
              rootStyles={{
                ["." + menuClasses.button]: {
                  background: "#0f0f0f",
                  color: "white",
                  "&:hover": {
                    background: "#0f0f0f",
                  },
                },
                ["." + menuClasses.label]: {
                  color: "white",
                },
                ["." + menuClasses.SubMenuExpandIcon]: {
                  color: "white",
                },
                ["." + menuClasses.icon]: {
                  color: "white",
                },
              }}
              label="OpsVista"
            >
              {/* <MenuItem component={<Link to={"/slm"} />}> SLM</MenuItem>
              <MenuItem component={<Link to={"/peems"} />}>
                {" "}
             Event Express
              </MenuItem>
              <MenuItem component={<Link to={"/cams"} />}>
                {" "}
                Smart Alert
              </MenuItem> */}
              <MenuItem 
              onClick={handleclickslm}
              // onClick={Tokengneration}
              
              > SLM</MenuItem>
              <MenuItem onClick={handleobservability}>
 
              Observability
              </MenuItem>
              <MenuItem component={<Link to={"/wardashboard"} />}>WAR</MenuItem>
              {/* <MenuItem component={<Link to={"/warnew"} />}>
                {" "}
                War
              </MenuItem> */}
            </SubMenu>
 
            <MenuItem
              rootStyles={{
                ["." + menuClasses.button]: {
                  background: "#0f0f0f",
                  color: "white",
                  "&:hover": {
                    background: "#0f0f0f",
                  },
                },
                ["." + menuClasses.label]: {
                  color: "white",
                },
              }}
              component={<Link to="/costdemaze/quick_glance" />}
            >
              {" "}
              CostDeMaze
            </MenuItem>
            {/* <MenuItem
              rootStyles={{
                ["." + menuClasses.button]: {
                  background: "#0f0f0f",
                  color: "white",
                  "&:hover": {
                    background: "#0f0f0f",
                  },
                },
                ["." + menuClasses.label]: {
                  color: "white",
                },
              }}
            >
              {" "}
              <a
                style={{ color: "white" }}
                // href="https://secops.1cloudhub.com/app/wazuh#/agents-preview/?_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:now-24h,to:now))&_a=(columns:!(_source),filters:!(('$state':(isImplicit:!t,store:appState),meta:(alias:!n,disabled:!f,index:'wazuh-alerts-*',key:cluster.name,negate:!f,params:(query:'1cloudhub'),removable:!f,type:phrase),query:(match:(cluster.name:(query:'1cloudhub',type:phrase))))),index:'wazuh-alerts-*',interval:auto,query:(language:kuery,query:''),sort:!())"
                href="https://secops.1cloudhub.com/app/wazuh"
                target="_blank"
              >
                SkySentinal
              </a>
            </MenuItem> */}
                        <MenuItem
              rootStyles={{
                ["." + menuClasses.button]: {
                  background: "#0f0f0f",
                  color: "white",
                  "&:hover": {
                    background: "#0f0f0f",
                  },
                },
                ["." + menuClasses.label]: {
                  color: "white",
                },
              }}
              onClick={handleAutoLogin}
            >
         
            Token Optix            
            </MenuItem>
            
          </Menu>


          <Menu className="sidebar-footer">
  <div>

  <span className="cloudhub-text1">Powered by</span>
</div>
<div>
   <span>   <img 
        src={cloudhubimg}
        alt="Powered by" 
        className="powered-by-image img-right"
      /></span>
   
      <span className="cloudhub-text ms-1 mt-4">1CloudHub</span>
      </div>

</Menu>

        </Sidebar>
        
      </div>
    </div>
  );
}
 
export default A2CSidebar;
