import React, { useState, useEffect } from "react";
import { Button, Row, Col, Space, Modal, Form,Progress,Spin} from "antd";
import { useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import axios from 'axios';
import { useDispatch, useSelector } from "react-redux";
import A2CSidebar from "../a2c_rover/A2CSidebar";
import A2cFilter from "../a2c_rover/A2cFilter";
import { listworkloadnames ,createworkload} from "../action/graphql/a2crover/A2CReducer";

import { setSelectedWorkload,setselectWorkloadid } from "../slice/a2c_roverSlice";

function Dasboardwar() {

  const {selectPercentage} =useSelector((state)=>state.a2cRover) 
  console.log(selectPercentage) 

  const [openmodal, setOpenModal] = useState(false);
  const [assessmentName, setAssessmentName] = useState("");
  const [reviewType, setReviewType] = useState("");
  const [accountid, setAccountID] = useState("");
  const [selectedEnvironment, setSelectedEnvironment] = useState("");
  const [description, setDescription] = useState("");
  const [lenses, setLenses] = useState("");
  const [awsregion, setAwsRegion] = useState("");
  const [architecturedesign, setArchitecturedesign] = useState("");
  const [dashboardlist, setDashboardList] = useState([]);
  const [WorkloadName, setWorkloadName] = useState([]);
  const [WorkloadID, setWorkloadID] = useState([]);
  const [percentagevalue,setPercentagevalue]=useState(0);
  const [loader, setLoader] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleCancel = () => {
    setOpenModal(false);
  };
  const onFinish = (value) => {
    // console.log(value)
  };

  const handleworkload = () => {
    setOpenModal(true);
  };
  // const { selectedTopdate } = useSelector((store) => store.a2cRover);
  var customer = localStorage.getItem("CognitoIdentityServiceProvider.company");

  // useEffect(() => {
  //   const input = {
  //     customer: customer,
  //   };

  //   dispatch(getdashboardlist({ input }))
  //     .unwrap()
  //     .then(({ data }) => {
  //       console.log(data, "dashboardlist");
  //       // const wardashboardlist = data.get_war_workload.map((datas) => {
  //       const workloadNames = data.get_war_workload.map(
  //         (item) => item.workload_name
  //       );
  //       setDashboardList(workloadNames, "workloadNames");
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, []); 

  // const input1 = {
  //   event_type: "list-workload-names",
  // };

  // useEffect(() => {
  //   axios.post("http://localhost:8000/api/endpoint", input1)
  //     .then((result) => {
  //       setLoader(true)
  //       // setSecuritydata(result.data);
  //      console.log(result.data)
  //       setDashboardList(result.data, "workloadNames");
  //       setLoader(false)
  //     })
  //     .catch((error) => {
  //       console.error("Error:", error);
  //     });
  // }, []);


  const handleAssessmentSubmit = () => {
    const input = {
      event_type: "create-workload",
      workload_name: assessmentName,
      description: description,
      environment: selectedEnvironment,
      account_id: accountid,
      architectural_design: "",
      review_owner: reviewType, 
      lenses: lenses,
      aws_regions: awsregion  
      
    };
    console.log(assessmentName)
    console.log(selectedEnvironment)
    console.log(accountid)
    console.log(reviewType)
    console.log(awsregion)
   
  
    dispatch(createworkload({ input }))
    .unwrap()
    .then(({ data }) => {
      console.log(data, "createworklaods");

      setDashboardList(prevDashboardList => [...prevDashboardList, assessmentName]);
      setOpenModal(false);
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};

  const handleview = (workload) => {
    // console.log(e)
    dispatch(setSelectedWorkload( workload.workloadname))
    dispatch(setselectWorkloadid( workload.workload_id))
  console.log('Clicked on Review for workload:', workload.workloadname);
  console.log('Workload ID:', workload.workload_id);
  setWorkloadName(workload.workloadname)
  setWorkloadID(workload.workload_id)

    navigate("/realability");
    
  };

  // console.log(dashboardlist)

  const handleWorkloadClick = (workloadId) => {
    console.log('Clicked on workload with ID:', workloadId);

  };
 
      
      // console.log(applicationcount[6])
    

// const input1 = {
//     event_type: "list-workload-names",
//   };

//   useEffect(() => {
//     axios.post("http://localhost:8000/api/endpoint", input1)
//       .then((result) => {
//         setLoader(true)
//         // setSecuritydata(result.data);
       
//         setDashboardList(result.data, "workloadNames");
//         setLoader(false)
//       })
//       .catch((error) => {
//         console.error("Error:", error);
//       });
//   }, []);

useEffect(() => {
  const listinput = {
    event_type: "list-workload-names",
  };
  
  dispatch(listworkloadnames({ listinput }))
    .unwrap()
    .then(({ data }) => {
      console.log(data, "listworkloadsnames");
      setLoader(true);
  
      const parsedDataArray = data.list_workload_names.map((jsonString) =>
        JSON.parse(jsonString)
      );
  
      const allValuesArray = parsedDataArray.map((item, index) => ({
        itemNumber: index + 1,
        percentage: item.percentage,
        workloadname: item.workloadname,
        workloadcreation: item.workloadcreation,
        workload_id: item.workload_id,
      }));
  
      console.log(allValuesArray);
      setDashboardList(allValuesArray, "workloadNames");
      setLoader(false);
    })
    .catch((err) => {
      console.log(err);
    });
  }, []);
  return (
    <div style={{ display: "flex", height: "100%", minHeight: "400px" }}>
      <A2CSidebar />
      
      <main className="position-main-div" style={{ padding: 10, flex: 1 }}>
        {" "}
        
        <div className="cams-content">
          <div className="container-fluid">
            <div className="header-filter d-flex justify-content-between">
              <div className="header-title-a2c-main">WAR</div>
              
              <span className="d-flex align-items-end">
              <A2cFilter />
              </span>
            </div>
     
            <div className="">
              <Row justify={"space-between"}>
                <Col >
                  <h4 className="sub-header mt-2">Summary</h4>
           
                </Col>  
               

                <Col>
                  <Button className="buttonclass mt-4" onClick={handleworkload}>
                    <Space>
                      <Icon icon="material-symbols:add-circle-outline" />
                      Create New Assesment
                    </Space>
                  </Button>
                </Col>
              </Row>
              <Row>
              <Col span={6}>
                  <h4 className="sub-header mt-2 font1">Application Name</h4>
           
                </Col>
              <Col span={7}>
                  <h4 className="sub-header mt-2 font1">Created DateTime</h4>
           
                </Col>
                <Col span={8}>
                  <h4 className="sub-header mt-2 progress-align font1">Progress</h4>          
                </Col>
                <Col span={3}>
                  <h4 className="sub-header mt-2 font1">Actions</h4>          
                </Col>
            
              </Row>

              <Modal
                className="costmodal"
                width="40%"
                title="Create New Assesment"
                open={openmodal}
                onCancel={handleCancel}
                footer={null}
              >
                <Form onFinish={onFinish}>
                  <div class="form-group titletab">
                    <label>Assessment name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Unique Identifier"
                      value={assessmentName}
                      onChange={(e) => setAssessmentName(e.target.value)}
                    />

                  </div>
                  <div class="form-group mt-3">
                    <label className="titletab">Review Owner</label>
                    <input
                      className="form-control "
                      placeholder="Review Owner"
                      value={reviewType}
                      onChange={(e) => setReviewType(e.target.value)}
                    >
                    </input>
                  </div>

                  <div class="form-group titletab mt-3">
                    <label>Account ID</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Account ID"
                      value={accountid}
                      onChange={(e) => setAccountID(e.target.value)}
                    />
                  </div>
                  <div class="form-group mt-3">
                    <label className="titletab">Select Environment</label>
                    <select
                      className="form-control "
                      placeholder="Environment"
                      value={selectedEnvironment}
                      onChange={(e) => setSelectedEnvironment(e.target.value)}
                    >
                      <option value="" disabled selected>
                        Select Environment
                      </option>
                      <option>PRODUCTION</option>
                      <option>NON PRODUCTION</option>
                    </select>
                  </div>
                  <div class="form-group titletab mt-3">
                    <label>lenses</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Lenses"
                      value={lenses}
                      onChange={(e) => setLenses(e.target.value)}
                    />
                  </div>

  <div class="form-group titletab mt-3">
                    <label>AWS Region</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="AWS Region"
                      value={awsregion}
                      onChange={(e) => setAwsRegion(e.target.value)}
                    />
                  </div>
                  <div class="form-group titletab mt-3">
                    <label>Architecture Design</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Architecture Design"
                      value={architecturedesign}
                      onChange={(e) => setArchitecturedesign(e.target.value)}
                    />
                  </div>
                  <div class="form-group mt-3">
                    <label className="titletab">Description</label>
                    <textarea
                      class="form-control"
                      placeholder="Please Enter brief description of the Assesment"
                      rows="3"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    ></textarea>
                  </div>
                  <div className="mt-3 ">
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Button
                        className="buttonclass mt-3"
                        onClick={handleAssessmentSubmit}
                      >
                        CREATE
                      </Button>
                      <Button className="mt-3 ms-3" onClick={handleCancel}>
                        CANCEL
                      </Button>
                    </div>
                  </div>
                </Form>
              </Modal>
              <Spin spinning={loader}>
              {dashboardlist.map((workload, index) => (
                
                <Row gutter={[16, 16]} key={index}>
                  
                  <Col span={6}>

                  <hr/>   <h6
        className="titletab titlefont mt-3"
        onClick={() => handleWorkloadClick(workload.workload_id)}
        style={{ cursor: 'pointer' }}
      >
        {workload.workloadname}
      </h6>
                   </Col> 
                  <Col span={6}> <hr/><h6 className="titletab titlefont">{workload.workloadcreation}</h6></Col>
                  <Col span={6}> <hr/> <div className="">
                        <div className="white-color mt-3">
                         
                         
                        </div>
                        <div className="text-center text-lg text-normal text-white">
                          <div
                            className="progress-score"
                            style={{
                              width: 200,
                            }}
                          >
                            <Progress
                              percent={workload.percentage}
                              size="small"
                              style={{color:"white"}}
                              className="war-progress titletab"
                            />
                            {/* <p className="progress-percentage text-sm">30%</p> */}
                          </div>
                        </div>
                      </div></Col>
                      <Col span={2}>
  <hr />
  <h6
    className="mt-4 titletab titlefont"
    onClick={() => handleview(workload)}
    style={{ cursor: "pointer" }}
  >
    <Space>
      <Icon icon="ep:view" />
      Review <Space>|</Space>
    </Space>
  </h6>
</Col>

                  <Col span={2}><hr/>
                    <h6
                      className="mt-4 ms-2 titletab titlefont"
                      // onClick={handledit}
                      style={{ cursor: "pointer" }}
                    >
                      <Space>
                        <Icon
                          icon="fluent:edit-16-regular"
                          // onClick={handledit}
                        />
                        Edit <Space>|</Space>
                      </Space>
                    </h6>
                  </Col>
                  <Col span={2}><hr />
                    <h6
                      className="mt-4 ms-2 titletab titlefont"
                      style={{ cursor: "pointer" }}
                    >
                      <Space>
                        <Icon
                          style={{ color: "#ff6a2f" }}
                          icon="codicon:trash"
                        />
                        Delete
                      </Space>
                    </h6>
                  </Col>
                </Row>
                
              ))}</Spin>
            </div>
            

            <br />
            {/* Graph */}
          </div>
        </div>
      </main>
    </div>
  );
}

export default Dasboardwar;
