import React from "react";
import { useState } from "react";
import "./instanceOverview.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import { Tooltip, Table, Spin, Tag } from "antd";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listInstanceTracking } from "../../action/costimizeV2Active";
import { capitalizeFirst } from "../../custom_hook/CustomHook";

const InstanceTrackingTable = () => {
  const [trackingrecords, setTrackingRecords] = useState([]);
  // const companyName = localStorage.getItem(
  //   "CognitoIdentityServiceProvider.company"
  // );

  var companyName = "all_cloud";

  const dispatch = useDispatch();
  //loader
  const [loader, setLoader] = useState(true);
  const monthNamesShort = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const {
    selectedTopdate,
    selectedEnvironment,
    selectedAccount,
    selectedCloud,
    selectedApplication,
    selectedos,
  } = useSelector((state) => state.constimize);

  useEffect(() => {
    setLoader(true);
    const apivalue = {
      account_name: selectedAccount.toString(),
      application: selectedApplication.toString(),
      cloud: selectedCloud.toString(),
      company_name: companyName,
      environment: selectedEnvironment.toString(),
      month: selectedTopdate,
      os: selectedos.toString(),
    };

    dispatch(listInstanceTracking({ apivalue }))
      .unwrap()
      .then(({ data }) => {
        // console.log(data);
        const list = data.newfunctionquery15.map((list_data, index) => {
          // console.log(list_data);
          var status = list_data.status;
          if (list_data.status === "CREATED") {
            status = "LAUNCHED";
          }
          return {
            key: index,
            application: list_data.application,
            environment: list_data.environment,
            instance_name: list_data.resourcename,
            date: list_data.date,
            status: list_data.status,
            changes_occured: list_data.changes_occured,
            region: list_data.region,
            root_device_type: list_data.root_device_type,
            instance_type: list_data.instance_type,
            ebs_volume: list_data.ebs_volume,
            modified_category: list_data.modified_category
          };
        });
        // console.log(list);
        setTrackingRecords(list);
        setLoader(false);
      })
      .catch((err) => {
        console.log(err.message);
        setLoader(false);
      });
  }, [
    selectedAccount,
    selectedApplication,
    selectedCloud,
    selectedEnvironment,
    selectedTopdate,
    selectedos,
  ]);

  const history_columns = [
    {
      title: "Modified Date",
      dataIndex: "date",
      key: "date",
      align: "left",
      sorter: (a, b) => (a.date > b.date ? -1 : 1),
      render: (record, index) => {
        // console.log(record);
        // return record;
        if (record) {
          return `${new Date(record).getDate()}/${new Date(record).getMonth() + 1
            }/${new Date(record).getFullYear()}`;
        }
      },
    },
    {
      title: "Application",
      dataIndex: "application",
      key: "application",
      align: "left",
      sorter: (a, b) => (a.application > b.application ? -1 : 1),
    },
    {
      title: "Environment",
      dataIndex: "environment",
      key: "environment",
      align: "left",
      sorter: (a, b) => (a.environment > b.environment ? -1 : 1),
    },
    {
      title: "Instance Name",
      dataIndex: "instance_name",
      key: "instance_name",
      align: "left",
      sorter: (a, b) => (a.instance_name > b.instance_name ? -1 : 1),
      render: (record, index) => {
        if (record) {
          if (record.length > 80) {
            return (
              <>
                <Tooltip placement="bottom" title={record}>
                  {record.substring(0, 80)}...
                </Tooltip>
              </>
            );
          } else {
            return record;
          }
        }
      },
    },

    {
      title: "Category",
      dataIndex: "modified_category",
      key: "modified_category",
      align: "left",
      sorter: (a, b) => (a.modified_category > b.modified_category ? -1 : 1),
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      sorter: (a, b) => (a.status > b.status ? -1 : 1),
      render: (record, index) => {
        var cell = record;
        return (
          <div className="d-flex justify-content-center">
            {cell === "running" ? (
              <div
                className="p-1 tracking-tag"

                style={{
                  color: "#00AF4F",
                  border: "1px solid #00AF4F",
                  // backgroundColor: "#EAFFE4",
                  // width: "80",
                }}
              >
                {capitalizeFirst(cell)}
              </div>
            ) : cell === "terminated" ? (
              <div
                className="p-1 tracking-tag"
                style={{
                  color: "#FF5454",
                  border: "1px solid #FF5454",
                  // backgroundColor: "#FFCACA",

                }}
              >
                {capitalizeFirst(cell)}
              </div>
            ) : cell === "stopped" ? (
              <div
                className="p-1 tracking-tag"
                style={{
                  color: "#FF5454",
                  border: "1px solid #FF5454",

                }}
              >
                {capitalizeFirst(cell)}
              </div>
            ) : cell === "new" ? (
              <div
                className="p-1 tracking-tag"
                style={{
                  color: "rgb(66 170 39)",
                  border: "1px solid rgb(66 170 39)",

                }}
              >
                {capitalizeFirst(cell)}
              </div>
            ) : cell === "modified" ? (
              <div
                className="p-1 tracking-tag"
                style={{
                  color: "#FFE500",

                  border: "1px solid #FFE500",

                }}
              >
                {capitalizeFirst(cell)}
              </div>
            ) : (
              <> </>
            )}
          </div>
        );
      },
    },
  ];

  const tableProps = {};
  return (
    <>
      <div className="costimize-table-width">
        <div className="instanceTrackingTable">
          <Spin spinning={loader} wrapperClassName="costimize-loader">
            <Table
              {...tableProps}
              className="slm"
              columns={history_columns}
              expandable={{
                expandedRowRender: (record) => (
                  <>
                    <div className="instance_text ">
                      {record && record.changes_occured && (
                        <li>Changes_occured : {record.changes_occured}</li>
                      )}
                      {record && record.region && (
                        <li>Region:{record.region}</li>
                      )}
                      {record && record.root_device_type && (
                        <li>Device Type : {record.root_device_type}</li>
                      )}
                      {record && record.instance_type && (
                        <li>Instance Type : {record.instance_type}</li>
                      )}
                      {record && record.ebs_volume && (
                        <li>EBS Volume : {record.ebs_volume}</li>
                      )}
                    </div>
                  </>
                ),
              }}
              dataSource={trackingrecords}
              pagination={true}
              showSizeChanger={false}
            // rowClassName={(record, index) => {
            //   if(record.status === 'LAUNCHED'){
            //     return "added-row";
            //   }else if(record.status === 'running'){
            //     return "added-row";
            //   }else if(record.status === "stopped"){
            //     return "deleted-row";
            //   }else if(record.status === 'deletted'){
            //     return "deleted-row";
            //   }else if(record.status === 'modified'){
            //     return "modified-row";
            //   }
            // }}
            />
          </Spin>
        </div>
      </div>
    </>
  );
};

export default InstanceTrackingTable;
